import { radarConstants } from '../constants';

const initialState = {
    radars: null,
    damagePlotRadars: null,
    selectedRadarDate: null,
    radarsToDisplay: null,
    mepars: null,
};

export function radar(state = initialState, action) {
    switch (action.type) {
        case radarConstants.SET_RADARS:
            return {
                radars: action.payload.radars,
                damagePlotRadars: action.payload.damagePlotRadars,
                selectedRadarDate: action.payload.selectedRadarDate,
                radarsToDisplay: action.payload.radarsToDisplay,
                mepars: action.payload.mepars,
            };
        case radarConstants.SET_SELECTED_RADAR_DATE:
            return {
                ...state,
                selectedRadarDate: action.payload.selectedRadarDate,
                radarsToDisplay: action.payload.radarsToDisplay,
                mepars: action.payload.mepars,
            };
        default:
            return state;
    }
}
