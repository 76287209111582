import { LAYERS } from 'shared';
import { Fill, Style, Text } from 'ol/style';
import FillPattern from 'ol-ext/style/FillPattern';
import { getWidth } from 'ol/extent';
import VectorLayer from 'ol/layer/Vector';
import { bbox } from 'ol/loadingstrategy';
import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import { layerService } from '../../services';
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';
import moment from 'moment';

const colors = [
    '230, 25, 75',
    '60, 180, 75',
    '255, 225, 25',
    '0, 130, 200',
    '245, 130, 48',
    '145, 30, 180',
    '70, 240, 240',
    '240, 50, 230',
    '210, 245, 60',
    '250, 190, 190',
    '0, 128, 128',
    '230, 190, 255',
    '170, 110, 40',
    '255, 250, 200',
    '128, 0, 0',
    '170, 255, 195',
    '128, 128, 0',
    '255, 215, 180',
    '0, 0, 128',
    '128, 128, 128',
    '255, 255, 255',
    '0, 0, 0',
];

const patterns = [
    'hatch',
    'cross',
    'dot',
    'circle',
    'square',
    'tile',
    'woven',
    'crosses',
    'caps',
    'nylon',
    'hexagon',
    'cemetry',
    'sand',
    'conglomerate',
    'gravel',
    'brick',
    'dolomite',
    'coal',
    'breccia',
    'clay',
    'flooded',
    'chaos',
    'grass',
    'swamp',
    'wave',
    'vine',
    'forest',
    'scrub',
    'tree',
    'pine',
    'pines',
    'rock',
    'rocks',
];

const crops = {
    2018: {
        1: 'árpa, búza, tritikálé',
        2: 'repce',
        3: 'csemegekukorica, kukorica, silókukorica',
        4: 'napraforgó',
        5: 'borsó, cukorrépa, lucerna,szója, zöldbab',
    },
    2019: {
        1: 'kalászos',
        2: 'őszi káposztarepce',
        3: 'kukorica',
        4: 'napraforgó',
    },
    2020: {
        1: 'kalászos',
        2: 'őszi káposztarepce',
        3: 'kukorica',
        4: 'napraforgó',
        5: 'kukorica/napreforgó',
    },
    2021: {
        1: 'kalászos',
        2: 'őszi káposztarepce',
        3: 'kukorica',
        4: 'napraforgó',
    },
    2022: {
        1: 'kalászos',
        2: 'őszi káposztarepce',
        3: 'kukorica',
        4: 'napraforgó',
    },
};

export default class CropLayer {
    map = null;
    layer = null;
    year = null;

    constructor(map, year = null) {
        this.map = map;
        this.year = year;
        this.init();
    }

    init() {
        const vectorSource = new VectorSource({
            format: new GeoJSON(),
            loader: async (extent) => {
                const extentPolygon = fromExtent(extent);
                const response = await layerService.getCropMap(this.year, extentPolygon);
                response.forEach((crop) => {
                    const cropPolygon = JSON.parse(crop.cropPolygon);
                    const feature = new Feature({
                        geometry:
                            cropPolygon.type === 'Polygon'
                                ? new Polygon(cropPolygon.coordinates)
                                : new MultiPolygon(cropPolygon.coordinates),
                        crop,
                    });
                    vectorSource.addFeature(feature);
                });
            },
            strategy: bbox,
        });

        this.layer = new VectorLayer({
            title: `Vetéstérkép`,
            type: LAYERS.TYPES.CROP,
            visible: false,
            renderMode: 'vector',
            declutter: true,
            source: vectorSource,
            maxResolution: 3,
            style: (feature) => {
                const crop = feature.get('crop');
                const color = `rgba(${colors[crop.crop]},0.2)`;
                return new Style({
                    fill: new FillPattern({
                        pattern: patterns[crop.crop],
                        color: 'rgba(0,0,0,1)',
                        fill: new Fill({ color }),
                    }),
                    text: new Text({
                        font: '14px sans-serif',
                        overflow: true,
                        placement: 'center',
                        fill: new Fill({
                            color: 'white',
                        }),
                        text: crops[moment(crop.date).format('YYYY')][crop.crop],
                    }),
                    geometry: function (feature) {
                        let geometry = feature.getGeometry();
                        if (geometry.getType() === 'MultiPolygon') {
                            // Only render label for the widest polygon of a multipolygon
                            var polygons = geometry.getPolygons();
                            var widest = 0;
                            for (var i = 0, ii = polygons.length; i < ii; ++i) {
                                var polygon = polygons[i];
                                var width = getWidth(polygon.getExtent());
                                if (width > widest) {
                                    widest = width;
                                    geometry = polygon;
                                }
                            }
                        }
                        return geometry;
                    },
                });
            },
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        this.layer.setVisible(layer.visible);
    }
}
