import { LAYERS } from 'shared';
import { Tile as TileLayer } from 'ol/layer';
import XYZSource from 'ol/source/XYZ';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import VectorSource from 'ol/source/Vector';

export default class CemeteryUAVLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new TileLayer({
            title: 'UAV',
            preload: Infinity,
            type: LAYERS.TYPES.CEMETERY_UAV,
            visible: true,
            displayInLayerSwitcher: true,
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (layer.geoserverUrl && layer.fittingLayer && layer.visible && layer.fittingGeometry) {
            this.layer.setSource(
                new XYZSource({
                    url: `https://temeto.envimap.hu${layer.tiles}/{z}/{x}/{y}.png`,
                })
            );

            const polygon = layer.fittingGeometry;
            const feature = new Feature({
                geometry:
                    polygon.type === 'Polygon'
                        ? new Polygon(polygon.coordinates)
                        : new MultiPolygon(polygon.coordinates),
            });
            const fittingSource = new VectorSource();
            fittingSource.addFeature(feature);

            this.map.map.getView().fit(fittingSource.getExtent(), this.map.map.getSize());
        }

        this.layer.setVisible(layer.visible);
    }
}
