import { Divider, List, ListItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button/Button';
import { ROUTES } from 'shared/src/constants/routes';
import { withRouter } from 'react-router';

const styles = (theme) => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    label: {
        minWidth: '200px',
        fontSize: '13px',
    },
    labelProject: {
        minWidth: '200px',
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    blockButtonHighlighted: {
        backgroundColor: 'rgba(170,170,170,1)',
    },
    buttonLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonInfoRowLayout: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    buttonTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    groupTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    buttonLabel: {
        fontSize: '12px',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: '12px',
    },
    divider: {
        marginTop: '10px',
    },
    groupTitleRow: {
        paddingBottom: '0px',
    },
});

class WoodwiserProjectPanel extends React.Component {
    onForestUnitButton = (forestUnit) => () => {
        this.props.history.push(`${ROUTES.WOODWISER_FOREST_UNIT(forestUnit.id)}`);
    };

    render() {
        const { classes, woodwiserProject } = this.props;
        moment.locale('hu');

        if (!woodwiserProject) {
            return null;
        }

        const area = woodwiserProject.forestUnits.reduce((accumulator, forestUnit) => {
            return accumulator + forestUnit.area;
        }, 0);
        const areaSum = Math.round(area * 100) / 100;

        const volume = woodwiserProject.forestUnits.reduce((accumulator, forestUnit) => {
            return accumulator + forestUnit.volumeSum;
        }, 0);
        const volumeSum = Math.round(volume * 100) / 100;

        const stemCount = woodwiserProject.forestUnits.reduce((accumulator, forestUnit) => {
            return accumulator + forestUnit.stemCount;
        }, 0);

        const heightAvgSum = woodwiserProject.forestUnits.reduce((accumulator, forestUnit) => {
            return accumulator + forestUnit.heightAvg;
        }, 0);
        const heightAvg =
            Math.round((heightAvgSum / woodwiserProject.forestUnits.length) * 100) / 100;

        const dbhAvgSum = woodwiserProject.forestUnits.reduce((accumulator, forestUnit) => {
            return accumulator + forestUnit.dbhAvg;
        }, 0);
        const dbhAvg = Math.round((dbhAvgSum / woodwiserProject.forestUnits.length) * 100) / 100;

        return (
            <List>
                <ListItem>
                    <div className={classes.layout}>
                        <div className={classes.row}>
                            <Typography className={classes.labelProject}>
                                {woodwiserProject.name}
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Terület:</Typography>
                            <Typography className={classes.value}>{areaSum} ha</Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Törzsszám:</Typography>
                            <Typography className={classes.value}>{stemCount} db</Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Átlag famagasság:</Typography>
                            <Typography className={classes.value}>{heightAvg} m</Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>
                                Átlag mellmagassági átmérő:
                            </Typography>
                            <Typography className={classes.value}>{dbhAvg} cm</Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>
                                Számított összes fatérfogat:
                            </Typography>
                            <Typography className={classes.value}>{volumeSum} m3</Typography>
                        </div>
                    </div>
                    <Divider />
                </ListItem>
                {woodwiserProject.forestUnits.map((forestUnit) => (
                    <ListItem key={forestUnit.id}>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="default"
                            size="small"
                            onClick={this.onForestUnitButton(forestUnit)}
                        >
                            <div className={classes.buttonLayout}>
                                <Typography className={classes.buttonTitle}>
                                    {`${forestUnit.settlement} ${forestUnit.tag}${
                                        forestUnit.subTag ? `/${forestUnit.subTag}` : ''
                                    }`}
                                </Typography>
                            </div>
                        </Button>
                    </ListItem>
                ))}
            </List>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.user;
    const { woodwiserProject } = state.woodwiserProject;
    return {
        user,
        woodwiserProject,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(WoodwiserProjectPanel)));
