import fetchWithTimeout from '../helpers/fetchWithTimeOut';

export const yieldCalculationService = {
    startCalculation,
};

async function startCalculation(damagePlotId) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-admin': `${isAdmin}`,
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await fetchWithTimeout(
            `/api/damagePlots/calculateYield/${encodeURIComponent(damagePlotId)}`,
            options
        );

        return response.json();
    } catch (err) {
        console.log(err);
        return { err: 'A hozamszámítás sikertelen volt. Ellenőrizze az internetkapcsolatát!' };
    }
}
