import { LAYERS } from 'shared';
import { Fill, Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';

export default class FrostDamageChronologyLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new VectorLayer({
            title: 'Fagykár kronológia',
            type: LAYERS.TYPES.FROST_DAMAGE_CHRONOLOGY,
            visible: false,
            displayInLayerSwitcher: true,
            style: new Style({
                stroke: new Stroke({
                    color: 'red',
                    width: 2,
                }),
                fill: new Fill({
                    color: 'rgba(255, 0, 0, 0.1)',
                }),
            }),
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (layer.damageChronology !== this.layer.get('damageChronology') && layer.damageChronology) {
            const damageChronology = layer.damageChronology;
            const damageChronologySource = new VectorSource();

            damageChronology.forEach(damageChronologyItem => {
                const geom = damageChronologyItem.geom;
                const feature = new Feature({
                    geometry: new Polygon(geom.coordinates),
                    damageChronologyItem,
                });
                feature.setStyle(new Style({
                    stroke: new Stroke({
                        color: damageChronologyItem.color,
                        width: 2,
                    }),
                    fill: new Fill({
                        color: damageChronologyItem.color,
                        opacity: 0.5,
                    }),
                }));
                damageChronologySource.addFeature(feature);
            });

            this.layer.setSource(damageChronologySource);
            this.layer.set('damageChronology', damageChronology);
        }
        this.layer.setVisible(layer.visible);
    }
}