import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { woodwiserProjectService } from '../services/woodwiserProjectService';
import { woodwiserProjectActions } from '../state/actions/woodwiserProjectActions';
import { mapActions, viewActions } from '../state/actions';
import { LAYERS } from 'shared/src/constants/layers';
import { VIEWS } from 'shared/src/constants/views';

class WoodwiserProjectController extends React.Component {
    componentDidMount() {
        return this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.match.params.id !== prevProps.match.params.id ||
            this.props.location.search !== prevProps.location.search
        ) {
            return this.getData();
        }
    }

    async getData() {
        const {
            match: { params },
        } = this.props;

        this.props.dispatch(viewActions.setInfoPanelState({ open: true }));
        this.props.dispatch(viewActions.setViewState({ view: VIEWS.PROGRESS }));

        this.props.dispatch(mapActions.resetLayers());

        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.COUNTRY,
                visible: true,
                fittingLayer: false,
            })
        );

        const woodwiserProject = await woodwiserProjectService.getWoodwiserProject(params.id);

        if (!woodwiserProject) {
            return;
        }

        this.props.dispatch(woodwiserProjectActions.setWoodwiserProject(woodwiserProject));
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_FOREST_UNIT,
                forestUnits: woodwiserProject.forestUnits,
                fittingLayer: true,
            })
        );
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_DTM,
                products: woodwiserProject.products,
                fittingLayer: false,
            })
        );
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_HEIGHT,
                products: woodwiserProject.products,
                fittingLayer: false,
            })
        );
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_ASPECT,
                products: woodwiserProject.products,
                fittingLayer: false,
            })
        );
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_SLOPE,
                products: woodwiserProject.products,
                fittingLayer: false,
            })
        );
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_RGB,
                products: woodwiserProject.products,
                fittingLayer: false,
            })
        );
        this.props.dispatch(viewActions.setViewState({ view: VIEWS.WOODWISER_PROJECT }));
    }

    render() {
        return null;
    }
}

export default connect()(withRouter(WoodwiserProjectController));
