export const PRODUCTS = {
    TYPES: {
        HETEROGENITY_MAP: 'Heterogeneity',
        CHRONOLOGY: 'Chronology',
        TABLE_FOLLOW_UP: 'TableFollowUp',
        DAMAGE_MAP: 'DamageMap',
        CHANGE_MAP: 'ChangeMap',
        NDVI: 'NDVI',
        AGRI_NDVI: 'AgriNDVI',
        ALERT: 'Alert',
        CHANGE: 'Change',
        SAMPLING_ZONE_RASTER: 'SamplingMap',
        SOIL_MANAGEMENT_ZONE: 'SoilManagementZone',
    },

    DETAILS: {
        Heterogeneity: {
            name: 'Táblamonitoring',
            description:
                'Virtuális közbenső szemléhez előállított rétegek, amelyek a káreseményt követő felhőmentes felvételeken észlelhető biomassza intenzitás eltérések táblán belüli monitoringját szolgálják.',
            legend: '/artifacts/HeterogenitasTerkep/infoHeterogeneity.gif',
        },
        TableFollowUp: {
            name: 'Tábla utánkövetés',
            description:
                'A tábla utánkövetés a kár utáni felhőmentes heterogenitás értékekből generált térképi réteg, ami a táblán lévő növény kár utáni változását ábrázolja.',
            legend: '/artifacts/TablaUtankovetes/infoTableFollowUp.gif',
        },
        DamageMap: {
            name: 'Káros - nem káros térkép',
            description:
                'Radar adatok alapján becsült táblán belüli várható károsodottsági szint a káros - nem káros MEPAR blokkok elkülönítéséhez.',
            legend: '/artifacts/KarosNemKaros_Eloszemlehez/infoDamageMap.gif',
        },
        ChangeMap: {
            name: 'Változás térkép',
            description:
                'Változástérkép (előszemléhez vagy virtuális közbenső szemléhez, ha nincs az előszemléig felvétel). A térképi réteg káresemény előtt és után két időpont közötti biomassza intenzitás változását ábrázolja.',
            legend: '/artifacts/ValtozasTerkep/infoChangeMap.gif',
        },
        Chronology: {
            name: 'NDVI',
            description: 'Felhőmentes műholdfelvételekből készült biomassza intenzitás térképek.',
            legend: '/artifacts/Kronologia/infoNDVI.gif',
        },
        NDVI: {
            name: 'NDVI',
            description:
                'Az NDVI térkép a felhőmentes időpontok látható színes és biomassza intenzitás rétegeit tartalmazza.',
            legend: '/artifacts/Kronologia/infoNDVI.gif',
        },
        AgriNDVI: {
            name: 'NDVI',
            description:
                'Az NDVI térkép a felhőmentes időpontok látható színes és biomassza intenzitás rétegeit tartalmazza.',
            legend: '/artifacts/Kronologia/infoNDVI.gif',
        },
        Alert: {
            name: 'Riasztás',
            description:
                'A riasztás térkép a jelentős biomassza változással érintett területeket ábrázolja.',
            legend: '',
        },
        Change: {
            name: 'Változás',
            description:
                'A változástérkép két időpont közötti biomassza intenzitás változását ábrázolja.',
            legend: '/artifacts/ValtozasTerkep/infoChangeMap.gif',
        },
        SoilManagementZone: {
            name: 'Menedzsment zóna',
            description:
                'Olyan térképi réteg, amely az adott földterület biomassza információi alapján kezelési zónákra osztja a területet. Ennek segítségével a tápanyag kijuttatás optimalizálható.',
            legend: '/artifacts/MenedzsmentZona/SoilManagementZone_legend.png',
        },
    },
};
