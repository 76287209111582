import { connect } from 'react-redux';
import DamagePlotPanel from './DamagePlotPanel';

const mapStateToProps = (state) => {
    const { damage, damagePlot } = state.damage;
    return {
        damage,
        damagePlot,
    };
};

export default connect(mapStateToProps)(DamagePlotPanel);