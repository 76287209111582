export const USER = {
    KÁRNAVIGÁTOR_API: 4,
    PUCCINI_API: 3,
    GMBP_API: 6,
};

export const COMPANY = {
    ENVIROSENSE: 4,
    OTHER: 6,
};

export const USER_STATUS = {
    NOT_INITIALISED: 1,
    IS_FETCHING: 2,
    FETCHED: 3,
    AUTHENTICATION_FAILED: 4,
};
