import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { screens } from '../../styles/styles';
import SimpleHeader from '../headers/SimpleHeader';

const styles = (theme) => ({
    page: {
        display: 'flex',
        flexDirection: 'column',
        /*backgroundColor: theme.palette.background.paper,*/
    },
    mainAreaAlign: {
        flex: 1,
        display: 'block',
        width: '100%',
    },
    mainAreaSize: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        [theme.breakpoints.down(screens.tablet)]: {
            minWidth: '100%',
            maxWidth: '100%',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: '100%',
            maxWidth: '100%',
        },
    },
    hideOnDesktop: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'none',
        },
    },
    hideOnTablet: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'none',
        },
    },
    hideOnMobile: {
        [theme.breakpoints.down(screens.mobile)]: {
            display: 'none',
        },
    },
});

class SecondaryScreen extends React.Component {
    render() {
        const { classes, children, title, showClose } = this.props;

        return (
            <div className={classes.page}>
                <SimpleHeader title={title} showClose={showClose} />
                <main className={classNames(classes.mainAreaAlign)}>
                    <div className={classNames(classes.mainAreaSize)}>{children}</div>
                </main>
            </div>
        );
    }
}

export default withStyles(styles)(SecondaryScreen);
