export const ROUTES = {
    HOME: () => '/',
    LOGIN: () => '/bejelentkezes',
    USER_PROFILE: () => '/profil',
    BLOCK: (id) => `/view/block/${id}`,
    DAMAGE: (id) => `/view/damage/${id}`,
    POLICY: (id) => `/view/policy/${id}`,
    WOODWISER_PROJECT: (id) => `/view/woodwiserProject/${id}`,
    WOODWISER_FOREST_UNIT: (id) => `/view/woodwiserForestUnit/${id}`,
    RADAR: () => '/view/radar',
    CREATE_DAMAGE: () => '/create/damage',
    DASHBOARD: () => '/view/dashboard',
    DAMAGE_CHRONOLOGY: () => '/view/damageChronology',
    FROST_DAMAGE: () => '/view/frostDamage',
    SAMPLING: (id) => `/create/sampling/${id}`,
    PASSWORD_RESET: () => '/passwordReset',
    NEW_PASSWORD_RESET: () => `/passwordRenew`,
    LINK_TOKEN: (compoundLinkToken) => `/t/${compoundLinkToken}`,
    ALERT_LINK_TOKEN: (compoundLinkToken, view, date) => `/t/${compoundLinkToken}/${view}/${date}`,
    SOIL_SAMPLING_MEPAR: (meparCode) => `/view/soilSamplingMepar/${meparCode}`,
    EK: (ekCode) => `/view/ek/${ekCode}`,
    BLOCK_PLOT: (id) => `/view/blockPlot/${id}`,
    YIELD: (type, plant) => `/view/yield/${type}/${plant}`,
};
