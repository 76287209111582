import { LAYERS } from 'shared';
import { Tile as TileLayer } from 'ol/layer';
import OSM from 'ol/source/OSM';

export default class StreetLayer {
    map = null;
    layer = null;

    constructor(map, visible = false) {
        this.map = map;
        this.init(visible);
    }

    init(visible) {
        this.layer = new TileLayer({
            title: 'Utcatérkép',
            type: LAYERS.TYPES.STREET,
            visible,
            source: new OSM(),
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        this.layer.setVisible(layer.visible);
    }
}
