import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import _ from 'lodash';

export function downloadExcel(graves, selectedDeads, selectedGraves) {
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const jsonGraves = [
        [
            'Parcella',
            'Sor',
            'Pozíció',
            'Érvényességi idő',
            'Elhunyt neve',
            'Elhunyt születési éve',
            'Elhunyt halálozási éve',
        ],
        ..._.flatten(
            graves.map((grave) =>
                (grave.deads.length > 0 ? grave.deads : [{}]).map((dead) => [
                    grave.parcel,
                    grave.row,
                    grave.pos,
                    grave.graveValidDate,
                    dead.name,
                    dead.birth,
                    dead.death,
                ])
            )
        ),
    ];

    const jsonSelectedDeads = [
        ['Elhunyt neve', 'Elhunyt születési éve', 'Elhunyt halálozási éve'],
        ...selectedDeads.map((dead) => [dead.name, dead.birth, dead.death]),
    ];

    const jsonSelectedGraves = [
        [
            'Parcella',
            'Sor',
            'Pozíció',
            'Érvényességi idő',
            'Elhunyt neve',
            'Elhunyt születési éve',
            'Elhunyt halálozási éve',
        ],
        ..._.flatten(
            selectedGraves.map((grave) =>
                (grave.deads.length > 0 ? grave.deads : [{}]).map((dead) => [
                    grave.parcel,
                    grave.row,
                    grave.pos,
                    grave.graveValidDate,
                    dead.name,
                    dead.birth,
                    dead.death,
                ])
            )
        ),
    ];
    /*
        var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
        var data = XLSX.utils.sheet_to_json(first_worksheet, {header:1});

        /!* convert from array of arrays to workbook *!/
        var worksheet = XLSX.utils.aoa_to_sheet(data);
        var new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
    */

    const fileName = `temeto`;
    const wsGraves = utils.aoa_to_sheet(jsonGraves);
    const wsSelectedDeads = utils.aoa_to_sheet(jsonSelectedDeads);
    const wsSelectedGraves = utils.aoa_to_sheet(jsonSelectedGraves);
    const wb = {
        Sheets: {
            Sirhelyek: wsGraves,
            'Kivalasztott elhunytak': wsSelectedDeads,
            'Kivalasztott sirhelyek': wsSelectedGraves,
        },
        SheetNames: ['Sirhelyek', 'Kivalasztott elhunytak', 'Kivalasztott sirhelyek'],
    };
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
}
