import * as React from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { ROUTES } from 'shared/src/constants/routes';
import { SERVER_ERROR_INVALID_CREDENTIALS } from '../../services/baseService';

export default function SecureRoute(props) {
    const { component: Component, ...rest } = props;

    const user = useSelector((state) => state.user.user);
    const error = useSelector((state) => state.error.error);
    const location = useLocation();

    const isAuthenticationError = error && error.code === SERVER_ERROR_INVALID_CREDENTIALS;

    const url = `${location.pathname}${location.search}`;

    return (
        <Route
            {...rest}
            render={(renderProps) =>
                user && !isAuthenticationError ? (
                    <Component {...renderProps} />
                ) : (
                    <Redirect to={`${ROUTES.LOGIN()}?url=${encodeURIComponent(url)}`} {...props} />
                )
            }
        />
    );
}
