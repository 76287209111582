import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { meparActions } from '../../state/actions/meparActions';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { dialogActions } from '../../state/actions/dialogActions';

const useStyles = makeStyles((theme) => ({
    layout: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '42px',
        '& > *:not(:first-child)': {
            marginLeft: '10px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '46px',
        },
    },
}));

export default function DrawingBar() {
    const classes = useStyles();
    const policyPlotToDraw = useSelector((state) => state.mepar.policyPlotToDraw);
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const drawingManager = useSelector((state) => state.map.drawingManager);
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!policyPlotToDraw) {
            setIsOpen(false);
            return;
        }

        setIsOpen(true);
    }, [policyPlotToDraw]);

    function handleSaveClick() {
        dispatch(meparActions.savePlotGeometry(policyPlotToDraw, drawingManager.polygon));
        dispatch(meparActions.drawPlotGeometry(null));
        drawingManager.stopDrawing();

        const allPlotsAreDrawn = !selectedMepar.policyPlots.some(
            (policyPlot) => !policyPlot.tableGeometry
        );
        if (!allPlotsAreDrawn) {
            dispatch(dialogActions.showPolicyPlotsDialog());
        }
    }

    function handleCancelClick() {
        dispatch(meparActions.drawPlotGeometry(null));
        drawingManager.stopDrawing();
    }

    if (!isOpen) {
        return null;
    }

    return (
        <div className={classes.layout}>
            <Button
                variant="contained"
                size="small"
                onClick={handleCancelClick}
                startIcon={<CloseIcon />}
            >
                Mégsem
            </Button>
            <Button
                variant="contained"
                color={'primary'}
                size="small"
                onClick={handleSaveClick}
                startIcon={<CheckIcon />}
            >
                Körvonal mentése
            </Button>
        </div>
    );
}
