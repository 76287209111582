import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ekActions, mapActions, viewActions } from '../state/actions';
import { LAYERS } from 'shared/src/constants/layers';
import { VIEWS } from 'shared';
import { meparService } from '../services/meparService';

class EKController extends React.Component {
    componentDidMount() {
        return this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.match.params.ekCode !== prevProps.match.params.ekCode ||
            this.props.location.search !== prevProps.location.search
        ) {
            return this.getData();
        }
    }

    async getData() {
        const {
            match: { params },
            location,
        } = this.props;

        this.props.dispatch(viewActions.setInfoPanelState({ open: true }));
        this.props.dispatch(viewActions.setViewState({ view: VIEWS.PROGRESS }));
        this.props.dispatch(mapActions.resetLayers());

        let searchParams = new URLSearchParams(location.search);
        const meparCode = searchParams.get('meparCode');
        const productType = searchParams.get('productType');

        const ekCode = params.ekCode;
        this.props.dispatch(ekActions.setEK(ekCode));

        if (meparCode && productType) {
            this.props.dispatch(ekActions.setEK(ekCode));
            const mepar = await meparService.getMeparByCode(meparCode);
            this.props.dispatch(
                mapActions.updateLayer({
                    type: LAYERS.TYPES.COUNTRY,
                    visible: true,
                    fittingLayer: false,
                })
            );

            this.props.dispatch(
                mapActions.updateLayer({
                    type: LAYERS.TYPES.EK,
                    mepars: [mepar],
                    fittingLayer: true,
                    visible: true,
                })
            );

            this.props.dispatch(viewActions.setViewState({ view: VIEWS.EK_PRODUCT }));
            return;
        }

        let mepar1;
        let mepar2;
        if (ekCode === '3545151354') {
            mepar1 = await meparService.getMeparByCode('T4QNPD17');
            mepar2 = await meparService.getMeparByCode('TJDHCX17');
        } else {
            mepar1 = await meparService.getMeparByCode('T71PDH17');
            mepar2 = await meparService.getMeparByCode('TFM5DT17');
        }

        const mepars = [mepar1, mepar2];

        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.COUNTRY,
                visible: true,
                fittingLayer: false,
            })
        );

        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.EK,
                mepars,
                fittingLayer: true,
                visible: true,
            })
        );

        this.props.dispatch(viewActions.setViewState({ view: VIEWS.EK }));
    }

    render() {
        return null;
    }
}

export default connect()(withRouter(EKController));
