import { LAYERS } from 'shared';
import { Tile as TileLayer } from 'ol/layer';
import TileWMS from 'ol/source/TileWMS';
import { WOODWISER_PRODUCT_TYPES } from 'shared/src/constants/woodwiser';

export default class WoodwiserSlopeLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new TileLayer({
            title: 'Lejtőszög',
            type: LAYERS.TYPES.WOODWISER_SLOPE,
            visible: false,
            displayInLayerSwitcher: true,
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (layer.products !== this.layer.get('products') && layer.products) {
            const products = layer.products;

            const product = products.find(
                (product) => product.type === WOODWISER_PRODUCT_TYPES.SLOPE
            );

            const source = new TileWMS({
                url: `/geoserver/woodwiser/wms?service=WMS${product.colorScaleTag}`,
                crossOrigin: 'anonymous',
                params: {
                    LAYERS: product.url,
                    FORMAT: 'image/png',
                },
            });

            this.layer.setSource(source);
            this.layer.set('products', products);
        }
    }
}
