import { combineReducers } from 'redux';

import { user } from './userReducer';
import { block } from './blockReducer';
import { damage } from './damageReducer';
import { frostDamage } from './frostDamageReducer';
import { map } from './mapReducer';
import { view } from './viewReducer';
import { policy } from './policyReducer';
import { drawing } from './drawingReducer';
import { damagePlotAreas } from './damagePlotAreaReducer';
import { radar } from './radarReducer';
import { woodwiserProject } from './woodwiserProjectReducer';
import { woodwiserForestUnit } from './woodwiserForestUnitReducer';
import { alert } from './alertReducer';
import { error } from './errorReducer';
import { mepar } from './meparReducer';
import { dialog } from './dialogReducer';
import { ek } from './ekReducer';
import { cemetery } from './cemeteryReducer';

const rootReducer = combineReducers({
    user,
    alert,
    block,
    policy,
    damage,
    frostDamage,
    map,
    view,
    damagePlotAreas,
    drawing,
    radar,
    woodwiserProject,
    woodwiserForestUnit,
    error,
    mepar,
    dialog,
    ek,
    cemetery,
});

export default rootReducer;
