import { useContext, useEffect, useState } from 'react';
import { toWgs84 } from 'reproject';
import epsg from 'epsg';
import { GoogleMapContext } from '@googlemap-react/core';
import { useDispatch, useSelector } from 'react-redux';
import { farmerModule } from 'shared/src/modules/farmerModule';
import { useHistory } from 'react-router';
import { FARMER_VIEWS } from 'shared';

export default function MeparLayer(props) {
    const [mapLayer, setMapLayer] = useState(null);
    const [mapFeatures, setMapFeatures] = useState(null);
    const mepars = useSelector((state) => state.mepar.mepars);
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const {
        state: { map },
    } = useContext(GoogleMapContext);
    const dispatch = useDispatch();
    const history = useHistory();
    const viewState = useSelector((state) => state.view.farmerViewState);
    const productDate = useSelector((state) => state.mepar.productDate);
    const alertDate = useSelector((state) => state.mepar.alertDate);
    const alertMepars = useSelector((state) => state.mepar.alertMepars);
    const policyPlotToDraw = useSelector((state) => state.mepar.policyPlotToDraw);

    function zoomToMepar(map, mapFeatures, selectedMepar) {
        const mapFeature = mapFeatures.find(
            (mapFeature) => mapFeature.getProperty('mepar').meparCode === selectedMepar.meparCode
        );
        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds();
        mapFeature.getGeometry().forEachLatLng((latlng) => {
            bounds.extend(latlng);
        });

        // todo: read https://stackoverflow.com/questions/2437683/google-maps-api-v3-can-i-setzoom-after-fitbounds/4065006#4065006

        map.fitBounds(bounds);
    }

    useEffect(() => {
        if (!map || !dispatch) {
            return;
        }

        // eslint-disable-next-line no-undef
        const mapLayer = new google.maps.Data({});

        mapLayer.setMap(map);

        setMapLayer(mapLayer);
    }, [map, dispatch]);

    useEffect(() => {
        if (!mapLayer || !selectedMepar) {
            return;
        }

        mapLayer.setStyle((feature) => {
            const mepar = feature.getProperty('mepar');
            const isSelected = selectedMepar.meparCode === mepar.meparCode;
            return {
                strokeColor: isSelected ? 'rgb(255, 255, 255)' : 'rgb(255,255,255, 0.2)', // '#BE0000', <== EK mepar color
                strokeWeight: isSelected ? 3 : 3,
                fillColor: 'rgb(255, 255, 255)',
                fillOpacity: isSelected ? 0 : 0.2,
            };
        });
    }, [mapLayer, selectedMepar]);

    useEffect(() => {
        if (!mapLayer) {
            return;
        }

        // eslint-disable-next-line no-undef
        google.maps.event.clearListeners('click');

        mapLayer.addListener('click', function (event) {
            const mepar = event.feature.getProperty('mepar');
            if (
                !policyPlotToDraw &&
                (!selectedMepar || selectedMepar.meparCode !== mepar.meparCode)
            ) {
                const newViewState =
                    viewState === FARMER_VIEWS.ALERT &&
                    alertMepars.find((alertMepar) => alertMepar.meparCode === mepar.meparCode)
                        ? FARMER_VIEWS.ALERT
                        : FARMER_VIEWS.NDVI;
                const url = farmerModule.composeUrl(mepar, newViewState, productDate, alertDate);
                history.push(url);
            }
        });
    }, [
        mapLayer,
        selectedMepar,
        policyPlotToDraw,
        alertDate,
        alertMepars,
        history,
        productDate,
        viewState,
    ]);

    useEffect(() => {
        if (!mapLayer || !mepars || !map) {
            return;
        }

        mapLayer.forEach((feature) => {
            mapLayer.remove(feature);
        });

        const mapFeatures = mepars.reduce((accumulator, mepar) => {
            const geoJson = {
                type: 'Feature',
                geometry: toWgs84(mepar.geom, 'EPSG:23700', epsg),
                properties: {
                    mepar,
                },
            };

            return [...accumulator, ...mapLayer.addGeoJson(geoJson)];
        }, []);

        setMapFeatures(mapFeatures);
    }, [mepars, map, mapLayer]);

    useEffect(() => {
        if (map && mapFeatures && selectedMepar) {
            zoomToMepar(map, mapFeatures, selectedMepar);
        }
    }, [map, mapFeatures, selectedMepar]);

    return null;
}
