import { ekConstants } from '../constants';

export const ekActions = {
    setEK,
    setProduct,
};

function setEK(ekCode) {
    return {
        type: ekConstants.SET_EK,
        payload: {
            ekCode,
        },
    };
}

function setProduct(product) {
    return {
        type: ekConstants.SET_PRODUCT,
        payload: {
            product,
        },
    };
}
