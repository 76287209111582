import { connect } from 'react-redux';
import DrawingsPanel from './DrawingsPanel';

const mapStateToProps = (state) => {
    const { damage, damagePlot, productType, product } = state.damage;
    const { drawing } = state.drawing;
    const { damagePlotAreas } = state.damagePlotAreas;
    return {
        damage,
        damagePlot,
        productType,
        product,
        drawing,
        damagePlotAreas,
    };
};

export default connect(mapStateToProps)(DrawingsPanel);