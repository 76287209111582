import { frostDamageConstants } from '../constants';

const initialState = {
    damages: [],
    danger: null,
    mepar: null,
    city: null,
};

export function frostDamage(state = initialState, action) {
    switch (action.type) {
        case frostDamageConstants.SET_FROST_DAMAGES:
            return {
                ...state,
                damages: action.payload.damages,
            };
        case frostDamageConstants.SET_FROST_DANGER:
            return {
                ...state,
                danger: action.payload.danger,
            };
        case frostDamageConstants.SET_FROST_MEPAR:
            return {
                ...state,
                mepar: action.payload.mepar,
            };
        case frostDamageConstants.SET_FROST_CITY:
            return {
                ...state,
                city: action.payload.city,
            };
        default:
            return state
    }
}
