import { LAYERS } from 'shared';
import { Fill, Stroke, Style, Text } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import GeoJSON from 'ol/format/GeoJSON';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { bbox } from 'ol/loadingstrategy';
import { getWidth } from 'ol/extent';
import Select from 'ol/interaction/Select';
import { click, pointerMove } from 'ol/events/condition';
import { frostDamageActions } from '../../state/actions';
import { layerService, meparService } from '../../services';

export default class FrostDamageLayer {
    map = null;
    layer = null;
    year = null;

    constructor(map, year = null) {
        this.map = map;
        this.year = year;
        this.init();
    }

    init() {
        const vectorSource = new VectorSource({
            format: new GeoJSON(),
            loader: async (extent) => {
                const extentPolygon = fromExtent(extent);
                const response = await layerService.getFrostDamages(extentPolygon);
                response.forEach(mepar => {
                    const meparPolygon = JSON.parse(mepar.polygon);
                    const feature = new Feature({
                        geometry: meparPolygon.type === 'Polygon' ? new Polygon(meparPolygon.coordinates) : new MultiPolygon(meparPolygon.coordinates),
                        mepar,
                    });
                    vectorSource.addFeature(feature);

                });
            },
            strategy: bbox,
        });

        this.layer = new VectorLayer({
            title: `Fagykár`,
            type: LAYERS.TYPES.FROST_DAMAGE,
            visible: false,
            renderMode: 'vector',
            declutter: true,
            source: vectorSource,
            maxResolution: 7,
            style: (feature) => {
                const mepar = feature.get('mepar');
                const isDamaged = mepar.damages && mepar.damages.length > 0;
                return new Style({
                    stroke: new Stroke({
                        color: isDamaged ? 'red' : 'yellow',
                        width: isDamaged ? 3 : 1,
                    }),
                    fill: new Fill({
                        color: 'rgba(0,0,0,0.00000001)',
                    }),
                    text: new Text({
                        font: '14px sans-serif',
                        overflow: true,
                        placement: 'center',
                        fill: new Fill({
                            color: 'white',
                        }),
                        text: mepar.meparCode,
                    }),
                    geometry: function (feature) {
                        let geometry = feature.getGeometry();
                        if (geometry.getType() === 'MultiPolygon') {
                            // Only render label for the widest polygon of a multipolygon
                            var polygons = geometry.getPolygons();
                            var widest = 0;
                            for (var i = 0, ii = polygons.length; i < ii; ++i) {
                                var polygon = polygons[i];
                                var width = getWidth(polygon.getExtent());
                                if (width > widest) {
                                    widest = width;
                                    geometry = polygon;
                                }
                            }
                        }
                        return geometry;
                    },

                });
            },
        });
    }

    initInteractions() {
        this.frostDamageLayerPointerSelect = new Select({
            condition: pointerMove,
            style: (feature) => {
                const mepar = feature.get('mepar');
                const isDamaged = mepar.damages && mepar.damages.length > 0;
                return new Style({
                    stroke: new Stroke({
                        color: 'green',
                        width: isDamaged ? 3 : 1,
                    }),
                    fill: new Fill({
                        color: 'rgba(0,0,0,0.00000001)',
                    }),
                    text: new Text({
                        font: '14px sans-serif',
                        overflow: true,
                        placement: 'center',
                        fill: new Fill({
                            color: 'white',
                        }),
                        text: mepar.meparCode,
                    }),
                });
            },
            layers: [this.layer],
        });

        this.frostDamageLayerClickSelect = new Select({
            condition: click,
            style: () => {},

            layers: [this.layer],
        });

        this.frostDamageLayerClickSelect.on('select', (event) => {
            const features = event.target.getFeatures();
            features.forEach(feature => {
                const mepar = feature.get('mepar');

                if (mepar) {
                    this.map.props.dispatch(frostDamageActions.setFrostDamages(mepar.damages));
                    this.map.props.dispatch(frostDamageActions.setFrostDanger(mepar.frost));
                    this.map.props.dispatch(frostDamageActions.setFrostMepar(mepar));
                }
            });
        });
    }

    addInteractions() {
        this.removeInteractions();
        this.map.map.addInteraction(this.frostDamageLayerPointerSelect);
        this.map.map.addInteraction(this.frostDamageLayerClickSelect);
    }

    removeInteractions() {
        this.map.map.removeInteraction(this.frostDamageLayerPointerSelect);
        this.map.map.removeInteraction(this.frostDamageLayerClickSelect);
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        this.layer.setVisible(layer.visible);
        this.initInteractions();
        this.addInteractions();

    }

    async setSelectedBlock(meparParam) {
        if (meparParam && this.layer.getVisible()) {
            const mepar = await meparService.getMeparByCode(meparParam.meparCode || meparParam.block.meparCode);
            const meparPolygon = mepar.geom;
            const feature = new Feature({
                geometry: meparPolygon.type === 'Polygon' ? new Polygon(meparPolygon.coordinates) : new MultiPolygon(meparPolygon.coordinates),
            });

            this.map.map.getView().fit(feature.getGeometry().getExtent(), this.map.map.getSize());
        }
    }

    async setSelectedCity(city) {
        if (city && this.layer.getVisible()) {
            const cityPolygon = city.geom;
            const feature = new Feature({
                geometry: cityPolygon.type === 'Polygon' ? new Polygon(cityPolygon.coordinates) : new MultiPolygon(cityPolygon.coordinates),
            });

            this.map.map.getView().fit(feature.getGeometry().getExtent(), this.map.map.getSize());
        }
    }
}