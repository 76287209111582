import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField/TextField';
import classNames from 'classnames';
import { damageActions, mapActions } from '../state/actions';
import SecondaryScreen from '../components/screens/SecondaryScreen';
import { withRouter } from 'react-router';
import { screens } from '../styles/styles';
import { FieldArray, Formik } from 'formik';
import Button from '@material-ui/core/Button/Button';
import { damageService } from '../services';
import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { LAYERS } from 'shared';
import { samplingService } from '../services/samplingService';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';

const styles = (theme) => ({
    layout: {
        minWidth: '400px',
        maxWidth: '400px',
        padding: '30px',
        marginTop: '30px',
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: '100%',
            maxWidth: '100%',
            flex: 1,
            padding: '30px',
            marginTop: '0px',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    controlGroup: {
        display: 'flex',
        flexDirection: 'column',
    },
    error: {
        color: 'rgba(255,0,0,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
        paddingBottom: '15px',
    },
    text: {
        color: 'rgba(50,50,50,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
    },
    checkLabel: {
        color: 'rgba(142,142,147,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
    },
    textField: {
        marginTop: '10px',
        '&:not(:last-child)': {
            marginBottom: '10px',
        },
    },
    saveButton: {
        marginTop: '30px',
        marginBottom: '50px',
        minHeight: '60px',
        maxHeight: '60px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: 'rgba(0,84,255,1)',
        '&:hover': {
            backgroundColor: '#004EEE',
        },
        boxShadow: '0 2px 4px 0 rgba(5,33,81,0.07)',
        color: theme.palette.text.primaryInverse,
        fontSize: '18px',
        textTransform: 'none',
    },
    passwordButton: {
        marginTop: '30px',
        minHeight: '60px',
        maxHeight: '60px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        },
        boxShadow: '0 2px 4px 0 rgba(5,33,81,0.07)',
        color: theme.palette.text.primaryInverse,
        fontSize: '18px',
        textTransform: 'none',
    },
    blockTitleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    blockTitle: {
        fontSize: '16px',
        marginTop: 10,
        marginBottom: 10,
    },
});

class Sampling extends React.Component {
    state = {
        error: null,
    };

    componentDidMount() {
        return this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.match.params.id !== prevProps.match.params.id ||
            this.props.location.search !== prevProps.location.search
        ) {
            return this.getData();
        }
    }

    async getData() {
        const {
            match: { params },
            location,
        } = this.props;

        let searchParams = new URLSearchParams(location.search);
        const damagePlotId = parseInt(searchParams.get('damagePlotId'), 10);

        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.COUNTRY,
                visible: true,
                fittingLayer: false,
            })
        );

        const damage = await damageService.getDamage(params.id);

        const damagePlotSummary = damage.damagePlots.find(
            (damagePlot) => damagePlot.id === damagePlotId
        );
        const damagePlot = await damageService.getDamagePlot(damagePlotSummary.id);

        this.props.dispatch(damageActions.setDamage(damage, damagePlot));
    }

    validate = (values) => {
        const errors = {};

        const requiredFields = [];

        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field] = 'Kötelező kitölteni';
            }
        });

        values.sampling.forEach((samplingItem, index) => {
            const requiredSamplingFields = ['yield'];

            requiredSamplingFields.forEach((field) => {
                if (!values.sampling[index][field] && values.sampling[index][field] !== 0) {
                    this.createSamplingError(errors, index, field, 'Kötelező kitölteni');
                }
            });

            if (
                values.sampling[index]['yield'] === 0 &&
                (!values.sampling[index]['comment'] || values.sampling[index]['comment'] === '')
            ) {
                this.createSamplingError(errors, index, 'comment', 'Kötelező kitölteni');
            }
        });

        return errors;
    };

    createSamplingError(errors, index, field, message) {
        if (!errors.sampling) {
            errors.sampling = [];
        }
        if (!errors.sampling[index]) {
            errors.sampling[index] = {};
        }
        errors.sampling[index][field] = message;
    }

    onSubmit = async (values, { setSubmitting, setStatus }) => {
        try {
            setStatus(undefined);
            await samplingService.saveSampling(this.props.damagePlot.id, values.sampling);
            this.props.history.goBack();
        } catch (error) {
            this.setState({
                error: error.message,
            });
            setSubmitting(false);
        }
    };

    render() {
        const { classes, damagePlot } = this.props;

        if (!damagePlot) {
            return null;
        }

        damagePlot.sampling.forEach((samplingItem) => {
            samplingItem.yield = samplingItem.yield || 0;
            samplingItem.comment = samplingItem.comment || '';
        });

        return (
            <SecondaryScreen title={'Mintavételi adatok'} showClose={true}>
                <div className={classNames(classes.layout)}>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale="hu" moment={moment}>
                        <Formik
                            initialValues={{
                                sampling: damagePlot.sampling,
                            }}
                            validate={this.validate}
                            onSubmit={this.onSubmit}
                        >
                            {({
                                touched,
                                errors,
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                            }) => (
                                <form
                                    name="sampling"
                                    onSubmit={handleSubmit}
                                    className={classes.form}
                                >
                                    <div className={classes.controlGroup}>
                                        <FieldArray
                                            name="sampling"
                                            render={({ remove, push, replace, form }) => (
                                                <>
                                                    {values.sampling.map((samplingItem, index) => (
                                                        <div
                                                            key={index}
                                                            className={classes.controlGroup}
                                                        >
                                                            <div className={classes.blockTitleRow}>
                                                                <Typography
                                                                    className={classes.blockTitle}
                                                                >
                                                                    {samplingItem.isGenerated
                                                                        ? 'Javasolt'
                                                                        : 'Hozzáadott'}{' '}
                                                                    mintavételi pont #
                                                                    {samplingItem.sequenceId}
                                                                </Typography>
                                                            </div>
                                                            <Typography className={classes.text}>
                                                                Hozam (to/ha)
                                                            </Typography>
                                                            <TextField
                                                                name={`sampling.${index}.yield`}
                                                                type="number"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                variant="outlined"
                                                                value={
                                                                    form.values.sampling[index]
                                                                        .yield
                                                                }
                                                                className={classes.textField}
                                                                error={
                                                                    !!(
                                                                        form.touched.sampling &&
                                                                        form.touched.sampling[
                                                                            index
                                                                        ] &&
                                                                        form.touched.sampling[index]
                                                                            .yield &&
                                                                        form.errors.sampling &&
                                                                        form.errors.sampling[
                                                                            index
                                                                        ] &&
                                                                        form.errors.sampling[index]
                                                                            .yield
                                                                    )
                                                                }
                                                                helperText={
                                                                    form.touched.sampling &&
                                                                    form.touched.sampling[index] &&
                                                                    form.touched.sampling[index]
                                                                        .yield &&
                                                                    form.errors.sampling &&
                                                                    form.errors.sampling[index] &&
                                                                    form.errors.sampling[index]
                                                                        .yield
                                                                }
                                                            />
                                                            <Typography className={classes.text}>
                                                                Megjegyzés
                                                            </Typography>
                                                            <TextField
                                                                name={`sampling.${index}.comment`}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                variant="outlined"
                                                                value={
                                                                    form.values.sampling[index]
                                                                        .comment
                                                                }
                                                                className={classes.textField}
                                                                error={
                                                                    !!(
                                                                        form.touched.sampling &&
                                                                        form.touched.sampling[
                                                                            index
                                                                        ] &&
                                                                        form.touched.sampling[index]
                                                                            .comment &&
                                                                        form.errors.sampling &&
                                                                        form.errors.sampling[
                                                                            index
                                                                        ] &&
                                                                        form.errors.sampling[index]
                                                                            .comment
                                                                    )
                                                                }
                                                                helperText={
                                                                    form.touched.sampling &&
                                                                    form.touched.sampling[index] &&
                                                                    form.touched.sampling[index]
                                                                        .comment &&
                                                                    form.errors.sampling &&
                                                                    form.errors.sampling[index] &&
                                                                    form.errors.sampling[index]
                                                                        .comment
                                                                }
                                                            />
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <Button
                                        className={classNames(classes.saveButton)}
                                        type="submit"
                                    >
                                        Mentés
                                    </Button>
                                </form>
                            )}
                        </Formik>
                    </MuiPickersUtilsProvider>
                </div>
            </SecondaryScreen>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.user;
    const { damagePlot } = state.damage;

    return {
        user,
        damagePlot,
    };
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Sampling)));
