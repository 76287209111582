import { Tooltip, withStyles } from '@material-ui/core';

export const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#333333',
        color: 'rgba(255, 255, 255, 1)',
        fontSize: '16px',
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
}))(Tooltip);
