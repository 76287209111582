import * as React from 'react';
import { VIEWS } from 'shared/src/constants/views';
import { List, ListItem, Typography, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { useSelector } from 'react-redux';
import Left from '@material-ui/icons/ArrowBack';
import Info from '@material-ui/icons/Info';
import { useHistory, useLocation } from 'react-router';
import { PRODUCTS } from 'shared';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { ROLE } from 'shared/src/constants/role';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    titleRow: {
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    titleWithInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    list: {
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    listItem: {
        paddingTop: '5px',
        paddingBottom: '0px',
    },
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#333333',
        color: 'rgba(255, 255, 255, 1)',
        fontSize: '18px',
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
}))(Tooltip);

function getTitle(viewState, productType, blockProductType, ekProductType, blockPlot) {
    if (!viewState) {
        return '';
    }
    switch (viewState.view) {
        case VIEWS.DAMAGE:
            return 'Kárakta';
        case VIEWS.DAMAGE_PLOT:
            return 'Károsodott tábla';
        case VIEWS.DRAWING:
            return 'Táblahatár / Mérés';
        case VIEWS.BLOCK_DRAWING:
            return 'Táblahatár';
        case VIEWS.SAMPLING:
            return 'Mintavétel';
        case VIEWS.PRODUCT:
            return PRODUCTS.DETAILS[productType].name;
        case VIEWS.POLICY:
            return 'Kötvény';
        case VIEWS.POLICY_PLOT:
            return 'Biztosított tábla';
        case VIEWS.BLOCK:
            return 'MePAR blokk';
        case VIEWS.BLOCK_PRODUCT:
            return PRODUCTS.DETAILS[blockProductType].name;
        case VIEWS.RADAR:
            return 'Erőforrás allokáció támogatása';
        case VIEWS.WOODWISER_PROJECT:
            return 'Projekt';
        case VIEWS.WOODWISER_FOREST_UNIT:
            return 'Terület';
        case VIEWS.AGRO_HOME:
            return 'Összes tábla';
        case VIEWS.AGRO_BLOCK_PLOT:
            return `Tábla: ${blockPlot.name}`;
        case VIEWS.EK:
            return 'Egységes kérelem';
        case VIEWS.EK_PRODUCT:
            return ekProductType ? PRODUCTS.DETAILS[ekProductType].name : '';
        case VIEWS.DAMAGE_CHRONOLOGY:
            return 'Kárkronológia';
        case VIEWS.FROST_DAMAGE:
            return 'Fagyveszély';
        default:
            return '';
    }
}

export default function InfoPanelHeader() {
    const classes = useStyles();
    const viewState = useSelector((state) => state.view.viewState);
    const productType = useSelector((state) => state.damage.productType);
    const blockProductType = useSelector((state) => state.block.productType);
    const blockPlot = useSelector((state) => state.mepar.blockPlot);
    const meparProductType = useSelector((state) => state.mepar.productType);
    const user = useSelector((state) => state.user.user);
    const history = useHistory();
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const ekProductType = searchParams.get('productType');

    function onClose() {
        history.goBack();
    }

    if (!viewState || viewState.view === VIEWS.PROGRESS) {
        return null;
    }

    return (
        <List className={classes.list}>
            <ListItem className={classes.listItem}>
                <div className={classes.layout}>
                    <div className={classes.titleRow}>
                        <div className={classes.titleWithInfo}>
                            <Typography className={classes.title}>
                                {getTitle(
                                    viewState,
                                    productType,
                                    blockProductType,
                                    ekProductType,
                                    blockPlot
                                )}
                            </Typography>
                            {viewState.view === VIEWS.PRODUCT && (
                                <HtmlTooltip
                                    disableFocusListener
                                    title={PRODUCTS.DETAILS[productType].description}
                                    leaveDelay={500}
                                >
                                    <IconButton>
                                        <Info />
                                    </IconButton>
                                </HtmlTooltip>
                            )}
                            {viewState.view === VIEWS.EK_PRODUCT && (
                                <HtmlTooltip
                                    disableFocusListener
                                    title={
                                        ekProductType
                                            ? PRODUCTS.DETAILS[ekProductType].description
                                            : ''
                                    }
                                    leaveDelay={500}
                                >
                                    <IconButton>
                                        <Info />
                                    </IconButton>
                                </HtmlTooltip>
                            )}
                            {viewState.view === VIEWS.BLOCK_PRODUCT && (
                                <HtmlTooltip
                                    disableFocusListener
                                    title={PRODUCTS.DETAILS[blockProductType].description}
                                    leaveDelay={500}
                                >
                                    <IconButton>
                                        <Info />
                                    </IconButton>
                                </HtmlTooltip>
                            )}
                            {viewState.view === VIEWS.AGRO_BLOCK_PLOT && meparProductType && (
                                <HtmlTooltip
                                    disableFocusListener
                                    title={PRODUCTS.DETAILS[meparProductType].description}
                                    leaveDelay={500}
                                >
                                    <IconButton>
                                        <Info />
                                    </IconButton>
                                </HtmlTooltip>
                            )}
                        </div>
                        {user.role !== ROLE.FARMER && (
                            <IconButton onClick={onClose}>
                                <Left />
                            </IconButton>
                        )}
                    </div>
                </div>
            </ListItem>
        </List>
    );
}
