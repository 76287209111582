import { sendServerRequest } from './baseService';

export const meparService = {
    getMepars,
    getMeparByCode,
};

async function getMepars() {
    return sendServerRequest('GET', '/api/mepars');
}

async function getMeparByCode(meparCode) {
    return sendServerRequest('GET', `/api/mepars/${meparCode}`);
}
