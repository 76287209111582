export const logService = {
    saveLog,
};

async function saveLog(type, resource) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            resource,
        }),
    };

    const response = await fetch(`/api/logs/${type}`, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}
