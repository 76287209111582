import { alertConstants } from '../constants';

const initialState = {
    alerts: [],
};

export function alert(state = initialState, action) {
    switch (action.type) {
        case alertConstants.SET_ALERTS:
            return {
                ...state,
                alerts: action.payload.alerts,
            };
        default:
            return state;
    }
}
