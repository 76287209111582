import { drawingConstants } from '../constants';

export const drawingActions = {
    setDrawing,
};

function setDrawing(drawing) {
    return {
        type: drawingConstants.SET_DRAWING,
        payload: {
            drawing,
        },
    };
}
