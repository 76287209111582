import { getArea } from 'ol/sphere';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';

export function getHectareGeomAreaFromDrawing(drawing) {
    const polygon =
        drawing.type === 'Polygon'
            ? new Polygon(drawing.coordinates)
            : new MultiPolygon(drawing.coordinates);
    return getGeomArea(polygon) / 10000;
}

export function getGeomArea(polygon) {
    return getArea(polygon, {
        projection: 'EPSG:23700',
    });
}

export function roundHectareArea(area) {
    return Math.round((area + Number.EPSILON) * 100) / 100;
}

export function roundSqMeterArea(area) {
    return Math.round((area + Number.EPSILON) / 100) / 100;
}
