import { sendServerRequest } from './baseService';

export const userService = {
    login,
    logout,
    loginWithLinkToken,
    getCurrentUser,
    updateCurrentUser,
    updateCurrentUserPassword,
    suppressWelcomeScreen,
};

async function login(username, password) {
    const body = {
        grant_type: 'password',
        username,
        password,
    };
    localStorage.setItem('envimap-admin', 'false');

    const token = await sendServerRequest('POST', `/api/oauth2/token`, body);

    const accessToken = token.access_token;
    localStorage.setItem('jwt', accessToken);
}

function logout() {
    localStorage.removeItem('jwt');
}

async function loginWithLinkToken(userId, linkToken, isAdmin) {
    const body = {
        userId,
        linkToken,
    };

    localStorage.setItem('envimap-admin', `${isAdmin}`);

    const { token: accessToken } = await sendServerRequest('POST', '/api/oauth2/linkToken', body);

    localStorage.setItem('jwt', accessToken);
}

async function suppressWelcomeScreen() {
    return sendServerRequest('POST', '/api/suppressWelcomeScreen');
}

async function getCurrentUser() {
    let token = null;
    try {
        token = localStorage.getItem('jwt');
    } catch (error) {}

    if (!token) {
        return null;
    }

    return sendServerRequest('GET', '/api/currentUser');
}

async function updateCurrentUser(userUpdate) {
    return sendServerRequest('POST', '/api/currentUser', userUpdate);
}

async function updateCurrentUserPassword(currentPassword, newPassword) {
    const body = {
        currentPassword: currentPassword,
        newPassword: newPassword,
    };

    return sendServerRequest('POST', '/api/currentUserPassword', body);
}
