import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { blockActions, mapActions, viewActions } from '../state/actions';
import { LAYERS } from 'shared/src/constants/layers';
import { VIEWS } from 'shared/src/constants/views';
import { blockService } from '../services';

class BlockController extends React.Component {
    componentDidMount() {
        return this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.match.params.id !== prevProps.match.params.id ||
            this.props.location.search !== prevProps.location.search
        ) {
            return this.getData();
        }
    }

    async getData() {
        const {
            match: { params },
            location,
        } = this.props;

        let searchParams = new URLSearchParams(location.search);
        const productType = searchParams.get('productType');

        this.props.dispatch(viewActions.setInfoPanelState({ open: true }));
        this.props.dispatch(viewActions.setViewState({ view: VIEWS.PROGRESS }));

        this.props.dispatch(mapActions.resetLayers());
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.COUNTRY,
                visible: true,
                fittingLayer: false,
            })
        );

        const block = await blockService.getBlock(params.id);
        this.props.dispatch(blockActions.setBlock(block, productType));
        if (block.meparPolygon && JSON.parse(block.meparPolygon)) {
            this.props.dispatch(
                mapActions.updateLayer({
                    type: LAYERS.TYPES.PLOT,
                    visible: true,
                    plots: [block],
                    fittingLayer: true,
                })
            );
        } else {
            this.props.dispatch(
                mapActions.updateLayer({ type: LAYERS.TYPES.PLOT, visible: false })
            );
            this.props.dispatch(
                mapActions.updateLayer({
                    type: LAYERS.TYPES.COUNTRY,
                    visible: true,
                    fittingLayer: true,
                })
            );
        }

        if (productType) {
            const product = block.products.filter((product) => product.type === productType)[0];
            this.props.dispatch(blockActions.setProduct(product));
            this.props.dispatch(
                mapActions.updateLayer({
                    type: LAYERS.TYPES.PRODUCT,
                    visible: true,
                    product,
                    block,
                })
            );
        }

        if (productType) {
            this.props.dispatch(viewActions.setViewState({ view: VIEWS.BLOCK_PRODUCT }));
        } else {
            this.props.dispatch(viewActions.setViewState({ view: VIEWS.BLOCK }));
        }
    }

    render() {
        return null;
    }
}

export default connect()(withRouter(BlockController));
