export const woodwiserForestUnitActions = {
    setWoodwiserForestUnit,
    SET_WOODWISER_FOREST_UNIT: 'SET_WOODWISER_FOREST_UNIT',
};

function setWoodwiserForestUnit(woodwiserForestUnit) {
    return {
        type: woodwiserForestUnitActions.SET_WOODWISER_FOREST_UNIT,
        payload: {
            woodwiserForestUnit,
        },
    };
}
