import fetchWithTimeout from '../helpers/fetchWithTimeOut';

export const samplingService = {
    removeSampling,
    saveSampling,
    startZonesGeneration,
};

async function saveSampling(damagePlotId, sampling) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            damagePlotId,
            sampling,
        }),
    };

    const response = await fetch(
        `/api/damagePlots/sampling/${encodeURIComponent(damagePlotId)}`,
        options
    );

    return response.json();
}

async function removeSampling(damagePlotId, sequenceId) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await fetch(
        `/api/damagePlots/sampling/remove/${encodeURIComponent(damagePlotId)}/${encodeURIComponent(sequenceId)}`,
        options
    );

    return response.json();
}

async function startZonesGeneration(damagePlotId) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-admin': `${isAdmin}`,
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await fetchWithTimeout(
            `/api/damagePlots/generateZones/${encodeURIComponent(damagePlotId)}`,
            options
        );

        return response.json();
    } catch (err) {
        console.log(err);
        return { err: 'A zónagenerálás sikertelen volt. Ellenőrizze az internetkapcsolatát!' };
    }
}
