import { Divider, List, ListItem, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { LAYERS, PRODUCTS, ROUTES } from 'shared';
import Button from '@material-ui/core/Button/Button';
import { mapActions, meparActions } from '../../state/actions';
import { useHistory } from 'react-router';

moment.locale('hu');

const useStyles = makeStyles((theme) => ({
    progress: {
        margin: theme.spacing(2),
    },
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    navigationRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    navigationNumbers: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    blockButtonHighlighted: {
        backgroundColor: 'rgba(200,200,200,0.5)',
    },
    buttonLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonInfoRowLayout: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    buttonTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    buttonLabel: {
        fontSize: '12px',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: '12px',
    },
    productListWrapper: {
        flex: 1,
        overflow: 'auto',
    },
    productList: {},
    expansion: {
        width: '100%',
    },
    list: {
        paddingTop: '0px',
    },
    listItem: {
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    panelLayout: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    description: {
        fontSize: '14px',
        paddingBottom: '10px',
    },
    drawingButton: {
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
    },
    productRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    dividerTop: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    dividerBottom: {
        marginTop: '10px',
    },
}));

export default function AgroBlockPlotPanel() {
    const classes = useStyles();
    const mepars = useSelector((state) => state.mepar.mepars);
    const blockPlot = useSelector((state) => state.mepar.blockPlot);
    const productType = useSelector((state) => state.mepar.productType);
    const product = useSelector((state) => state.mepar.product);
    const history = useHistory();
    const [mepar, setMepar] = useState(null);
    const [productTypeMap, setProductTypeMap] = useState({});
    const dispatch = useDispatch();

    const showProduct = useCallback(
        (product) => {
            dispatch(meparActions.setProduct(product));
            if (product.type === PRODUCTS.TYPES.SOIL_MANAGEMENT_ZONE) {
                dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.BLOCK_PLOT_SOIL_MANAGEMENT_ZONE,
                        visible: true,
                        product,
                    })
                );
            } else {
                dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.BLOCK_PLOT_PRODUCT,
                        visible: true,
                        product,
                    })
                );
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (!mepars || !blockPlot) {
            return;
        }

        const mepar =
            mepars &&
            mepars.find((mepar) =>
                mepar.blockPlots.find((meparBlockPlot) => meparBlockPlot.id === blockPlot.id)
            );
        setMepar(mepar);
    }, [mepars, blockPlot]);

    useEffect(() => {
        if (!mepar || !blockPlot) {
            return;
        }

        const productTypeMap =
            mepar && mepar.agroProducts
                ? mepar.agroProducts.reduce((accumulator, product) => {
                      if (product.blockPlotId && product.blockPlotId !== blockPlot.id) {
                          return accumulator;
                      }
                      const productsInType = accumulator[product.type] || [];
                      productsInType.push(product);
                      return Object.assign(accumulator, {
                          [product.type]: productsInType,
                      });
                  }, {})
                : {};

        setProductTypeMap(productTypeMap);
    }, [mepar, blockPlot]);

    useEffect(() => {
        if (!mepars || !mepar || !productType || !productTypeMap[productType]) {
            return;
        }

        const product = productTypeMap[productType][0];
        showProduct(product);
    }, [dispatch, mepars, mepar, productType, productTypeMap, showProduct]);

    if (!mepars || !blockPlot) {
        return null;
    }

    const onShowProduct = (product) => () => {
        history.push(`${ROUTES.BLOCK_PLOT(blockPlot.id)}?productType=${product.type}`);
    };

    function getLabelFromSubType(subType) {
        if (subType === 'BMI') {
            return 'Biomassza intenzitás';
        }

        if (subType === 'TCI') {
            return 'Látható felvétel';
        }

        return subType;
    }

    const onShowProductItem = (product) => () => {
        showProduct(product);
    };

    return (
        <List className={classes.layout}>
            <ListItem>
                <div className={classes.layout}>
                    <div className={classes.row}>
                        <Typography className={classes.label}>MePAR:</Typography>
                        <Typography className={classes.value}>
                            {blockPlot.meparCode ? blockPlot.meparCode : 'Nincs adat'}
                        </Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Terület:</Typography>
                        <Typography className={classes.value}>
                            {blockPlot.area ? `${blockPlot.area} ha` : 'Nincs adat'}
                        </Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Növény:</Typography>
                        <Typography className={classes.value}>
                            {blockPlot.plantName || 'Nincs adat'}
                        </Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Term. menny.:</Typography>
                        <Typography className={classes.value}>
                            {blockPlot.yield || 'Nincs adat'}
                        </Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Term. átlag:</Typography>
                        <Typography className={classes.value}>
                            {blockPlot.yield || 'Nincs adat'}
                        </Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Diff. kijuttatás:</Typography>
                        <Typography className={classes.label}>
                            {blockPlot.differentialSpraying ? 'Igen' : 'Nem'}
                        </Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Diff. kij. típusa:</Typography>
                        <Typography className={classes.label}>
                            {blockPlot.differentialSprayingType || 'Nincs adat'}
                        </Typography>
                    </div>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Öntözés:</Typography>
                        <Typography className={classes.label}>
                            {blockPlot.irrigation ? 'Igen' : 'Nem'}
                        </Typography>
                    </div>
                </div>
                <Divider />
            </ListItem>
            {/*
            <ListItem>
                <Button variant="contained" color="default" size="small" className={classes.button}
                        onClick={onShowDrawings(blockPlot)}>
                    {`Táblahatár / Mérés`}
                </Button>
            </ListItem>
*/}
            {!productType && (
                <>
                    {productTypeMap[PRODUCTS.TYPES.CHRONOLOGY] && (
                        <ListItem>
                            <Button
                                variant="contained"
                                color="default"
                                size="small"
                                className={classes.button}
                                onClick={onShowProduct(
                                    productTypeMap[PRODUCTS.TYPES.CHRONOLOGY][0]
                                )}
                            >
                                {`Kronológia`}
                            </Button>
                        </ListItem>
                    )}
                    {productTypeMap[PRODUCTS.TYPES.SOIL_MANAGEMENT_ZONE] && (
                        <ListItem>
                            <Button
                                variant="contained"
                                color="default"
                                size="small"
                                className={classes.button}
                                onClick={onShowProduct(
                                    productTypeMap[PRODUCTS.TYPES.SOIL_MANAGEMENT_ZONE][0]
                                )}
                            >
                                {`Menedzsment zóna`}
                            </Button>
                        </ListItem>
                    )}
                </>
            )}
            {productType && (
                <ListItem className={classes.listItem}>
                    <img src={PRODUCTS.DETAILS[productType].legend} width={'290px'} alt={''} />
                </ListItem>
            )}
            {productType && productType !== PRODUCTS.TYPES.SOIL_MANAGEMENT_ZONE && (
                <div className={classes.productListWrapper}>
                    <List className={classes.productList}>
                        {product &&
                            productTypeMap[product.type] &&
                            productTypeMap[product.type].length === 1 && (
                                <>
                                    {product.dateBefore && (
                                        <ListItem>
                                            <div className={classes.layout}>
                                                <div className={classes.row}>
                                                    <Typography className={classes.label}>
                                                        Előtte felvétel:
                                                    </Typography>
                                                    <Typography className={classes.value}>
                                                        {`${moment(product.dateBefore).format(
                                                            'YYYY. MM. DD.'
                                                        )}`}
                                                    </Typography>
                                                </div>
                                                <div className={classes.row}>
                                                    <Typography className={classes.label}>
                                                        Utána felvétel:
                                                    </Typography>
                                                    <Typography className={classes.value}>
                                                        {`${moment(product.date).format(
                                                            'YYYY. MM. DD.'
                                                        )}`}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </ListItem>
                                    )}
                                </>
                            )}
                        {product &&
                            productTypeMap[product.type] &&
                            productTypeMap[product.type].length > 1 &&
                            productTypeMap[product.type].map((productItem) => (
                                <ListItem key={productItem.id}>
                                    <div className={classes.productRow} key={productItem.id}>
                                        <Button
                                            className={classes.button}
                                            variant="contained"
                                            color={
                                                product.id === productItem.id
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                            size="small"
                                            onClick={onShowProductItem(productItem)}
                                        >
                                            {productItem.date ? (
                                                productItem.subType ? (
                                                    <>
                                                        {getLabelFromSubType(productItem.subType)}
                                                        <br />
                                                        {moment(productItem.date).format(
                                                            'YYYY. MM. DD.'
                                                        )}
                                                    </>
                                                ) : (
                                                    moment(productItem.date).format('YYYY. MM. DD.')
                                                )
                                            ) : (
                                                'Megjelenít'
                                            )}
                                            {productItem.dateBefore && (
                                                <>
                                                    <br />
                                                    {`${moment(productItem.dateBefore).format(
                                                        'YYYY. MM. DD.'
                                                    )} - ${moment(productItem.date).format(
                                                        'YYYY. MM. DD.'
                                                    )}`}
                                                </>
                                            )}
                                        </Button>
                                    </div>
                                </ListItem>
                            ))}
                    </List>
                </div>
            )}
        </List>
    );
}
