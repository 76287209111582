import { cemeteryActions } from '../actions/cemeteryActions';

const initialState = {
    graveyard: null,
    graves: [],
    selectedDeads: [],
    selectedGraves: [],
};

export function cemetery(state = initialState, action) {
    switch (action.type) {
        case cemeteryActions.SET_GRAVEYARD:
            return {
                ...state,
                graveyard: action.payload.graveyard,
            };
        case cemeteryActions.SET_GRAVES:
            return {
                ...state,
                graves: action.payload.graves,
            };
        case cemeteryActions.SET_SELECTED_DEADS:
            return {
                ...state,
                selectedDeads: action.payload.deads,
                selectedGraves: [],
            };
        case cemeteryActions.SET_SELECTED_GRAVES:
            return {
                ...state,
                selectedDeads: [],
                selectedGraves: action.payload.graves,
            };
        default:
            return state;
    }
}
