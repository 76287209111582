import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import Modify from 'ol/interaction/Modify';
import { drawingActions } from '../../state/actions';
import Overlay from 'ol/Overlay';
import VectorLayer from 'ol/layer/Vector';
import { LAYERS } from 'shared';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import MultiPoint from 'ol/geom/MultiPoint';
import Draw from 'ol/interaction/Draw';
import { getGeomArea, roundSqMeterArea } from '../../helpers/areaCalculator';

export default class DrawingLayer {
    map = null;
    layer = null;
    sketch = null;
    draw = null;
    modify = null;
    measureTooltipElement = null;
    measureTooltip = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    getLayer() {
        return this.layer;
    }

    init() {
        this.source = new VectorSource();

        this.layer = new VectorLayer({
            title: 'Rajzolás',
            type: LAYERS.TYPES.DRAWING,
            visible: true,
            displayInLayerSwitcher: false,
            source: this.source,
            style: [
                new Style({
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)',
                    }),
                    stroke: new Stroke({
                        color: '#ffcc33',
                        width: 2,
                    }),
                }),
                new Style({
                    image: new CircleStyle({
                        radius: 5,
                        fill: new Fill({
                            color: '#ffcc33',
                        }),
                    }),
                    geometry: function (feature) {
                        var coordinates = feature.getGeometry().getCoordinates()[0];
                        return new MultiPoint(coordinates);
                    },
                }),
            ],
        });

        const type = 'Polygon';
        this.draw = new Draw({
            source: this.source,
            type: type,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.5)',
                    lineDash: [10, 10],
                    width: 2,
                }),
                image: new CircleStyle({
                    radius: 5,
                    stroke: new Stroke({
                        color: 'rgba(0, 0, 0, 0.7)',
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)',
                    }),
                }),
            }),
            layers: [this.layer],
        });

        this.modify = new Modify({
            source: this.source,
            layers: [this.layer],
        });

        this.draw.on('drawstart', (evt) => {
            // set sketch
            this.source.clear();
            this.sketch = evt.feature;

            this.map.map.addOverlay(this.measureTooltip);

            this.sketch.getGeometry().on('change', (evt) => {
                const geom = evt.target;
                const output = this.formatArea(geom);
                this.tooltipCoord = geom.getInteriorPoint().getCoordinates();
                this.measureTooltipElement.innerHTML = output;
                this.measureTooltip.setPosition(this.tooltipCoord);

                const drawing = this.map.props.drawing;
                drawing.coordinates = evt.target.getCoordinates();
                this.map.props.dispatch(drawingActions.setDrawing(drawing));
            });
        });

        this.draw.on('drawend', (evt) => {
            // unset tooltip so that a new one can be created
            this.map.map.removeInteraction(this.draw);
            this.map.map.addInteraction(this.modify);
        });

        this.measureTooltipElement = document.createElement('div');
        this.measureTooltipElement.className = 'tooltip tooltip-measure';
        this.measureTooltip = new Overlay({
            element: this.measureTooltipElement,
            offset: [0, -15],
            positioning: 'bottom-center',
        });
    }

    getArea = (polygon) => {
        return getGeomArea(polygon, {
            projection: 'EPSG:23700',
        });
    };

    formatArea = (polygon) => {
        const area = getGeomArea(polygon);
        return `${roundSqMeterArea(area)} ha`;
    };

    removeInteraction = () => {
        if (this.draw) {
            this.map.map.removeInteraction(this.draw);
        }
        if (this.modify) {
            this.map.map.removeInteraction(this.modify);
        }
        if (this.measureTooltip) {
            this.map.map.removeOverlay(this.measureTooltip);
        }
    };

    updateDrawing(drawing) {
        this.removeInteraction();
        this.source.clear();

        if (drawing && drawing.coordinates) {
            const feature = new Feature({
                geometry:
                    drawing.type === 'Polygon'
                        ? new Polygon(drawing.coordinates)
                        : new MultiPolygon(drawing.coordinates),
            });
            this.source.addFeature(feature);

            feature.getGeometry().on('change', (evt) => {
                const geom = evt.target;
                const output = this.formatArea(geom);

                this.tooltipCoord = geom.getInteriorPoint
                    ? geom.getInteriorPoint().getCoordinates()
                    : geom.getInteriorPoints().getCoordinates()[0].slice(0, 2);

                this.measureTooltipElement.innerHTML = output;
                this.measureTooltip.setPosition(this.tooltipCoord);

                drawing.coordinates = evt.target.getCoordinates();
                this.map.props.dispatch(drawingActions.setDrawing(drawing));
            });

            this.map.map.addInteraction(this.modify);
            this.map.map.addOverlay(this.measureTooltip);

            const geom = feature.getGeometry();
            const output = this.formatArea(geom);
            this.tooltipCoord = geom.getInteriorPoint
                ? geom.getInteriorPoint().getCoordinates()
                : geom.getInteriorPoints().getCoordinates()[0].slice(0, 2);
            this.measureTooltipElement.innerHTML = output;
            this.measureTooltip.setPosition(this.tooltipCoord);
        } else if (drawing) {
            this.source.clear();
            this.map.map.addInteraction(this.draw);
        }
    }

    update(layer) {
        if (!layer.visible) {
            this.removeInteraction();
            this.source.clear();
        }
        this.layer.setVisible(layer.visible);
    }
}
