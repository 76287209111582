export const tokenModule = {
    composeCompoundLinkToken,
    parseCompoundLinkToken,
};

function composeCompoundLinkToken(user) {
    return `${user.id}-${user.linkToken}`;
}

function parseCompoundLinkToken(compoundLinkToken) {
    const matches = compoundLinkToken.match(/^([0-9]+)-(.*)$/);

    if (matches.length < 2) {
        throw new Error(`Invalid compound link token ${compoundLinkToken}`);
    }

    const userId = matches[1];
    const linkToken = matches[2];

    return {
        userId,
        linkToken,
    };
}
