import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField/TextField';
import classNames from 'classnames';
import { userActions } from '../state/actions';
import SecondaryScreen from '../components/screens/SecondaryScreen';
import { withRouter } from 'react-router';
import { screens } from '../styles/styles';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button/Button';
import { userService } from '../services';
import { connect } from 'react-redux';

const styles = (theme) => ({
    layout: {
        minWidth: '400px',
        maxWidth: '400px',
        padding: '30px',
        marginTop: '30px',
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: '100%',
            maxWidth: '100%',
            flex: 1,
            padding: '30px',
            marginTop: '0px',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    controlGroup: {
        display: 'flex',
        flexDirection: 'column',
    },
    error: {
        color: 'rgba(255,0,0,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
        paddingBottom: '15px',
    },
    text: {
        color: 'rgba(50,50,50,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
    },
    checkLabel: {
        color: 'rgba(142,142,147,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
    },
    textField: {
        marginTop: '11px',
        '&:not(:last-child)': {
            marginBottom: '30px',
        },
    },
    saveButton: {
        marginTop: '30px',
        marginBottom: '50px',
        minHeight: '60px',
        maxHeight: '60px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: 'rgba(0,84,255,1)',
        '&:hover': {
            backgroundColor: '#004EEE',
        },
        boxShadow: '0 2px 4px 0 rgba(5,33,81,0.07)',
        color: theme.palette.text.primaryInverse,
        fontSize: '18px',
        textTransform: 'none',
    },
    passwordButton: {
        marginTop: '30px',
        minHeight: '60px',
        maxHeight: '60px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        },
        boxShadow: '0 2px 4px 0 rgba(5,33,81,0.07)',
        color: theme.palette.text.primaryInverse,
        fontSize: '18px',
        textTransform: 'none',
    },
});

class UserProfile extends React.Component {
    state = {
        error: null,
    };

    validate = (values) => {
        const errors = {};

        const requiredFields = ['name'];

        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field] = 'Kötelező kitölteni';
            }
        });

        return errors;
    };

    validatePassword = (values) => {
        console.log(values);

        const errors = {};

        const requiredFields = ['currentPassword', 'newPassword', 'newPasswordRepeat'];

        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field] = 'Kötelező kitölteni';
            }
        });

        const uniqueCharactersInPassword =
            values.newPassword &&
            values.newPassword
                .split('')
                .filter(function (item, i, ar) {
                    return ar.indexOf(item) === i;
                })
                .join('');

        if (
            (values.newPassword && values.newPassword.length < 8) ||
            (uniqueCharactersInPassword && uniqueCharactersInPassword.length < 4)
        ) {
            errors.newPassword =
                'A megadott jelszó nem megfelelő (minimum 8 karakter, minimum 4 különböző karakter)!';
        }

        if (
            values.newPasswordRepeat &&
            values.newPassword &&
            values.newPasswordRepeat !== values.newPassword
        ) {
            errors.newPasswordRepeat = 'A megismételt jelszó nem azonos az új jelszóval!';
        }

        return errors;
    };

    onSubmit = async (values, { setSubmitting, setStatus }) => {
        try {
            setStatus(undefined);
            const { name } = values;
            const user = await userService.updateCurrentUser({ name });
            this.props.dispatch(userActions.setUser(user));
            this.props.history.goBack();
        } catch (error) {
            this.setState({
                error: error.message,
            });
            setSubmitting(false);
        }
    };

    onSubmitPassword = async (values, { setSubmitting, setStatus }) => {
        console.log(values);
        try {
            setStatus(undefined);
            const { currentPassword, newPassword } = values;
            const result = await userService.updateCurrentUserPassword(
                currentPassword,
                newPassword
            );
            if (result.error) {
                this.setState({
                    error: result.error,
                });
                return;
            }
            this.props.dispatch(userActions.setUser(null));
            this.props.history.goBack();
        } catch (error) {
            this.setState({
                error: error.message,
            });
            setSubmitting(false);
        }
    };

    render() {
        const { classes, user } = this.props;
        const { error } = this.state;

        return (
            <SecondaryScreen title={'Felhasználói adatok'} showClose={true}>
                <div className={classNames(classes.layout)}>
                    <Formik initialValues={user} validate={this.validate} onSubmit={this.onSubmit}>
                        {({ touched, errors, handleSubmit, handleChange, handleBlur, values }) => (
                            <form
                                name="editUserProfile"
                                onSubmit={handleSubmit}
                                className={classes.form}
                            >
                                {error && (
                                    <Typography className={classes.error}>{error}</Typography>
                                )}

                                <div className={classes.controlGroup}>
                                    <Typography className={classes.text}>Név</Typography>
                                    <TextField
                                        name="name"
                                        autoFocus={true}
                                        autoComplete={'name'}
                                        error={!!(touched.name && errors.name)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        variant="outlined"
                                        helperText={touched.name && errors.name}
                                        className={classes.textField}
                                    />
                                </div>
                                <Button className={classNames(classes.saveButton)} type="submit">
                                    Módosítások mentése
                                </Button>
                            </form>
                        )}
                    </Formik>
                    <Formik
                        initialValues={{
                            currentPassword: '',
                            newPassword: '',
                            newPasswordRepeat: '',
                        }}
                        validate={this.validatePassword}
                        onSubmit={this.onSubmitPassword}
                    >
                        {({ touched, errors, handleSubmit, handleChange, handleBlur, values }) => (
                            <form name="form2" onSubmit={handleSubmit} className={classes.form}>
                                {error && (
                                    <Typography className={classes.error}>{error}</Typography>
                                )}

                                <div className={classes.controlGroup}>
                                    <Typography className={classes.text}>
                                        Jelenlegi jelszó
                                    </Typography>
                                    <TextField
                                        name="currentPassword"
                                        type="password"
                                        error={
                                            !!(touched.currentPassword && errors.currentPassword)
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.currentPassword}
                                        variant="outlined"
                                        helperText={
                                            touched.currentPassword && errors.currentPassword
                                        }
                                        className={classes.textField}
                                    />

                                    <Typography className={classes.text}>Új jelszó</Typography>
                                    <TextField
                                        name="newPassword"
                                        type="password"
                                        error={!!(touched.newPassword && errors.newPassword)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.newPassword}
                                        variant="outlined"
                                        helperText={touched.newPassword && errors.newPassword}
                                        className={classes.textField}
                                    />
                                    <Typography className={classes.text}>
                                        Új jelszó ismétlése
                                    </Typography>
                                    <TextField
                                        name="newPasswordRepeat"
                                        type="password"
                                        error={
                                            !!(
                                                touched.newPasswordRepeat &&
                                                errors.newPasswordRepeat
                                            )
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.newPasswordRepeat}
                                        variant="outlined"
                                        helperText={
                                            touched.newPasswordRepeat && errors.newPasswordRepeat
                                        }
                                        className={classes.textField}
                                    />
                                </div>
                                <Button
                                    className={classNames(classes.passwordButton)}
                                    type="submit"
                                >
                                    Jelszó módosítása
                                </Button>
                            </form>
                        )}
                    </Formik>
                </div>
            </SecondaryScreen>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.user;
    return {
        user,
    };
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(UserProfile)));
