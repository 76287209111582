import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent/SnackbarContent';
import InfoTooltip from '../../common/InfoTooltip';
import { GoogleMapContext } from '@googlemap-react/core';

const useStyles = makeStyles((theme) => ({
    layout: {
        position: 'absolute',
        top: '4px',
        left: '5px',
        right: '5px',
        [theme.breakpoints.up('sm')]: {
            top: '10px',
            left: '50%',
            right: 'auto',
            transform: 'translateX(-50%)',
        },
        '&.MuiSnackbarContent-root': {
            backgroundColor: '#4CAF50',
        },
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    tooltip: {
        backgroundColor: '#333333',
        color: 'rgba(255, 255, 255, 1)',
        fontSize: '18px',
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
}));

export default function DrawingMessage() {
    const classes = useStyles();
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const policyPlotToDraw = useSelector((state) => state.mepar.policyPlotToDraw);
    const editedPolygon = useSelector((state) => state.mepar.editedPolygon);
    const [drawnArea, setDrawnArea] = useState(null);
    const {
        state: { map },
    } = useContext(GoogleMapContext);

    useEffect(() => {
        if (!map || !editedPolygon) {
            return;
        }

        function calculateDrawnArea(editedPolygon) {
            const drawnArea =
                Math.round(
                    // eslint-disable-next-line no-undef
                    (google.maps.geometry.spherical.computeArea(editedPolygon.getPath()) / 10000) *
                        100
                ) / 100;
            setDrawnArea(drawnArea);
        }

        // eslint-disable-next-line no-undef
        google.maps.event.clearListeners('insert_at');
        // eslint-disable-next-line no-undef
        google.maps.event.clearListeners('remove_at');
        // eslint-disable-next-line no-undef
        google.maps.event.clearListeners('set_at');

        editedPolygon.getPaths().forEach(function (path, index) {
            // eslint-disable-next-line no-undef
            google.maps.event.addListener(path, 'insert_at', function () {
                calculateDrawnArea(editedPolygon);
            });

            // eslint-disable-next-line no-undef
            google.maps.event.addListener(path, 'remove_at', function () {
                calculateDrawnArea(editedPolygon);
            });

            // eslint-disable-next-line no-undef
            google.maps.event.addListener(path, 'set_at', function () {
                calculateDrawnArea(editedPolygon);
            });
        });

        calculateDrawnArea(editedPolygon);
    }, [map, editedPolygon]);

    if (!selectedMepar) {
        return null;
    }

    if (!policyPlotToDraw) {
        return null;
    }

    return (
        <SnackbarContent
            message={
                <div className={classes.message}>
                    {policyPlotToDraw.plantName} - {policyPlotToDraw.insuredArea} ha
                    {drawnArea && (
                        <>
                            <br />
                            rajzolt: {drawnArea} ha
                        </>
                    )}
                </div>
            }
            action={
                <>
                    <InfoTooltip
                        title={`A táblahatár megrajzolásához kérjük kattintson az egérrel a tábla egyik sarkára, majd körben haladva a tábla szélének jellegzetes pontjaira. A rajzolást a kiinduló pontra kattintva fejezheti be, és a mentés előtt a kis köröket mozgatva tudja módosítani.`}
                    />
                </>
            }
            className={classes.layout}
        />
    );
}
