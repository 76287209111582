import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField/TextField';
import classNames from 'classnames';
import { userActions } from '../state/actions';
import SecondaryScreen from '../components/screens/SecondaryScreen';
import { screens } from '../styles/styles';
import { ROUTES, USER_STATUS } from 'shared';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
    layout: {
        minWidth: '400px',
        maxWidth: '400px',
        padding: '30px',
        marginTop: '30px',
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: '100%',
            maxWidth: '100%',
            flex: 1,
            padding: '30px',
            marginTop: '0px',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    error: {
        color: 'rgba(255,0,0,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
        paddingBottom: '15px',
    },
    text: {
        color: 'rgba(142,142,147,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
    },
    checkLabel: {
        color: 'rgba(142,142,147,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
    },
    textField: {
        marginTop: '11px',
        marginBottom: '30px',
    },
    loginButton: {
        marginTop: '30px',
        minHeight: '60px',
        maxHeight: '60px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: 'rgba(0,84,255,1)',
        '&:hover': {
            backgroundColor: '#004EEE',
        },
        boxShadow: '0 2px 4px 0 rgba(5,33,81,0.07)',
        color: theme.palette.text.primaryInverse,
        fontSize: '18px',
        textTransform: 'none',
    },
    link: {
        textDecoration: 'none',
        fontSize: '12px',
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer',
        },
    },
}));

export default function Login() {
    const classes = useStyles();
    const [error, setError] = useState(null);
    const user = useSelector((state) => state.user.user);
    const userStatus = useSelector((state) => state.user.status);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (userStatus === USER_STATUS.FETCHED && user) {
            let searchParams = new URLSearchParams(location.search);
            const url = searchParams.get('url');

            history.push(url || ROUTES.HOME());
        }
    }, [user, userStatus, history, location.search]);

    useEffect(() => {
        if (userStatus === USER_STATUS.AUTHENTICATION_FAILED) {
            setError('Érvénytelen felhasználónév vagy jelszó!');
        }
    }, [userStatus]);

    function validate(values) {
        const errors = {};

        const requiredFields = ['username', 'password'];

        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field] = 'Kötelező kitölteni';
            }
        });

        return errors;
    }

    async function onSubmit({ username, password }) {
        dispatch(userActions.loginUser(username, password));
    }

    /*
    async function handlePasswordResetClick() {
        // todo: implement this
    }
*/

    return (
        <SecondaryScreen title={'Bejelentkezés'} showClose={false}>
            <div className={classNames(classes.layout)}>
                {/*
                <Typography variant={'body1'}>
                    Tisztelt felhasználó!
                </Typography>
                <br/>
                <Typography variant={'body2'}>
                    Ha Ön a Generali "Riasztás" csomagra feliratokozott ügyfelünk, akkor az emailben vagy sms-ben kapott link segítségével tud belépni a térképi
                    felületre.
                </Typography>
                <br/>
                <Typography variant={'body2'}>
                    Belépési probléma esetén kérjük, hogy írjon nekünk a <MuiLink href="mailto:generali@karriasztas.hu"
                                                                                  className={classes.email}>generali@karriasztas.hu</MuiLink> email címre.
                </Typography>
                <br/>
*/}
                <Formik
                    initialValues={{ username: '', password: '' }}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {({ touched, errors, handleSubmit, handleChange, handleBlur, values }) => (
                        <form onSubmit={handleSubmit} className={classes.form}>
                            {error && <Typography className={classes.error}>{error}</Typography>}

                            <Typography className={classes.text}>Felhasználó</Typography>
                            <TextField
                                name="username"
                                autoFocus={true}
                                autoComplete={'username'}
                                error={!!(touched.username && errors.username)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.username}
                                variant="outlined"
                                helperText={touched.username && errors.username}
                                className={classes.textField}
                            />

                            <Typography className={classes.text}>Jelszó</Typography>
                            <TextField
                                name="password"
                                type="password"
                                autoComplete="password"
                                error={!!(touched.password && errors.password)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                variant="outlined"
                                helperText={touched.password && errors.password}
                                className={classes.textField}
                            />
                            {/*
                            <Link to={ROUTES.PASSWORD_RESET} onClick={handlePasswordResetClick} className={classes.link}>
                                Elfelejtette a jelszavát vagy először jár itt?
                            </Link>
*/}

                            <Button className={classNames(classes.loginButton)} type="submit">
                                Bejelentkezés
                            </Button>
                        </form>
                    )}
                </Formik>
            </div>
        </SecondaryScreen>
    );
}
