export const policyService = {
    getPolicies,
    getPolicy,
    getPolicyPlot,
    getPolicyYields,
    savePolicyPlotOutline,
};

async function getPolicies() {
    try {
        const token = localStorage.getItem('jwt');

        let isAdmin = false;
        try {
            isAdmin = localStorage.getItem('envimap-admin') === 'true';
        } catch (error) {}

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-admin': `${isAdmin}`,
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await fetch(`/api/policies/`, options);

        if (!response.ok) {
            console.log(response);
            return null;
        }

        return response.json();
    } catch (error) {
        console.log(error);
        return null;
    }
}

async function getPolicy(id) {
    try {
        const token = localStorage.getItem('jwt');

        let isAdmin = false;
        try {
            isAdmin = localStorage.getItem('envimap-admin') === 'true';
        } catch (error) {}

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-admin': `${isAdmin}`,
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await fetch(`/api/policies/${encodeURIComponent(id)}`, options);

        if (!response.ok) {
            console.log(response);
            return null;
        }

        return response.json();
    } catch (error) {
        console.log(error);
        return null;
    }
}

async function getPolicyPlot(id) {
    try {
        const token = localStorage.getItem('jwt');

        let isAdmin = false;
        try {
            isAdmin = localStorage.getItem('envimap-admin') === 'true';
        } catch (error) {}

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-admin': `${isAdmin}`,
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await fetch(`/api/policyPlots/${encodeURIComponent(id)}`, options);

        if (!response.ok) {
            console.log(response);
            return null;
        }

        return response.json();
    } catch (error) {
        console.log(error);
        return null;
    }
}

async function getPolicyYields(policyNumber) {
    try {
        const token = localStorage.getItem('jwt');

        let isAdmin = false;
        try {
            isAdmin = localStorage.getItem('envimap-admin') === 'true';
        } catch (error) {}

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-admin': `${isAdmin}`,
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await fetch(`/api/policyYields/${encodeURIComponent(policyNumber)}`, options);

        if (!response.ok) {
            console.log(response);
            return null;
        }

        return response.json();
    } catch (error) {
        console.log(error);
        return null;
    }
}

async function savePolicyPlotOutline(policyPlotId, tablePolygon) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            policyPlotId,
            tablePolygon,
        }),
    };

    const response = await fetch(`/api/policyDrawings`, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}
