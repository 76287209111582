import { useContext, useEffect, useState } from 'react';
import { GoogleMapContext } from '@googlemap-react/core';
import { useSelector } from 'react-redux';
import epsg from 'epsg';
import { toWgs84 } from 'reproject';
import { farmerModule } from 'shared/src/modules/farmerModule';

export default function AlertLayer() {
    const {
        state: { map },
    } = useContext(GoogleMapContext);
    const [mapLayer, setMapLayer] = useState(null);
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const showAlertLayer = useSelector((state) => state.mepar.showAlertLayer);
    const productDate = useSelector((state) => state.mepar.productDate);

    useEffect(() => {
        if (!map) {
            return;
        }

        // eslint-disable-next-line no-undef
        const mapLayer = new google.maps.Data({});

        mapLayer.setStyle((feature) => {
            return {
                strokeColor: '#FF0000',
                strokeWeight: 1,
                fillOpacity: 0,
            };
        });

        mapLayer.addListener('click', function (event) {});

        mapLayer.setMap(map);

        setMapLayer(mapLayer);
    }, [map]);

    useEffect(() => {
        if (!map || !mapLayer || !selectedMepar || !productDate) {
            return;
        }

        mapLayer.forEach((feature) => {
            mapLayer.remove(feature);
        });

        if (!showAlertLayer) {
            return;
        }

        const alerts = farmerModule.getMeparAlertsForDate(selectedMepar, productDate);

        alerts.forEach((alert) => {
            if (alert.geom) {
                const geoJson = {
                    type: 'Feature',
                    geometry: toWgs84(alert.geom, 'EPSG:23700', epsg),
                    properties: {
                        alert,
                    },
                };

                mapLayer.addGeoJson(geoJson);
            }
        });
    }, [map, mapLayer, selectedMepar, productDate, showAlertLayer]);

    return null;
}
