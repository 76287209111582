import { LAYERS } from 'shared';
import { Tile as TileLayer } from 'ol/layer';
import XYZSource from 'ol/source/XYZ';

export default class FrostSurfaceLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new TileLayer({
            title: 'Mélyfekvésű területek',
            type: LAYERS.TYPES.FROST_SURFACE,
            visible: false,
            opacity: 0.65,
            displayInLayerSwitcher: true,
            source: new XYZSource({
                url: `https://generali.envimap.hu/content/tiles/deepMap/{z}/{x}/{y}.png`,
            }),
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        this.layer.setVisible(layer.visible);
    }
}