import { Divider, List, ListItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { ROUTES } from 'shared/src/constants/routes';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    label: {
        minWidth: '200px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    blockButtonHighlighted: {
        backgroundColor: 'rgba(170,170,170,1)',
    },
    buttonLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonInfoRowLayout: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    buttonTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    groupTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    buttonLabel: {
        fontSize: '12px',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: '12px',
    },
    divider: {
        marginTop: '10px',
    },
    groupTitleRow: {
        paddingBottom: '0px',
    },
});

class WoodwiserForestUnitPanel extends React.Component {
    onBlockButton = (damage, damagePlot) => () => {
        this.props.history.push(
            `${ROUTES.DAMAGE(damage.damageNumber)}?damagePlotId=${damagePlot.id}`
        );
    };

    render() {
        const { classes, woodwiserForestUnit } = this.props;
        moment.locale('hu');

        if (!woodwiserForestUnit) {
            return null;
        }

        const areaSum = Math.round(woodwiserForestUnit.area * 100) / 100;
        const heightAvg = Math.round(woodwiserForestUnit.heightAvg * 100) / 100;
        const dbhAvg = Math.round(woodwiserForestUnit.dbhAvg * 100) / 100;
        const volumeSum = Math.round(woodwiserForestUnit.volumeSum * 100) / 100;

        return (
            <List>
                <ListItem>
                    <div className={classes.layout}>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Település:</Typography>
                            <Typography className={classes.value}>
                                {woodwiserForestUnit.settlement}
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Egység:</Typography>
                            <Typography className={classes.value}>
                                {woodwiserForestUnit.tag}
                                {woodwiserForestUnit.subTag ? `/${woodwiserForestUnit.subTag}` : ''}
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Projekt:</Typography>
                            <Typography className={classes.value}>
                                <Link
                                    className={classes.value}
                                    to={ROUTES.WOODWISER_PROJECT(woodwiserForestUnit.project.id)}
                                >
                                    {woodwiserForestUnit.project.name}
                                </Link>
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Terület:</Typography>
                            <Typography className={classes.value}>{areaSum} ha</Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Törzsszám:</Typography>
                            <Typography className={classes.value}>
                                {woodwiserForestUnit.stemCount} db
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Átlag famagasság:</Typography>
                            <Typography className={classes.value}>{heightAvg} m</Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>
                                Átlag mellmagassági átmérő:
                            </Typography>
                            <Typography className={classes.value}>{dbhAvg} cm</Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>
                                Számított összes fatérfogat:
                            </Typography>
                            <Typography className={classes.value}>{volumeSum} m3</Typography>
                        </div>
                    </div>
                    <Divider />
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Typography className={classes.label}>Kitettség jelmagyarázat:</Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <img src={'/artifacts/woodwiser/aspect_legend.png'} width={'290px'} alt={''} />
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Typography className={classes.label}>
                        Famagasság, domborzat és lejtőszög jelmagyarázat:
                    </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <img
                        src={'/artifacts/woodwiser/CHM_DTM_slope_legend.png'}
                        width={'290px'}
                        alt={''}
                    />
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Typography className={classes.label}>Fafajta jelmagyarázat:</Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <img
                        src={'/artifacts/woodwiser/tree_species_legend.png'}
                        width={'180px'}
                        alt={''}
                    />
                </ListItem>
            </List>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.user;
    const { woodwiserForestUnit } = state.woodwiserForestUnit;
    return {
        user,
        woodwiserForestUnit,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(WoodwiserForestUnitPanel)));
