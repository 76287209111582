import { blockConstants } from '../constants';

const initialState = {
    block: null,
    manageDrawings: false,
};

export function block(state = initialState, action) {
    switch (action.type) {
        case blockConstants.SET_BLOCK:
            return {
                block: action.payload.block,
                productType: action.payload.productType,
                manageDrawings: action.payload.manageDrawings,
            };
        case blockConstants.SET_PRODUCT:
            return {
                ...state,
                product: action.payload.product,
                manageDrawings: false,
            };
        default:
            return state;
    }
}
