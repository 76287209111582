import React from 'react';
import {
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@material-ui/core';
import { calcColor, hexToRGB } from '../../helpers/colors';

/**
 * Creates a React element of a legend for yields
 *
 * @param {Object} props - React element properties
 * @param {number} props.max - Maximum value of the dataset
 * @param {string} props.table - Name of dataset
 * @param {string} props.type - Type of yield - reference/real
 * @param {Object} [props.yieldData] - Data of selected feature. Only used if there's a selection
 * @returns {JSX.element} - React element
 */
export default function YieldsLegend({ max, table, type, yieldData }) {
    const rounded = Math.round(max);

    // If max number is too high, we shouldn't show all element of the scale
    const divider = rounded > 15 ? (rounded > 31 ? (rounded > 64 ? 4 : 3) : 2) : 1;

    // Define array for items of the scale
    const scale = new Array(Math.round(rounded / divider) + 1);

    // Fill up the scale with color values
    for (let i = 0; i < scale.length; i++) {
        const color = calcColor(i * divider, rounded);
        scale[i] = `rgba(${hexToRGB(color).string}, 0.8)`;
    }

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <Typography variant="body2">
                    Átlagos {type.toLowerCase()} hozam/{table}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">Jelmagyarázat</Typography>
            </Grid>
            <Grid item container>
                {scale.map((color, c) => (
                    <Grid key={c} item xs style={{ backgroundColor: color, height: '20px' }}></Grid>
                ))}
            </Grid>
            <Grid item container style={{ marginBottom: '20px' }}>
                {scale.map((color, c) => (
                    <Grid key={c} item xs style={{ fontSize: '10px', textAlign: 'center' }}>
                        {c * divider}
                    </Grid>
                ))}
            </Grid>
            {yieldData && (
                <Grid item container>
                    <Typography>{table.charAt(0).toUpperCase() + table.substring(1)}</Typography>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Kód</TableCell>
                                    <TableCell>
                                        {yieldData[table === 'MePAR' ? 'meparCode' : 'nuts4Code']}
                                    </TableCell>
                                </TableRow>
                                {yieldData.nuts4Name && (
                                    <TableRow>
                                        <TableCell>Név</TableCell>
                                        <TableCell>{yieldData.nuts4Name} Kistérség</TableCell>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <TableCell>Átlagos {type.toLowerCase()} hozam</TableCell>
                                    <TableCell>{yieldData.yield} t/ha</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Grid>
    );
}
