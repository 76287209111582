import { List, ListItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { LAYERS } from 'shared/src/constants/layers';
import { mapActions } from '../../state/actions';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button/Button';
import { radarActions } from '../../state/actions/radarActions';
import Divider from '@material-ui/core/Divider';
import { layerService } from '../../services/layerService';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';

const styles = (theme) => ({
    panelLayout: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    description: {
        fontSize: '14px',
        paddingBottom: '30px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    productRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    productListWrapper: {
        flex: 1,
        overflow: 'auto',
    },
    productList: {},
    damagePlotRadarBlock: {
        marginBottom: '10px',
    },
    progress: {
        marginTop: '20px',
    },
});

class RadarPanel extends React.Component {
    state = {
        loading: false,
    };

    onShowRadarDate = (selectedRadarDate) => async () => {
        this.setState({
            loading: true,
        });
        const { radars: radarsToDisplay, mepars } = await layerService.getRadars(selectedRadarDate);
        this.props.dispatch(
            radarActions.setSelectedRadarDate(selectedRadarDate, radarsToDisplay, mepars)
        );
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.RADAR,
                visible: true,
                radars: radarsToDisplay,
                fittingLayer: false,
            })
        );
        if (!this.props.damagePlot) {
            if (mepars && mepars.length > 0) {
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.PLOT,
                        visible: true,
                        plots: mepars,
                        fittingLayer: false,
                    })
                );
            } else {
                this.props.dispatch(
                    mapActions.updateLayer({ type: LAYERS.TYPES.PLOT, visible: false })
                );
            }
        }
        this.setState({
            loading: false,
        });
    };

    render() {
        const { classes, radars, damagePlotRadars, selectedRadarDate, mepars } = this.props;
        const { loading } = this.state;

        moment.locale('hu');

        const radarDates = !radars
            ? []
            : radars.reduce((accumulator, radar) => {
                  const radarDate = moment(radar.dateRadar);
                  if (
                      accumulator.some((distinctRadarDate) => distinctRadarDate.isSame(radarDate))
                  ) {
                      return accumulator;
                  }
                  return [...accumulator, radarDate];
              }, []);

        const damagePlotRadarDates = !damagePlotRadars
            ? []
            : damagePlotRadars.reduce((accumulator, radar) => {
                  const radarDate = moment(radar.dateRadar);
                  if (
                      accumulator.some((distinctRadarDate) => distinctRadarDate.isSame(radarDate))
                  ) {
                      return accumulator;
                  }
                  return [...accumulator, radarDate];
              }, []);

        return (
            <div className={classes.panelLayout}>
                <List>
                    <ListItem>
                        <div className={classes.layout}>
                            {loading && (
                                <LinearProgress className={classes.progress} variant="query" />
                            )}
                            {!loading && (
                                <div className={classes.row}>
                                    <Typography className={classes.label}>
                                        Érintett blokkok:
                                    </Typography>
                                    <Typography className={classes.value}>
                                        {`${mepars.length} db`}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </ListItem>
                </List>
                <div className={classes.productListWrapper}>
                    <List className={classes.productList}>
                        {damagePlotRadarDates && damagePlotRadarDates.length > 0 && (
                            <div className={classes.damagePlotRadarBlock}>
                                <Divider />
                                <ListItem>
                                    <Typography className={classes.groupTitle}>
                                        A blokkot érintő időpontok:
                                    </Typography>
                                </ListItem>
                                {damagePlotRadarDates.map((radarDate, index) => (
                                    <ListItem key={index}>
                                        <div className={classes.productRow}>
                                            <Button
                                                className={classes.button}
                                                variant="contained"
                                                color={
                                                    radarDate.isSame(selectedRadarDate)
                                                        ? 'primary'
                                                        : 'default'
                                                }
                                                size="small"
                                                onClick={this.onShowRadarDate(radarDate)}
                                            >
                                                {radarDate.format('YYYY. MM. DD.')}
                                            </Button>
                                        </div>
                                    </ListItem>
                                ))}
                            </div>
                        )}
                        <Divider />
                        <ListItem>
                            <Typography className={classes.groupTitle}>
                                Összes kritikus időjárási jelenség:
                            </Typography>
                        </ListItem>
                        {radarDates.map((radarDate, index) => (
                            <ListItem key={index}>
                                <div className={classes.productRow}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color={
                                            radarDate.isSame(selectedRadarDate)
                                                ? 'primary'
                                                : 'default'
                                        }
                                        size="small"
                                        onClick={this.onShowRadarDate(radarDate)}
                                    >
                                        {radarDate.format('YYYY. MM. DD.')}
                                    </Button>
                                    {/*
                                    {user.role !== ROLE.EXPERT_UW && user.role !== ROLE.EXPERT_DAMAGE &&
                                    <form method="get"
                                          action={`/api/downloads/radar/${radarDate.format('YYYY-MM-DD')}`}
                                          target="_blank">
                                        <IconButton variant="contained" color="default" size="small" type="submit">
                                            <SaveAlt/>
                                        </IconButton>
                                    </form>
                                    }
*/}
                                </div>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.user;
    const { damage, damagePlot, productType, product } = state.damage;
    const { radars, damagePlotRadars, selectedRadarDate, radarsToDisplay, mepars } = state.radar;
    return {
        user,
        damage,
        damagePlot,
        productType,
        product,
        radars,
        damagePlotRadars,
        selectedRadarDate,
        radarsToDisplay,
        mepars,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(RadarPanel)));
