import { List, ListItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { ekActions, mapActions } from '../../state/actions';
import Typography from '@material-ui/core/Typography/Typography';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button/Button';
import { LAYERS } from 'shared/src/constants/layers';
import { PRODUCTS } from 'shared';
import { productService } from '../../services';

const styles = (theme) => ({
    expansion: {
        width: '100%',
    },
    list: {
        paddingTop: '0px',
    },
    listItem: {
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    panelLayout: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    description: {
        fontSize: '14px',
        paddingBottom: '10px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    drawingButton: {
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
    },
    productRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    productListWrapper: {
        flex: 1,
        overflow: 'auto',
    },
    productList: {},
    dividerTop: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    dividerBottom: {
        marginTop: '10px',
    },
});

class EKProductPanel extends React.Component {
    componentDidMount() {
        return this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.match.params.ekCode !== prevProps.match.params.ekCode ||
            this.props.location.search !== prevProps.location.search
        ) {
            return this.getData();
        }
    }

    async getData() {
        const {
            match: { params },
            location,
        } = this.props;

        let searchParams = new URLSearchParams(location.search);
        this.meparCode = searchParams.get('meparCode');
        this.productType = searchParams.get('productType');
        this.ekCode = params.ekCode;

        this.products = await productService.getProducts(this.meparCode);
        const product = this.products.filter((product) => product.type === this.productType)[0];
        this.props.dispatch(ekActions.setProduct(product));
        this.props.dispatch(
            mapActions.updateLayer({ type: LAYERS.TYPES.EK_PRODUCT, visible: true, product })
        );
    }

    getLabelFromSubType(subType) {
        if (subType === 'BMI') {
            return 'Biomassza intenzitás';
        }

        if (subType === 'TCI') {
            return 'Látható felvétel';
        }

        return subType;
    }

    onShowProduct = (product) => () => {
        this.props.dispatch(ekActions.setProduct(product));
        this.props.dispatch(
            mapActions.updateLayer({ type: LAYERS.TYPES.EK_PRODUCT, visible: true, product })
        );
    };

    render() {
        const { classes, product } = this.props;
        moment.locale('hu');

        const productTypeMap = this.products
            ? this.products.reduce((accumulator, product) => {
                  const productsInType = accumulator[product.type] || [];
                  productsInType.push(product);
                  return Object.assign(accumulator, {
                      [product.type]: productsInType,
                  });
              }, {})
            : {};

        const productType = this.productType;

        if (!productType && !product) {
            return null;
        }

        return (
            <div className={classes.panelLayout}>
                <List className={classes.list}>
                    <ListItem className={classes.listItem}>
                        <img src={PRODUCTS.DETAILS[productType].legend} width={'290px'} alt={''} />
                    </ListItem>
                </List>
                <div className={classes.productListWrapper}>
                    <List className={classes.productList}>
                        {product && productTypeMap[product.type].length === 1 && (
                            <>
                                {product.dateBefore && (
                                    <ListItem>
                                        <div className={classes.layout}>
                                            <div className={classes.row}>
                                                <Typography className={classes.label}>
                                                    Előtte felvétel:
                                                </Typography>
                                                <Typography className={classes.value}>
                                                    {`${moment(product.dateBefore).format(
                                                        'YYYY. MM. DD.'
                                                    )}`}
                                                </Typography>
                                            </div>
                                            <div className={classes.row}>
                                                <Typography className={classes.label}>
                                                    Utána felvétel:
                                                </Typography>
                                                <Typography className={classes.value}>
                                                    {`${moment(product.date).format(
                                                        'YYYY. MM. DD.'
                                                    )}`}
                                                </Typography>
                                            </div>
                                        </div>
                                    </ListItem>
                                )}
                            </>
                        )}
                        {product &&
                            productTypeMap[product.type].length > 1 &&
                            productTypeMap[product.type].map((productItem) => (
                                <ListItem key={productItem.id}>
                                    <div className={classes.productRow} key={productItem.id}>
                                        <Button
                                            className={classes.button}
                                            variant="contained"
                                            color={
                                                product.id === productItem.id
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                            size="small"
                                            onClick={this.onShowProduct(productItem)}
                                        >
                                            {productItem.date ? (
                                                productItem.subType ? (
                                                    <>
                                                        {this.getLabelFromSubType(
                                                            productItem.subType
                                                        )}
                                                        <br />
                                                        {moment(productItem.date).format(
                                                            'YYYY. MM. DD.'
                                                        )}
                                                    </>
                                                ) : (
                                                    moment(productItem.date).format('YYYY. MM. DD.')
                                                )
                                            ) : (
                                                'Megjelenít'
                                            )}
                                            {productItem.dateBefore && (
                                                <>
                                                    <br />
                                                    {`${moment(productItem.dateBefore).format(
                                                        'YYYY. MM. DD.'
                                                    )} - ${moment(productItem.date).format(
                                                        'YYYY. MM. DD.'
                                                    )}`}
                                                </>
                                            )}
                                        </Button>
                                    </div>
                                </ListItem>
                            ))}
                    </List>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { product } = state.ek;
    return {
        product,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(EKProductPanel)));
