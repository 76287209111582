import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { FARMER_VIEWS } from 'shared';
import { useHistory } from 'react-router';
import { teal } from '@material-ui/core/colors';
import { farmerModule } from 'shared/src/modules/farmerModule';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        '&.MuiButton-root': {
            minWidth: '0px',
        },
    },
    buttonNdvi: {
        borderColor: teal[500],
        color: teal[500],
        '&:hover': {
            borderColor: teal[700],
            color: teal[700],
        },
    },
    buttonAlert: {
        borderColor: 'rgba(194, 27, 23, 1)',
        color: 'rgba(194, 27, 23, 1)',
        '&:hover': {
            borderColor: 'rgb(152,26,22)',
            color: 'rgb(152,26,22)',
        },
    },
}));

export default function NavigationPreviousButton(params) {
    const classes = useStyles(params);
    const mepars = useSelector((state) => state.mepar.mepars);
    const alertMepars = useSelector((state) => state.mepar.alertMepars);
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const isTablet = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const isMobile = !isTablet && !isDesktop;
    const [isVisible, setIsVisible] = useState(true);
    const policyPlotToDraw = useSelector((state) => state.mepar.policyPlotToDraw);
    const history = useHistory();
    const viewState = useSelector((state) => state.view.farmerViewState);
    const productDate = useSelector((state) => state.mepar.productDate);
    const alertDate = useSelector((state) => state.mepar.alertDate);

    useEffect(() => {
        if (policyPlotToDraw) {
            setIsVisible(false);
            return;
        }

        setIsVisible(true);
    }, [policyPlotToDraw]);

    if (!mepars || !selectedMepar) {
        return null;
    }

    function getSelectedMeparIndex(mepars, selectedMepar) {
        return mepars.findIndex((mepar) => mepar.meparCode === selectedMepar.meparCode);
    }

    function isFirstMepar(mepars, selectedMepar) {
        return getSelectedMeparIndex(mepars, selectedMepar) === 0;
    }

    function handleClick() {
        const selectedMeparIndex = getSelectedMeparIndex(getMepars(), selectedMepar);
        const newSelectedMepar = getMepars()[selectedMeparIndex - 1];

        const url = farmerModule.composeUrl(newSelectedMepar, viewState, productDate, alertDate);

        history.push(url);
    }

    function getMepars() {
        return viewState === FARMER_VIEWS.ALERT ? alertMepars : mepars;
    }

    const disabled = isFirstMepar(getMepars(), selectedMepar);

    return (
        <>
            {isVisible && (
                <>
                    {!isMobile && (
                        <Button
                            variant="outlined"
                            className={clsx(
                                viewState === FARMER_VIEWS.NDVI
                                    ? classes.buttonNdvi
                                    : classes.buttonAlert
                            )}
                            onClick={handleClick}
                            disabled={disabled}
                            size={'small'}
                        >
                            <ChevronLeftIcon />
                            Előző
                        </Button>
                    )}
                    {isMobile && (
                        <Button
                            color={'default'}
                            size="small"
                            variant="outlined"
                            className={clsx(
                                classes.iconButton,
                                viewState === FARMER_VIEWS.NDVI
                                    ? classes.buttonNdvi
                                    : classes.buttonAlert
                            )}
                            disabled={disabled}
                            onClick={handleClick}
                        >
                            <ChevronLeftIcon />
                        </Button>
                    )}
                </>
            )}
        </>
    );
}
