import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ListItemText, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ROUTES } from 'shared/src/constants/routes';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function YieldMenu({ label, items, onItemClick }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function onMenuItemClick(url) {
        return () => {
            onItemClick();
            history.push(url);
        };
    }

    return (
        <div>
            <ListItemText
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleClick}
                primary={label}
            />
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {items.map((item, i) => (
                    <StyledMenuItem
                        key={i}
                        onClick={onMenuItemClick(ROUTES.YIELD(item.params[0], item.params[1]))}
                    >
                        {item.label}
                    </StyledMenuItem>
                ))}
            </StyledMenu>
        </div>
    );
}
