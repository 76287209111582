import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { meparActions } from '../state/actions/meparActions';
import { farmerModule } from 'shared/src/modules/farmerModule';
import { viewActions } from '../state/actions';
import { FARMER_VIEWS } from 'shared';
import { useLocation } from 'react-router';

export function initialiseViewState(
    dispatch,
    mepars,
    selectedMepar,
    inputViewState,
    inputProductDate
) {
    const viewState =
        inputViewState ||
        (selectedMepar
            ? farmerModule.getDefaultMeparViewState(selectedMepar)
            : farmerModule.getDefaultViewState(mepars));

    const defaultMepar =
        selectedMepar || farmerModule.getDefaultMeparForViewState(viewState, mepars);

    dispatch(meparActions.setSelectedMepar(defaultMepar));
    dispatch(viewActions.setFarmerViewState(viewState));

    if (inputProductDate) {
        dispatch(meparActions.setProductDate(inputProductDate));
        if (viewState === FARMER_VIEWS.ALERT) {
            dispatch(meparActions.setAlertDate(inputProductDate));
        }
    } else {
        const ndviDates = farmerModule.getMeparNdviDates(defaultMepar);
        const alertDates = farmerModule.getMeparAlertDates(defaultMepar);

        // we remove drawing requirement for now
        // const defaultMeparHasTableGeometries = farmerModule.meparHasSomeTableGeometries(defaultMepar);

        const ndviDate = ndviDates.length > 0 ? ndviDates[0] : null;
        const alertDate = alertDates.length > 0 ? alertDates[0] : null;

        dispatch(
            meparActions.setProductDate(
                viewState === FARMER_VIEWS.NDVI
                    ? ndviDate /* (defaultMeparHasTableGeometries ? ndviDate : alertDate) */
                    : alertDate
            )
        );
    }
}

export default function FarmerHomeController() {
    const dispatch = useDispatch();
    const mepars = useSelector((state) => state.mepar.mepars);
    const location = useLocation();

    useEffect(() => {
        if (mepars) {
            return;
        }

        dispatch(meparActions.requestFarmerMepars());
    }, [mepars, dispatch]);

    useEffect(() => {
        if (!mepars) {
            return;
        }

        let view = null;
        let date = null;

        const match = location.pathname.match('/t/([^/]+)/([^/]+)/([^/]+)$');
        if (match && match.length === 4) {
            view = match[2];
            date = match[3];
        }

        let searchParams = new URLSearchParams(location.search);
        view = view || searchParams.get('view');
        date = date || searchParams.get('date');

        const viewState = view && farmerModule.parseViewState(view);
        const productDate = date && farmerModule.parseProductDate(date);

        initialiseViewState(dispatch, mepars, null, viewState, productDate);
    }, [mepars, dispatch, location]);

    return null;
}
