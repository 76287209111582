import { mapConstants } from '../constants';

export const mapActions = {
    setInfoWindow,
    setDrawingManager,
    resetLayers,
    updateLayer,
};

function setInfoWindow(infoWindow) {
    return {
        type: mapConstants.SET_INFO_WINDOW,
        payload: {
            infoWindow,
        },
    };
}

function setDrawingManager(drawingManager) {
    return {
        type: mapConstants.SET_DRAWING_MANAGER,
        payload: {
            drawingManager,
        },
    };
}

function resetLayers() {
    return {
        type: mapConstants.RESET_LAYERS,
    };
}

function updateLayer(layer) {
    return {
        type: mapConstants.SHOW_LAYER,
        payload: {
            layer,
        },
    };
}
