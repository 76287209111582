import { List, ListItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { mapActions } from '../../state/actions';
import { damageActions } from '../../state/actions/damageActions';
import Typography from '@material-ui/core/Typography/Typography';
import IconButton from '@material-ui/core/IconButton/IconButton';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button/Button';
import { Link } from 'react-router-dom';
import { ROUTES } from 'shared/src/constants/routes';
import { LAYERS } from 'shared/src/constants/layers';
import { PRODUCTS } from 'shared';
import { logService } from '../../services/logService';
import { LOGS } from 'shared/src/constants/logs';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary';
import ExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel';

const styles = (theme) => ({
    expansion: {
        width: '100%',
    },
    list: {
        paddingTop: '0px',
    },
    listItem: {
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    panelLayout: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    description: {
        fontSize: '14px',
        paddingBottom: '10px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    drawingButton: {
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
    },
    productRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    productListWrapper: {
        flex: 1,
        overflow: 'auto',
    },
    productList: {},
    dividerTop: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    dividerBottom: {
        marginTop: '10px',
    },
});

class ProductPanel extends React.Component {
    getLabelFromSubType(subType) {
        if (subType === 'BMI') {
            return 'Biomassza intenzitás';
        }

        if (subType === 'TCI') {
            return 'Látható felvétel';
        }

        return subType;
    }

    onShowProduct = (product) => () => {
        this.props.dispatch(damageActions.setProduct(product));
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.PRODUCT,
                visible: true,
                product,
                damagePlot: this.props.damagePlot,
            })
        );
    };

    onProductDownload = (type, resource) => async () => {
        await logService.saveLog(type, resource);
    };

    onShowDrawings = (damagePlot) => () => {
        this.props.history.push(
            `${ROUTES.DAMAGE(damagePlot.damage.damageNumber)}?damagePlotId=${
                damagePlot.id
            }&manageDrawings=true`
        );
    };

    render() {
        const { classes, damage, damagePlot, productType, product } = this.props;
        moment.locale('hu');

        if (!damagePlot || !productType) {
            return null;
        }

        const productTypeMap = damagePlot.products
            ? damagePlot.products.reduce((accumulator, product) => {
                  const productsInType = accumulator[product.type] || [];
                  productsInType.push(product);
                  return Object.assign(accumulator, {
                      [product.type]: productsInType,
                  });
              }, {})
            : {};

        return (
            <div className={classes.panelLayout}>
                <List className={classes.list}>
                    <ListItem>
                        <ExpansionPanel className={classes.expansion}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.label}>Tábla részletek</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className={classes.layout}>
                                    <div className={classes.row}>
                                        <Typography className={classes.label}>
                                            MePAR kód:
                                        </Typography>
                                        <Typography className={classes.value}>
                                            <Link
                                                className={classes.value}
                                                to={ROUTES.BLOCK(damagePlot.meparCode)}
                                            >
                                                {damagePlot.meparCode}
                                            </Link>
                                        </Typography>
                                    </div>
                                    <div className={classes.row}>
                                        <Typography className={classes.label}>Kárszám:</Typography>
                                        <Typography className={classes.value}>
                                            <Link
                                                className={classes.value}
                                                to={ROUTES.DAMAGE(damage.damageNumber)}
                                            >
                                                {damage.damageNumber}
                                            </Link>
                                        </Typography>
                                    </div>
                                    <div className={classes.row}>
                                        <Typography className={classes.label}>
                                            Kár dátuma:
                                        </Typography>
                                        <Typography className={classes.value}>
                                            {moment(damage.damageDate).format('YYYY. MM. DD.')}
                                        </Typography>
                                    </div>
                                    <div className={classes.row}>
                                        <Typography className={classes.label}>Kártípus:</Typography>
                                        <Typography className={classes.value}>
                                            {damage.dangerType}
                                        </Typography>
                                    </div>
                                    <div className={classes.row}>
                                        <Typography className={classes.label}>Növény:</Typography>
                                        <Typography className={classes.value}>
                                            {damagePlot.plantCode}
                                        </Typography>
                                    </div>
                                    <div className={classes.row}>
                                        <Typography className={classes.label}>
                                            Kár. terület:
                                        </Typography>
                                        <Typography className={classes.value}>
                                            {`${damagePlot.damagedArea} ha`}
                                        </Typography>
                                    </div>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        className={classes.drawingButton}
                                        onClick={this.onShowDrawings(damagePlot)}
                                    >
                                        {`Táblahatár / Mérés`}
                                    </Button>
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <img src={PRODUCTS.DETAILS[productType].legend} width={'290px'} alt={''} />
                    </ListItem>
                </List>
                <div className={classes.productListWrapper}>
                    <List className={classes.productList}>
                        {product && productTypeMap[product.type].length === 1 && (
                            <>
                                {product.dateBefore && (
                                    <ListItem>
                                        <div className={classes.layout}>
                                            <div className={classes.row}>
                                                <Typography className={classes.label}>
                                                    Előtte felvétel:
                                                </Typography>
                                                <Typography className={classes.value}>
                                                    {`${moment(product.dateBefore).format(
                                                        'YYYY. MM. DD.'
                                                    )}`}
                                                </Typography>
                                            </div>
                                            <div className={classes.row}>
                                                <Typography className={classes.label}>
                                                    Utána felvétel:
                                                </Typography>
                                                <Typography className={classes.value}>
                                                    {`${moment(product.date).format(
                                                        'YYYY. MM. DD.'
                                                    )}`}
                                                </Typography>
                                            </div>
                                        </div>
                                    </ListItem>
                                )}
                                <ListItem>
                                    <form
                                        method="get"
                                        action={`/pdfs/${product.fileName}`}
                                        target="_blank"
                                    >
                                        <Button
                                            variant="contained"
                                            color="default"
                                            size="small"
                                            type="submit"
                                        >
                                            {`Letöltés`}
                                            <SaveAlt />
                                        </Button>
                                    </form>
                                </ListItem>
                            </>
                        )}
                        {product &&
                            productTypeMap[product.type].length > 1 &&
                            productTypeMap[product.type].map((productItem) => (
                                <ListItem key={productItem.id}>
                                    <div className={classes.productRow} key={productItem.id}>
                                        <Button
                                            className={classes.button}
                                            variant="contained"
                                            color={
                                                product.id === productItem.id
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                            size="small"
                                            onClick={this.onShowProduct(productItem)}
                                        >
                                            {productItem.date ? (
                                                productItem.subType ? (
                                                    <>
                                                        {this.getLabelFromSubType(
                                                            productItem.subType
                                                        )}
                                                        <br />
                                                        {moment(productItem.date).format(
                                                            'YYYY. MM. DD.'
                                                        )}
                                                    </>
                                                ) : (
                                                    moment(productItem.date).format('YYYY. MM. DD.')
                                                )
                                            ) : (
                                                'Megjelenít'
                                            )}
                                            {productItem.dateBefore && (
                                                <>
                                                    <br />
                                                    {`${moment(productItem.dateBefore).format(
                                                        'YYYY. MM. DD.'
                                                    )} - ${moment(productItem.date).format(
                                                        'YYYY. MM. DD.'
                                                    )}`}
                                                </>
                                            )}
                                        </Button>
                                        <form
                                            method="get"
                                            action={`/pdfs/${productItem.fileName}`}
                                            target="_blank"
                                        >
                                            <IconButton
                                                variant="contained"
                                                color="default"
                                                size="small"
                                                type="submit"
                                                onClick={this.onProductDownload(
                                                    LOGS.TYPES.GET_PRODUCT_PDF,
                                                    productItem.fileName
                                                )}
                                            >
                                                <SaveAlt />
                                            </IconButton>
                                        </form>
                                    </div>
                                </ListItem>
                            ))}
                    </List>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { damage, damagePlot, productType, product } = state.damage;
    return {
        damage,
        damagePlot,
        productType,
        product,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(ProductPanel)));
