import React, { useEffect, useState } from 'react';
import MainScreen from '../components/screens/MainScreen';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { DASHBOARD } from 'shared';
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { dashboardService } from '../services/dashboardService';
import Paper from '@material-ui/core/Paper';
import numeral from 'numeral';

const useStyles = makeStyles((theme) => ({
    align: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px',
    },
    map: {
        height: 'calc(100% - 140px)',
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            height: 'calc(100% - 156px)',
        },
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100% - 156px)',
        },
    },
    menuBar: {
        '& > *': {
            margin: '10px',
        },
    },
    subMenuBar: {
        '& > *': {
            margin: '10px',
        },
    },
    title: {
        fontSize: '36px',
        marginBottom: '20px',
    },
    rowTitle: {
        fontSize: '18px',
        marginTop: '40px',
        marginBottom: '20px',
        textAlign: 'center',
    },
    loading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    tableBlocks: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        '& > *': {
            margin: '0px 30px',
        },
    },
    tableBlock: {
        display: 'flex',
        flexDirection: 'column',
    },
    tableWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > .MuiTableContainer-root': {
            width: 'initial',
        },
    },
}));

export default function Dashboard() {
    const classes = useStyles();
    const [dashboardCategoryIndex, setDashboardCategoryIndex] = useState(0);
    const [dashboardSubcategoryIndex, setDashboardSubcategoryIndex] = useState(0);
    const [dashboardData, setDashboardData] = useState([]);

    const user = useSelector((state) => state.user.user);

    useEffect(() => {
        setDashboardData([]);
        let isCancelled = false;

        async function loadDashboard(dashboardCategoryIndex, dashboardSubcategoryIndex) {
            const result = await dashboardService.loadDashboard(
                dashboardCategoryIndex,
                dashboardSubcategoryIndex
            );
            if (!isCancelled) {
                setDashboardData(result);
            }
        }

        loadDashboard(dashboardCategoryIndex, dashboardSubcategoryIndex).catch((error) =>
            console.log(error)
        );

        return () => (isCancelled = true);
    }, [dashboardCategoryIndex, dashboardSubcategoryIndex]);

    if (!user) {
        return null;
    }

    const handleCategoryClick = (categoryIndex) => () => {
        setDashboardCategoryIndex(categoryIndex);
        setDashboardSubcategoryIndex(0);
    };

    const handleSubcategoryClick = (subCategoryIndex) => () => {
        setDashboardSubcategoryIndex(subCategoryIndex);
    };

    return (
        <MainScreen>
            <div className={classes.align}>
                <Typography className={classes.title}>Dashboard</Typography>
                <div className={classes.menuBar}>
                    {DASHBOARD.CATEGORIES.map((category, categoryIndex) => (
                        <Button
                            key={categoryIndex}
                            color="primary"
                            variant={
                                categoryIndex === dashboardCategoryIndex ? 'contained' : 'outlined'
                            }
                            onClick={handleCategoryClick(categoryIndex)}
                        >
                            {category.LABEL}
                        </Button>
                    ))}
                </div>
                <div className={classes.subMenuBar}>
                    {DASHBOARD.CATEGORIES[dashboardCategoryIndex].CATEGORIES.map(
                        (subcategory, subcategoryIndex) => (
                            <Button
                                key={subcategoryIndex}
                                variant={
                                    subcategoryIndex === dashboardSubcategoryIndex
                                        ? 'contained'
                                        : 'outlined'
                                }
                                onClick={handleSubcategoryClick(subcategoryIndex)}
                            >
                                {subcategory.LABEL}
                            </Button>
                        )
                    )}
                </div>
                <div className={classes.tableBlocks}>
                    {dashboardData.length !== 0 &&
                        DASHBOARD.CATEGORIES[dashboardCategoryIndex].CATEGORIES[
                            dashboardSubcategoryIndex
                        ].CATEGORIES.map((categoryItem, categoryItemIndex) => (
                            <div key={categoryItemIndex} className={classes.tableBlock}>
                                <Typography className={classes.rowTitle}>
                                    {categoryItem.LABEL}
                                </Typography>
                                <div className={classes.tableWrapper}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                {/*
                                        <TableRow>
                                            <TableCell>Dessert (100g serving)</TableCell>
                                            <TableCell align="right">Calories</TableCell>
                                            <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                            <TableCell align="right">Protein&nbsp;(g)</TableCell>
                                        </TableRow>
    */}
                                            </TableHead>
                                            <TableBody>
                                                {dashboardData[categoryItemIndex] &&
                                                    dashboardData[categoryItemIndex].map(
                                                        (row, index) => (
                                                            <TableRow
                                                                key={index}
                                                                sx={{
                                                                    '&:last-child td, &:last-child th':
                                                                        { border: 0 },
                                                                }}
                                                            >
                                                                {Object.keys(row).map(
                                                                    (key, index) => (
                                                                        <TableCell
                                                                            key={index}
                                                                            align="right"
                                                                        >
                                                                            {isNaN(row[key])
                                                                                ? row[key]
                                                                                : numeral(row[key])
                                                                                      .format('0,0')
                                                                                      .replace(
                                                                                          /,/g,
                                                                                          ' '
                                                                                      )}
                                                                        </TableCell>
                                                                    )
                                                                )}
                                                            </TableRow>
                                                        )
                                                    )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        ))}
                </div>
                {dashboardData.length === 0 && (
                    <div className={classes.loading}>
                        <CircularProgress />
                    </div>
                )}
            </div>
        </MainScreen>
    );
}
