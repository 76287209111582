import React, { useEffect } from 'react';
import { useParams, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { LAYERS } from 'shared/src/constants/layers';
import { VIEWS } from 'shared/src/constants/views';
import { mapActions, viewActions } from '../state/actions';

const YieldsController = (props) => {
    const { type, plant } = useParams();

    useEffect(() => {
        props.dispatch(viewActions.setInfoPanelState({ open: true }));
        props.dispatch(viewActions.setViewState({ view: VIEWS.PROGRESS }));

        props.dispatch(mapActions.resetLayers());

        props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.YIELD,
                visible: true,
                plant: plant,
                yieldType: type,
            })
        );

        props.dispatch(viewActions.setViewState({ view: VIEWS.YIELD }));
    }, [type, plant]);

    return null;
};

export default connect()(withRouter(YieldsController));
