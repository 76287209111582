import { drawingConstants } from '../constants';

const initialState = {
    drawing: null,
};

export function drawing(state = initialState, action) {
    switch (action.type) {
        case drawingConstants.SET_DRAWING:
            return {
                drawing: action.payload.drawing,
            };
        default:
            return state;
    }
}
