import { LAYERS } from 'shared';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { Fill, Stroke, Style } from 'ol/style';
import { asArray } from 'ol/color';
import GeoJSON from 'ol/format/GeoJSON';
import { bbox } from 'ol/loadingstrategy';
import { prokatSoilZoneService } from '../../services/prokatSoilZoneService';
import Select from 'ol/interaction/Select';
import { pointerMove } from 'ol/events/condition';
import PopupFeature from 'ol-ext/overlay/PopupFeature';

export default class ProkatSoilZoneGeneticLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        const vectorSource = new VectorSource({
            format: new GeoJSON(),
            loader: async (extent) => {
                const extentPolygon = fromExtent(extent);
                const soilZones = await prokatSoilZoneService.getSoilZones(extentPolygon);
                soilZones.forEach((soilZone) => {
                    const soilZonePolygon = soilZone.geom;
                    const feature = new Feature({
                        geometry:
                            soilZonePolygon.type === 'Polygon'
                                ? new Polygon(soilZonePolygon.coordinates)
                                : new MultiPolygon(soilZonePolygon.coordinates),
                        soilZone,
                    });
                    vectorSource.addFeature(feature);
                });
            },
            strategy: bbox,
        });

        this.layer = new VectorLayer({
            title: `Talajfoltok`,
            type: LAYERS.TYPES.PROKAT_SOIL_ZONE_GENETIC,
            visible: false,
            renderMode: 'vector',
            source: vectorSource,
            maxResolution: 25,
            style: (feature) => {
                const soilZone = feature.get('soilZone');
                const color = asArray(soilZone.colorGenetic);
                const rgbColor = color.slice();
                rgbColor[3] = 1;
                console.log(rgbColor);
                return new Style({
                    stroke: new Stroke({
                        color: 'white',
                        width: 1,
                    }),
                    fill: new Fill({
                        color: rgbColor,
                    }),
                });
            },
        });
        this.initInteractions();
        this.addInteractions();
    }

    initInteractions() {
        this.pointerSelect = new Select({
            condition: pointerMove,
            layers: [this.layer],
        });

        const popup = new PopupFeature({
            select: this.pointerSelect,
            canFix: true,
            template: {
                title: (feature) => {
                    return 'Talajtípus/kötöttség';
                },
                attributes: {
                    genetic: {
                        title: 'Talajtípus',
                        format: function (value, feature) {
                            const soilSample = feature.get('soilZone');
                            return soilSample.genetic;
                        },
                    },
                    physical: {
                        title: 'Kötöttség',
                        format: function (value, feature) {
                            const soilSample = feature.get('soilZone');
                            return soilSample.physical;
                        },
                    },
                },
            },
        });

        this.map.map.addOverlay(popup);
    }

    addInteractions() {
        this.removeInteractions();
        this.map.map.addInteraction(this.pointerSelect);
    }

    removeInteractions() {
        this.map.map.removeInteraction(this.pointerSelect);
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        this.layer.setVisible(layer.visible);
    }
}
