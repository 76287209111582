import { LAYERS } from 'shared';
import { Fill, Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';

export default class RadarLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new VectorLayer({
            title: 'Kritikus időjárási jelenség',
            type: LAYERS.TYPES.RADAR,
            visible: true,
            displayInLayerSwitcher: false,
            style: new Style({
                stroke: new Stroke({
                    color: 'red',
                    width: 2,
                }),
                fill: new Fill({
                    color: 'rgba(255, 0, 0, 0.1)',
                }),
            }),
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (layer.radars !== this.layer.get('radars') && layer.radars) {
            const radars = layer.radars;
            const radarSource = new VectorSource();

            radars.forEach((radar) => {
                const polygon = JSON.parse(radar.polygon);
                const feature = new Feature({
                    geometry: new Polygon(polygon.coordinates),
                    radar,
                });
                feature.setStyle(
                    new Style({
                        stroke: new Stroke({
                            color: 'red',
                            width: 2,
                        }),
                        fill: new Fill({
                            color: 'rgba(255, 0, 0, 0.4)',
                        }),
                    })
                );
                radarSource.addFeature(feature);
            });

            this.layer.setSource(radarSource);
            this.layer.set('radars', radars);
        }
        this.layer.setVisible(layer.visible);
    }
}
