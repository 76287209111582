import React from 'react';
import { HtmlTooltip } from './HtmlTooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoOutlined';

export default function InfoTooltip(props) {
    const { title } = props;

    return (
        <HtmlTooltip title={title}>
            <IconButton size="small" aria-label="close" color="inherit">
                <InfoIcon fontSize="small" />
            </IconButton>
        </HtmlTooltip>
    );
}
