export const dashboardService = {
    loadDashboard,
};

async function loadDashboard(dashboardCategoryIndex, dashboardSubcategoryIndex) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await fetch(
        `/api/dashboards/${encodeURIComponent(dashboardCategoryIndex)}/${encodeURIComponent(
            dashboardSubcategoryIndex
        )}`,
        options
    );

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}
