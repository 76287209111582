import { radarConstants } from '../constants';
import moment from 'moment';

export const radarActions = {
    setRadars,
    setSelectedRadarDate,
};

function setRadars(
    radars,
    damagePlotRadars = null,
    selectedRadarDate = null,
    radarsToDisplay = null,
    mepars = null
) {
    return {
        type: radarConstants.SET_RADARS,
        payload: {
            radars,
            damagePlotRadars,
            selectedRadarDate: selectedRadarDate || moment(radars[0].dateRadar),
            radarsToDisplay,
            mepars,
        },
    };
}

function setSelectedRadarDate(radarDate, radarsToDisplay = null, mepars = null) {
    return {
        type: radarConstants.SET_SELECTED_RADAR_DATE,
        payload: {
            selectedRadarDate: radarDate,
            radarsToDisplay,
            mepars,
        },
    };
}
