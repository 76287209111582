import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { FARMER_VIEWS } from 'shared';
import { viewActions } from '../../state/actions';
import { farmerModule } from 'shared/src/modules/farmerModule';
import { teal } from '@material-ui/core/colors';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    layout: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '42px',
        '& > *:not(:first-child)': {
            marginLeft: '10px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '46px',
        },
    },
    button: {
        // color: theme.palette.getContrastText(purple[500]),
    },
    buttonNdvi: {
        color: 'white',
        backgroundColor: teal[500],
        '&:hover': {
            backgroundColor: teal[700],
        },
    },
    buttonAlert: {
        color: 'white',
        backgroundColor: 'rgba(194, 27, 23, 1)',
        '&:hover': {
            backgroundColor: 'rgb(152,26,22)',
        },
    },
}));

export default function ViewModeBar() {
    const classes = useStyles();
    const viewState = useSelector((state) => state.view.farmerViewState);
    const alertMepars = useSelector((state) => state.mepar.alertMepars);
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const productDate = useSelector((state) => state.mepar.productDate);
    const alertDate = useSelector((state) => state.mepar.alertDate);
    const dispatch = useDispatch();
    const history = useHistory();
    const policyPlotToDraw = useSelector((state) => state.mepar.policyPlotToDraw);

    const enableAlerts = alertMepars && alertMepars.length > 0;

    function handleNdviClick() {
        if (viewState !== FARMER_VIEWS.NDVI) {
            dispatch(viewActions.setFarmerViewState(FARMER_VIEWS.NDVI));

            const url = farmerModule.composeUrl(
                selectedMepar,
                FARMER_VIEWS.NDVI,
                productDate,
                alertDate
            );

            history.push(url);
        }
    }

    function handleAlertClick() {
        if (viewState !== FARMER_VIEWS.ALERT) {
            let newSelectedMepar = selectedMepar;
            if (!alertMepars.find((alertMepar) => alertMepar.meparCode === selectedMepar)) {
                newSelectedMepar = alertMepars[0];
            }

            const alertDates = farmerModule.getMeparAlertDates(newSelectedMepar);
            const newAlertDate = alertDate || alertDates[0];

            const url = farmerModule.composeUrl(
                newSelectedMepar,
                FARMER_VIEWS.ALERT,
                productDate,
                newAlertDate
            );

            history.push(url);
        }
    }

    return (
        <div className={classes.layout}>
            <Button
                variant="contained"
                disabled={policyPlotToDraw}
                className={clsx(
                    classes.button,
                    viewState === FARMER_VIEWS.NDVI && classes.buttonNdvi
                )}
                onClick={handleNdviClick}
            >
                NDVI
            </Button>
            <Button
                variant="contained"
                disabled={policyPlotToDraw || !enableAlerts}
                className={clsx(
                    classes.button,
                    viewState === FARMER_VIEWS.ALERT && classes.buttonAlert
                )}
                onClick={handleAlertClick}
            >
                Riasztás
            </Button>
        </div>
    );
}
