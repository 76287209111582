import { List, ListItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { ROUTES } from 'shared/src/constants/routes';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    redLabel: {
        color: 'red',
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
});

class PolicyPlotPanel extends React.Component {
    render() {
        const { classes, policy, policyPlot } = this.props;
        moment.locale('hu');

        if (!policy || !policyPlot) {
            console.log(policy, policyPlot);
            return null;
        }

        const meparBorderIsMissing = !policyPlot.meparPolygon;

        return (
            <List>
                <ListItem>
                    <div className={classes.layout}>
                        {meparBorderIsMissing && (
                            <div className={classes.row}>
                                <Typography className={classes.redLabel}>
                                    A MePAR körvonal hiányzik!
                                </Typography>
                            </div>
                        )}
                        <div className={classes.row}>
                            <Typography className={classes.label}>MePAR kód:</Typography>
                            <Typography className={classes.value}>
                                <Link
                                    className={classes.value}
                                    to={ROUTES.BLOCK(policyPlot.meparCode)}
                                >
                                    {policyPlot.meparCode}
                                </Link>
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Kötvényszám:</Typography>
                            <Typography className={classes.value}>
                                <Link
                                    className={classes.value}
                                    to={ROUTES.POLICY(policy.policyNumber)}
                                >
                                    {policy.policyNumber}
                                </Link>
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Növény:</Typography>
                            <Typography className={classes.value}>
                                {policyPlot.plantCode}
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Bizt. terület:</Typography>
                            <Typography className={classes.value}>
                                {`${policyPlot.insuredArea} ha`}
                            </Typography>
                        </div>
                    </div>
                </ListItem>
            </List>
        );
    }
}

const mapStateToProps = (state) => {
    const { policy, policyPlot } = state.policy;
    return {
        policy,
        policyPlot,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(PolicyPlotPanel)));
