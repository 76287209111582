import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    layout: {},
    wrapper: {
        margin: '10px',
        position: 'absolute',
        top: '70px',
        left: '0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minHeight: 0,
        backgroundColor: 'white',
        borderRadius: '2px',
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
    },
    titleRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '10px',
    },
    legendArea: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 10px 10px 10px',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        '& > * + *': {
            marginLeft: '10px',
        },
    },
    expandButton: {
        width: '40px',
        '&.MuiButton-root': {
            minWidth: '0px',
        },
    },
    colorBox: {
        width: '10px',
        height: '10px',
    },
    inaccessible: {
        backgroundColor: '#E64545',
    },
    closed: {
        backgroundColor: '#FFAA00',
    },
    difficult: {
        backgroundColor: '#E6D200',
    },
    easy: {
        backgroundColor: '#00B377',
    },
    legendTitle: {
        fontSize: '14px',
    },
    legendText: {
        whiteSpace: 'nowrap',
    },
    geneticLegend: {},
    physicalLegend: {
        marginTop: '20px',
    },
}));

export default function CemeteryLegend() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    function handleClick() {
        setOpen(!open);
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleRow}>
                <div className={classes.legendTitle}>Jelmagyarázat</div>
                <Button className={classes.expandButton} onClick={handleClick}>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
            </div>
            {open && (
                <div className={classes.legendArea}>
                    <img
                        alt="logo"
                        src="/images/cemetery/temeto_legend.PNG"
                        width="600px"
                        className={classes.geneticLegend}
                    />
                </div>
            )}
        </div>
    );
}
