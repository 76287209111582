import { BaseActions, simpleAction } from './baseActions';
import { meparService } from '../../services/meparService';
import epsg from 'epsg';
import proj4 from 'proj4';
import _ from 'lodash';
import { farmerModule } from 'shared/src/modules/farmerModule';
import { policyService } from '../../services';
import { logService } from '../../services/logService';
import { LOGS } from 'shared';

const { reproject } = require('reproject');

export const meparConstants = {
    SET_MEPARS: 'MEPAR_SET_MEPARS',
    SET_ALERT_MEPARS: 'MEPAR_SET_ALERT_MEPARS',
    SET_SELECTED_MEPAR: 'MEPAR_SET_SELECTED_MEPAR',
    SET_POLICY_PLOT_TO_DRAW: 'MEPAR_SET_POLICY_PLOT_TO_DRAW',
    UPDATE_POLICY_PLOT: 'MEPAR_UPDATE_POLICY_PLOT',
    SET_SHOW_NDVI_LAYER: 'MEPAR_SET_SHOW_NDVI_LAYER',
    SET_SHOW_ALERT_LAYER: 'MEPAR_SET_SHOW_ALERT_LAYER',
    SET_PRODUCT_DATE: 'MEPAR_SET_PRODUCT_DATE',
    SET_ALERT_DATE: 'MEPAR_SET_ALERT_DATE',
    SET_EDITED_POLYGON: 'MEPAR_SET_EDITED_POLYGON',
    SET_BLOCK_PLOT: 'MEPAR_SET_BLOCK_PLOT',
    SET_PRODUCT_TYPE: 'MEPAR_SET_PRODUCT_TYPE',
    SET_PRODUCT: 'MEPAR_SET_PRODUCT',
};

class MeparActions extends BaseActions {
    requestFarmerMepars() {
        return this.asyncAction(async (dispatch) => {
            const mepars = await meparService.getMepars();
            const alertDates = farmerModule.getAlertDates(mepars);
            const alertDate = (alertDates && alertDates.length > 0 && alertDates[0]) || null;
            const alertMepars = alertDate && farmerModule.getAlertMepars(mepars, alertDate);

            dispatch(this.setMepars(mepars));
            dispatch(this.setAlertMepars(alertMepars));
        });
    }

    requestAgroMepars() {
        return this.asyncAction(async (dispatch) => {
            const mepars = await meparService.getMepars();
            dispatch(this.setMepars(mepars));
        });
    }

    setShowNdviLayer(showNdviLayer) {
        logService
            .saveLog(LOGS.TYPES.SET_NDVI_LAYER, showNdviLayer)
            .catch((error) => console.log(error));
        return {
            type: meparConstants.SET_SHOW_NDVI_LAYER,
            payload: {
                showNdviLayer,
            },
        };
    }

    setShowAlertLayer(showAlertLayer) {
        logService
            .saveLog(LOGS.TYPES.SET_ALERT_LAYER, showAlertLayer)
            .catch((error) => console.log(error));
        return {
            type: meparConstants.SET_SHOW_ALERT_LAYER,
            payload: {
                showAlertLayer,
            },
        };
    }

    setBlockPlot(blockPlot) {
        return {
            type: meparConstants.SET_BLOCK_PLOT,
            payload: {
                blockPlot,
            },
        };
    }

    setProductType(productType) {
        return {
            type: meparConstants.SET_PRODUCT_TYPE,
            payload: {
                productType,
            },
        };
    }

    setProduct(product) {
        return {
            type: meparConstants.SET_PRODUCT,
            payload: {
                product,
            },
        };
    }

    setMepars(mepars) {
        return {
            type: meparConstants.SET_MEPARS,
            payload: {
                mepars,
            },
        };
    }

    setAlertMepars(alertMepars) {
        return {
            type: meparConstants.SET_ALERT_MEPARS,
            payload: {
                alertMepars,
            },
        };
    }

    setProductDate(productDate) {
        logService
            .saveLog(LOGS.TYPES.SET_PRODUCT_DATE, productDate)
            .catch((error) => console.log(error));
        return {
            type: meparConstants.SET_PRODUCT_DATE,
            payload: {
                productDate,
            },
        };
    }

    setAlertDate(alertDate) {
        return {
            type: meparConstants.SET_ALERT_DATE,
            payload: {
                alertDate,
            },
        };
    }

    setSelectedMepar(selectedMepar) {
        logService
            .saveLog(LOGS.TYPES.SET_SELECTED_MEPAR, selectedMepar && selectedMepar.id)
            .catch((error) => console.log(error));
        return {
            type: meparConstants.SET_SELECTED_MEPAR,
            payload: {
                selectedMepar,
            },
        };
    }

    setEditedPolygon(editedPolygon) {
        return {
            type: meparConstants.SET_EDITED_POLYGON,
            payload: {
                editedPolygon,
            },
        };
    }

    drawPlotGeometry(policyPlot) {
        if (policyPlot) {
            logService
                .saveLog(LOGS.TYPES.DRAWING_POLICY_PLOT_STARTED, policyPlot && policyPlot.id)
                .catch((error) => console.log(error));
        } else {
            logService
                .saveLog(LOGS.TYPES.DRAWING_POLICY_PLOT_FINISHED, null)
                .catch((error) => console.log(error));
        }
        return {
            type: meparConstants.SET_POLICY_PLOT_TO_DRAW,
            payload: {
                policyPlot,
            },
        };
    }

    deletePlotGeometry(policyPlot) {
        return this.asyncAction(async (dispatch) => {
            policyPlot.tableGeometry = null;

            await policyService.savePolicyPlotOutline(policyPlot.id, null);

            dispatch(simpleAction(meparConstants.UPDATE_POLICY_PLOT, { policyPlot }));
        });
    }

    savePlotGeometry(policyPlot, polygon) {
        return this.asyncAction(async (dispatch) => {
            const geometry = {
                type: 'Polygon',
                coordinates: [[]],
            };

            polygon
                .getPath()
                .getArray()
                .forEach((point) => {
                    geometry.coordinates[0].push([point.lng(), point.lat()]);
                });

            geometry.coordinates[0].push(_.cloneDeep(geometry.coordinates[0][0]));

            policyPlot.tableGeometry = reproject(geometry, proj4.WGS84, 'EPSG:23700', epsg);

            await policyService.savePolicyPlotOutline(policyPlot.id, policyPlot.tableGeometry);

            dispatch(simpleAction(meparConstants.UPDATE_POLICY_PLOT, { policyPlot }));
        });
    }
}

export const meparActions = new MeparActions();
