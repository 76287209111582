import moment from 'moment';

export const SERVER_ERROR_INVALID_CREDENTIALS = 1;
export const SERVER_ERROR_SERVICE_UNAVAILABLE = 2;

export async function sendServerRequest(method, path, body) {
    moment.fn.toJSON = function () {
        return this.format();
    };

    let token = null;
    try {
        token = localStorage.getItem('jwt');
    } catch (error) {}

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
        body: JSON.stringify(body),
    };

    let response = null;

    try {
        response = await fetch(path, options);
    } catch (error) {
        handleServiceError(error);
    }

    if (response.status === 403 || response.status === 401) {
        handleCredentialsError();
    }

    if (response.status < 200 || response.status >= 400) {
        handleServiceError();
    }

    return response.json();
}

function handleServiceError(errorObject) {
    console.log('Client error occurred', errorObject);
    const error = new Error('Service is not available!');
    Object.assign(error, {
        code: SERVER_ERROR_SERVICE_UNAVAILABLE,
    });
    throw error;
}

function handleCredentialsError() {
    const error = new Error('Invalid username or password!');
    Object.assign(error, {
        code: SERVER_ERROR_INVALID_CREDENTIALS,
    });
    throw error;
}
