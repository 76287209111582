export const layerService = {
    getRadars,
    getCropMap,
    getCity,
    getGeometry,
    getDamageChronology,
    getYields,
    getMaxValue,
    getFrostDamageChronology,
    getFrostDamages,
};

async function getRadars(radarDate, damagePlotId) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const response = radarDate
        ? await fetch(`/api/layers/radar/${radarDate.format('YYYY-MM-DD')}`, options)
        : damagePlotId
        ? await fetch(`/api/layers/radar/?damagePlotId=${encodeURI(damagePlotId)}`, options)
        : await fetch(`/api/layers/radar/`, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function getDamageChronology() {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await fetch(`/api/layers/damageChronology`, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function getCropMap(year, extent) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const extentPolygon = { type: 'Polygon', coordinates: extent.getCoordinates() };
    const extentPolygonString = JSON.stringify(extentPolygon);

    const response = await fetch(
        `/api/layers/cropMap/${year || ''}?extent=${encodeURIComponent(extentPolygonString)}`,
        options
    );

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function getCity(extent) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const extentPolygon = { type: 'Polygon', coordinates: extent.getCoordinates() };
    const extentPolygonString = JSON.stringify(extentPolygon);

    const response = await fetch(
        `/api/layers/city?extent=${encodeURIComponent(extentPolygonString)}`,
        options
    );

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function getGeometry(geometryId) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await fetch(`/api/layers/geometry/${encodeURIComponent(geometryId)}`, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function getYields(extent, plant, table, type) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';

        const extentPolygon = { type: 'Polygon', coordinates: extent.getCoordinates() };
        const extentPolygonString = JSON.stringify(extentPolygon);

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-admin': `${isAdmin}`,
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await fetch(
            `/api/layers/yields/${plant}/${table}/${type}?extent=${encodeURIComponent(
                extentPolygonString
            )}`,
            options
        );

        if (response.error) {
            throw new Error(response.error.message);
        }
        return response.json();
    } catch (error) {
        return error;
    }
}

async function getMaxValue(plant, table, type, field) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-admin': `${isAdmin}`,
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await fetch(`/api/layers/max/${plant || 'KALASZOS'}/${table}/${type || 'reference'}/${field}`, options);

        if (response.error) {
            throw new Error(response.error.message);
        }
        return response.json();
    } catch (error) {
        return error;
    }
}

async function getFrostDamageChronology() {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {
    }

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            'Authorization': `Bearer ${token}`,
        },
    };

    const response = await fetch(`/api/layers/frostDamageChronology`, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function getFrostDamages(extent) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {
    }

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            'Authorization': `Bearer ${token}`,
        },
    };

    const extentPolygon = { type: 'Polygon', coordinates: extent.getCoordinates() };
    const extentPolygonString = JSON.stringify(extentPolygon);

    const response = await fetch(`/api/layers/frostDamages?extent=${encodeURIComponent(extentPolygonString)}`, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}
