export const LAYERS = {
    TYPES: {
        BASE: 1,
        STREET: 2,
        PRODUCT: 3,
        RADAR: 4,
        CROP: 5,
        PLOT: 6,
        SAMPLING: 7,
        DRAWING: 8,
        COUNTRY: 9,
        CITY: 10,
        POSITION: 11,
        WOODWISER_DTM: 12,
        WOODWISER_HEIGHT: 13,
        WOODWISER_ASPECT: 14,
        WOODWISER_SLOPE: 15,
        WOODWISER_RGB: 16,
        WOODWISER_FOREST_UNIT: 17,
        WOODWISER_STEM: 18,
        WOODWISER_CROWN: 19,
        SAMPLING_ZONE_RASTER: 20,
        SAMPLING_ZONE_VECTOR: 21,
        PROKAT_SOIL_ZONE_PHYSICAL: 22,
        PROKAT_SOIL_ZONE_GENETIC: 23,
        PROKAT_SOIL_SAMPLE: 24,
        PROKAT_SOIL_SAMPLE_MEPAR: 25,
        PROKAT_DTM: 26,
        PROKAT_NDVI: 27,
        PROKAT_SOIL_MANAGEMENT_ZONE: 28,
        EK: 29,
        EK_PRODUCT: 31,
        MEPAR: 32,
        BLOCK_PLOT: 33,
        BLOCK_PLOT_PRODUCT: 34,
        BLOCK_PLOT_SOIL_MANAGEMENT_ZONE: 35,
        CEMETERY_UAV: 36,
        CEMETERY_GRAVE: 37,
        CEMETERY_OBJECT: 38,
        DAMAGE_CHRONOLOGY: 39,
        KARNAVI_PHOTOS: 40,
        YIELD: 41,
        FROST_DAMAGE: 42,
        FROST_DANGER: 43,
        FROST_SURFACE: 44,
        FROST_DAMAGE_CHRONOLOGY: 45,
    },
};
