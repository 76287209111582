import { Divider, List, ListItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button/Button';
import { ROUTES } from 'shared/src/constants/routes';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { PRODUCTS } from 'shared';

const styles = (theme) => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    blockButtonHighlighted: {
        backgroundColor: 'rgba(170,170,170,1)',
    },
    buttonLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonInfoRowLayout: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    buttonTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    groupTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    buttonLabel: {
        fontSize: '12px',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: '12px',
    },
    divider: {
        marginTop: '10px',
    },
    groupTitleRow: {
        paddingBottom: '0px',
    },
});

const meparBlocks1 = [
    {
        meparCode: 'T4QNPD17',
        plants: ['SZŐLŐ - 3.63 HA'],
    },
    {
        meparCode: 'TJDHCX17',
        plants: ['ALMA - 3.56 HA', 'ALMA - 0.53 HA'],
    },
];

const meparBlocks2 = [
    {
        meparCode: 'T71PDH17',
        plants: ['ALMA - 3.83 HA', 'ALMA - 6.93 HA'],
    },
    {
        meparCode: 'TFM5DT17',
        plants: ['SZŐLŐ - 0.44 HA', 'SZŐLŐ - 0.59 HA'],
    },
];

class EKPanel extends React.Component {
    onProductButton = (ekCode, meparCode, productType) => () => {
        this.props.history.push(
            `${ROUTES.EK(ekCode)}?meparCode=${meparCode}&productType=${productType}`
        );
    };

    render() {
        const { classes, user, ekCode } = this.props;

        if (!user || !ekCode) {
            return null;
        }

        return (
            <List>
                <ListItem>
                    <div className={classes.layout}>
                        <div className={classes.row}>
                            <Typography className={classes.label}>EK azonosító:</Typography>
                            <Typography className={classes.value}>{ekCode}</Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>KR szám:</Typography>
                            <Typography className={classes.value}>
                                {ekCode === '3545151354' ? '74408610720200' : '41713416703352'}
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Aláírás ideje:</Typography>
                            <Typography className={classes.value}>
                                {ekCode === '3545151354' ? '2020. 12. 10.' : '2020. 11. 30.'}
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Tulajdonos:</Typography>
                            <Typography className={classes.label}>
                                {ekCode === '3545151354'
                                    ? '4400 Nyíregyháza, Kossuth tér 1.'
                                    : '4025 Debrecen, Piac u. 20.'}
                            </Typography>
                        </div>
                    </div>
                    <Divider />
                </ListItem>
                {[...(ekCode === '3545151354' ? meparBlocks1 : meparBlocks2)].map(
                    (meparBlock, index) => (
                        <div key={index}>
                            <Divider className={classes.divider} />
                            <ListItem className={classes.groupTitleRow}>
                                <div className={classes.row}>
                                    <Typography className={classes.label}>MePAR:</Typography>
                                    <Typography className={classes.value}>
                                        {meparBlock.meparCode}
                                    </Typography>
                                </div>
                            </ListItem>
                            {meparBlock.plants.map((plant) => (
                                <ListItem>
                                    <div className={classes.row}>
                                        <Typography className={classes.label}></Typography>
                                        <Typography className={classes.value}>{plant}</Typography>
                                    </div>
                                </ListItem>
                            ))}
                            <ListItem>
                                <Button
                                    className={classNames(classes.button)}
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    onClick={this.onProductButton(
                                        ekCode,
                                        meparBlock.meparCode,
                                        PRODUCTS.TYPES.CHRONOLOGY
                                    )}
                                >
                                    <div className={classes.buttonLayout}>
                                        <Typography className={classes.buttonTitle}>
                                            Kronológia
                                        </Typography>
                                    </div>
                                </Button>
                            </ListItem>
                            <ListItem>
                                <Button
                                    className={classNames(classes.button)}
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    onClick={this.onProductButton(
                                        ekCode,
                                        meparBlock.meparCode,
                                        PRODUCTS.TYPES.CHANGE_MAP
                                    )}
                                >
                                    <div className={classes.buttonLayout}>
                                        <Typography className={classes.buttonTitle}>
                                            Változás térkép
                                        </Typography>
                                    </div>
                                </Button>
                            </ListItem>
                        </div>
                    )
                )}
            </List>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.user;
    const { ekCode } = state.ek;
    return {
        user,
        ekCode,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(EKPanel)));
