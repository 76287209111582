import { LAYERS, ROLE } from 'shared';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import { Fill, Stroke, Style } from 'ol/style';
import Select from 'ol/interaction/Select';
import { singleClick } from 'ol/events/condition';
import PopupFeature from 'ol-ext/overlay/PopupFeature';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';

export default class CemeteryGraveLayer {
    map = null;
    layer = null;

    constructor(map, user) {
        this.map = map;
        this.init(user);
    }

    init(user) {
        const self = this;

        this.layer = new VectorLayer({
            title: 'Sírok',
            type: LAYERS.TYPES.CEMETERY_GRAVE,
            visible: true,
            displayInLayerSwitcher: true,
            source: new VectorSource(),
            style: (feature) => {
                const selectedDeads = this.layer.get('selectedDeads');
                const selectedGraves = this.layer.get('selectedGraves');
                const grave = feature.get('grave');
                return new Style({
                    stroke: new Stroke({
                        color: self.isGraveSelected(grave, selectedDeads, selectedGraves)
                            ? 'red'
                            : 'yellow',
                        width: 2,
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.1)',
                    }),
                });
            },
        });

        this.initInteractions(user);
        this.addInteractions();
    }

    initInteractions(user) {
        this.pointerSelect = new Select({
            condition: singleClick,
            layers: [this.layer],
        });

        this.popup = new PopupFeature({
            select: this.pointerSelect,
            canFix: true,
            closeBox: true,
            showImage: false,
            template: {
                title: (feature) => {
                    const grave = feature.get('grave');
                    return `${grave.parcel ? `${grave.parcel}` : ''}${
                        grave.row ? ` - ${grave.row}` : ''
                    }${grave.pos ? `/${grave.pos}` : ''}`;
                },
                attributes: Object.assign(
                    {
                        dead_person: {
                            title: 'Elhunyt neve',
                            format: function (value, feature) {
                                const grave = feature.get('grave');
                                return grave.deads.length > 0
                                    ? grave.deads
                                          .map((dead) => dead.name || 'Nincs adat')
                                          .join('<br/>')
                                    : 'Nincs adat';
                            },
                        },
                        dead_person_born_died: {
                            title: 'Élt',
                            format: function (value, feature) {
                                const grave = feature.get('grave');
                                return grave.deads.length > 0
                                    ? grave.deads
                                          .map(
                                              (dead) =>
                                                  `${
                                                      (dead.birthDate &&
                                                          dead.birthDate.replace('.00.00', '')) ||
                                                      '?'
                                                  } - ${
                                                      (dead.deathDate &&
                                                          dead.deathDate.replace('.00.00', '')) ||
                                                      '?'
                                                  }`
                                          )
                                          .join('<br/>')
                                    : 'Nincs Adat';
                            },
                        },
                    },
                    user.role === ROLE.CEMETERY
                        ? {
                              valid_date: {
                                  title: 'Lejárat ideje',
                                  format: function (value, feature) {
                                      const grave = feature.get('grave');
                                      return grave.graveValidDate
                                          ? grave.graveValidDate
                                          : 'Nincs adat';
                                  },
                              },
                          }
                        : {},
                    {
                        photo: {
                            title: 'Fotó',
                            format: function (value, feature) {
                                const grave = feature.get('grave');
                                if (!grave.photoPath) {
                                    return 'Nincs';
                                }
                                const photoPath = grave.photoPath.replace(/photos\\/g, 'photos/');
                                return `<a href="/content/${photoPath.toLowerCase()}" onclick="lightbox.start($(this));return false;" data-lightbox="image-1"><img alt='Image' src="/content/${photoPath.toLowerCase()}"></a>`;
                            },
                        },
                    }
                ),
            },
        });

        this.map.map.addOverlay(this.popup);
    }

    addInteractions() {
        this.removeInteractions();
        this.map.map.addInteraction(this.pointerSelect);
    }

    removeInteractions() {
        this.map.map.removeInteraction(this.pointerSelect);
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (layer.graves !== this.layer.get('graves') && layer.graves && layer.visible) {
            let graveSource = new VectorSource();

            const graves = layer.graves;

            graves.forEach((grave) => {
                if (grave.geom) {
                    const polygon = grave.geom;
                    if (polygon) {
                        const feature = new Feature({
                            geometry:
                                polygon.type === 'Polygon'
                                    ? new Polygon(polygon.coordinates)
                                    : new MultiPolygon(polygon.coordinates),
                            grave,
                            graves: layer.graves,
                        });
                        graveSource.addFeature(feature);
                    }
                }
            });

            this.layer.setSource(graveSource);
            this.layer.set('graves', graves);
        }

        this.layer.setVisible(layer.visible);
    }

    setSelectedDeads(selectedDeads) {
        if (this.layer) {
            this.layer.set('selectedDeads', selectedDeads);
            this.refresh();
        }
    }

    setSelectedGraves(selectedGraves) {
        if (this.layer) {
            this.layer.set('selectedGraves', selectedGraves);
            this.refresh();
        }
    }

    refresh() {
        const graves = this.layer.get('graves');
        const selectedDeads = this.layer.get('selectedDeads');
        const selectedGraves = this.layer.get('selectedGraves');

        const fittingGraves = [];
        const fittingSource = new VectorSource();

        graves.forEach((grave) => {
            if (grave.geom && this.isGraveSelected(grave, selectedDeads, selectedGraves)) {
                const polygon = grave.geom;
                if (polygon) {
                    const feature = new Feature({
                        geometry:
                            polygon.type === 'Polygon'
                                ? new Polygon(polygon.coordinates)
                                : new MultiPolygon(polygon.coordinates),
                    });
                    fittingSource.addFeature(feature);
                    fittingGraves.push(grave);
                }
            }
        });

        this.layer.getSource().refresh();

        if (fittingGraves.length > 0) {
            this.map.map.getView().fit(fittingSource.getExtent(), this.map.map.getSize());
        }

        if (fittingGraves.length === 1) {
            const fittingGrave = fittingGraves[0];
            const fittingFeature = this.layer
                .getSource()
                .getFeatures()
                .find((feature) => {
                    const grave = feature.get('grave');
                    return grave && fittingGrave && grave.id === fittingGrave.id;
                });

            if (fittingFeature) {
                setTimeout(() => {
                    if (this.map.map.isRendered()) {
                        const featureExtent = fittingFeature.getGeometry().getExtent();
                        const center = this.getExtentCenter(featureExtent);
                        this.popup.show(center, fittingFeature);
                    }
                }, 1000);
            }
        }
    }

    isGraveSelected(grave, selectedDeads, selectedGraves) {
        return (
            (selectedDeads &&
                selectedDeads.find((selectedDead) => selectedDead.graveId === grave.id)) ||
            (selectedGraves &&
                selectedGraves.find((selectedGrave) => selectedGrave.id === grave.id))
        );
    }

    getExtentCenter(extent) {
        return [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2];
    }
}
