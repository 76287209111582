import { useContext, useEffect, useState } from 'react';
import { toWgs84 } from 'reproject';
import epsg from 'epsg';
import { GoogleMapContext } from '@googlemap-react/core';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { FARMER_VIEWS } from 'shared';
import { farmerModule } from 'shared/src/modules/farmerModule';
import { useHistory } from 'react-router';

export default function PolicyPlotLayer(props) {
    const [mapLayer, setMapLayer] = useState(null);
    const mepars = useSelector((state) => state.mepar.mepars);
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const {
        state: { map },
    } = useContext(GoogleMapContext);
    const viewState = useSelector((state) => state.view.farmerViewState);
    const productDate = useSelector((state) => state.mepar.productDate);
    const alertDate = useSelector((state) => state.mepar.alertDate);
    const alertMepars = useSelector((state) => state.mepar.alertMepars);
    const history = useHistory();
    const policyPlotToDraw = useSelector((state) => state.mepar.policyPlotToDraw);

    useEffect(() => {
        if (!map) {
            return;
        }

        // eslint-disable-next-line no-undef
        const mapLayer = new google.maps.Data({});

        mapLayer.setMap(map);

        setMapLayer(mapLayer);
    }, [map]);

    useEffect(() => {
        if (!mapLayer || !selectedMepar) {
            return;
        }

        mapLayer.setStyle((feature) => {
            const policyPlot = feature.getProperty('policyPlot');
            const mepar = policyPlot.mepar;
            const isSelected = selectedMepar.meparCode === mepar.meparCode;
            return {
                strokeColor: isSelected ? '#016400' : 'rgba(1, 100, 0, 0.5)',
                strokeWeight: 2,
                fillOpacity: 0,
            };
        });
    }, [mapLayer, selectedMepar]);

    useEffect(() => {
        if (!mapLayer) {
            return;
        }

        // eslint-disable-next-line no-undef
        google.maps.event.clearListeners('click');

        mapLayer.addListener('click', function (event) {
            const policyPlot = event.feature.getProperty('policyPlot');
            const mepar = policyPlot.mepar;
            if (
                !policyPlotToDraw &&
                (!selectedMepar || selectedMepar.meparCode !== mepar.meparCode)
            ) {
                const newViewState =
                    viewState === FARMER_VIEWS.ALERT &&
                    alertMepars.find((alertMepar) => alertMepar.meparCode === mepar.meparCode)
                        ? FARMER_VIEWS.ALERT
                        : FARMER_VIEWS.NDVI;
                const url = farmerModule.composeUrl(mepar, newViewState, productDate, alertDate);
                history.push(url);
            }
        });
    }, [
        mapLayer,
        selectedMepar,
        policyPlotToDraw,
        alertDate,
        alertMepars,
        history,
        productDate,
        viewState,
    ]);

    useEffect(() => {
        if (!map || !mapLayer || !mepars) {
            return;
        }

        mapLayer.forEach((feature) => {
            mapLayer.remove(feature);
        });

        const allPolicyPlots = _.flatten(
            mepars.map((mepar) => {
                mepar.policyPlots.forEach((policyPlot) => (policyPlot.mepar = mepar));
                return mepar.policyPlots;
            })
        );

        allPolicyPlots.reduce((accumulator, policyPlot) => {
            if (policyPlot.tableGeometry) {
                const geoJson = {
                    type: 'Feature',
                    geometry: toWgs84(policyPlot.tableGeometry, 'EPSG:23700', epsg),
                    properties: {
                        policyPlot,
                    },
                };

                return [...accumulator, ...mapLayer.addGeoJson(geoJson)];
            }

            return accumulator;
        }, []);
    }, [mepars, map, mapLayer]);

    return null;
}
