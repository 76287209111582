import { Fill, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { LAYERS } from 'shared';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import moment from 'moment';

moment.locale('hu');

export default class SamplingZoneVectorLayer {
    map = null;
    source = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.source = new VectorSource();

        this.layer = new VectorLayer({
            title: 'Mintavételi zónák (vektor)',
            type: LAYERS.TYPES.SAMPLING_ZONE_VECTOR,
            visible: false,
            source: this.source,
            displayInLayerSwitcher: false,
            style: (feature) => {
                const samplingZone = feature.get('samplingZone');
                return new Style({
                    fill: new Fill({
                        color: samplingZone.color,
                    }),
                });
            },
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (
            layer.damagePlot !== this.layer.get('damagePlot') &&
            layer.damagePlot &&
            layer.damagePlot.samplingZones
        ) {
            const damagePlot = layer.damagePlot;
            const samplingZones = damagePlot.samplingZones;
            this.source.clear();

            samplingZones.forEach((samplingZone) => {
                const polygon = JSON.parse(samplingZone.polygon);
                const feature = new Feature({
                    geometry: new Polygon(polygon.coordinates),
                    samplingZone,
                });

                this.source.addFeature(feature);
            });

            this.layer.set('damagePlot', damagePlot);
        }
        this.layer.set('displayInLayerSwitcher', layer.visible);
        this.layer.setVisible(layer.visible);
    }
}
