import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { farmerModule } from 'shared/src/modules/farmerModule';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import moment from 'moment';
import 'moment/locale/hu';
import { meparActions } from '../../state/actions/meparActions';

moment.locale('hu');

const useStyles = makeStyles((theme) => ({
    layout: {
        zIndex: 200,
    },
    dropdown: {
        maxHeight: '300px',
        overflow: 'auto',
    },
}));

export default function MeparListChooser() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [dateOptions, setDateOptions] = useState([]);
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const productDate = useSelector((state) => state.mepar.productDate);
    const viewState = useSelector((state) => state.view.farmerViewState);
    const dispatch = useDispatch();

    // we remove drawing requirement for now
    // const selectedMeparHasTableGeometries = farmerModule.meparHasSomeTableGeometries(selectedMepar);
    const filteredDateOptions =
        /* selectedMeparHasTableGeometries ? */ dateOptions; /* : dateOptions.filter(
        dateOption => farmerModule.meparDateHasAlert(selectedMepar, dateOption.date) ? dateOption : null).filter(dateOption => dateOption) */

    useEffect(() => {
        if (!selectedMepar) {
            return;
        }

        const ndviDates = farmerModule.getMeparNdviDates(selectedMepar);
        const alertDates = farmerModule.getMeparAlertDates(selectedMepar);
        const dateOptions = ndviDates.map((ndviDate) => {
            const alertDate = alertDates.find((alertDate) => alertDate === ndviDate);
            return {
                date: ndviDate,
                isAlert: !!alertDate,
            };
        });

        setDateOptions(dateOptions);
    }, [selectedMepar, viewState]);

    const handleMenuItemClick = (event, dateOption) => {
        dispatch(meparActions.setProductDate(dateOption.date));
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            {filteredDateOptions && filteredDateOptions.length > 0 && (
                <>
                    <Button onClick={handleToggle} size="small" ref={anchorRef}>
                        {productDate && moment(productDate).format('MMM D.')}
                        <ArrowDropDownIcon />
                    </Button>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        className={classes.layout}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper className={classes.dropdown}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu">
                                            {filteredDateOptions.map((dateOption, index) => (
                                                <MenuItem
                                                    key={index}
                                                    selected={dateOption.date === productDate}
                                                    onClick={(event) =>
                                                        handleMenuItemClick(event, dateOption)
                                                    }
                                                >
                                                    {moment(dateOption.date).format('YYYY. MMM D.')}
                                                    {dateOption.isAlert && (
                                                        <NotificationsActiveIcon />
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </>
            )}
        </>
    );
}
