import { dialogConstants } from '../actions/dialogActions';

const initialState = {
    isPolicyPlotDialogVisible: false,
};

export function dialog(state = initialState, action) {
    switch (action.type) {
        case dialogConstants.SHOW_POLICY_PLOT_DIALOG:
            return {
                ...state,
                isPolicyPlotDialogVisible: action.payload.show,
            };
        default:
            return state;
    }
}
