import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { withTheme } from '@material-ui/styles';

const SamplingModal = ({ drawing, onSave, onClose, showModal, MODAL_TYPE }) => {
    const samplingData = drawing;
    const coordinates = JSON.parse(drawing.polygon).coordinates;
    const roundedCoordinates = coordinates.map((c) => Math.round((c + Number.EPSILON) * 100) / 100);
    const updatedAt = new Date();
    const [createdAt, setCreatedAt] = useState(samplingData.createdAt || new Date());
    const [yieldAmount, setYieldAmount] = useState(samplingData.yield);
    const [name, setName] = useState(samplingData.name || null);
    const [comment, setComment] = useState(samplingData.comment || null);

    const handleSave = () => {
        showModal(MODAL_TYPE.NONE);
        onSave({
            ...samplingData,
            coordinates,
            createdAt,
            updatedAt,
            yield: yieldAmount,
            name,
            comment,
        });
    };

    const handleCancel = () => {
        onClose(samplingData.newPoint);
    };

    return (
        <Dialog open={showModal}>
            <DialogTitle>
                {samplingData.newPoint ? 'Új mintavétel rögzítése' : 'Mintavétel módosítása'}
            </DialogTitle>

            <DialogContent>
                <Grid container mt={2} component={'form'}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            disabled
                            variant="standard"
                            label="Sorszám"
                            value={samplingData.sequenceId}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            disabled
                            variant="standard"
                            label="Koordináták"
                            value={roundedCoordinates}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            variant="standard"
                            type="number"
                            label="Hozam (t/ha)"
                            value={yieldAmount}
                            onChange={(event) => setYieldAmount(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="standard"
                            label="Elnevezés"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="standard"
                            label="Megjegyzés"
                            value={comment}
                            onChange={(event) => setComment(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimePicker
                            fullWidth
                            variant="standard"
                            ampm={false}
                            format="YYYY.MM.DD HH:mm:ss"
                            label="Mintavétel időpontja"
                            value={createdAt}
                            onChange={(value) => setCreatedAt(new Date(value))}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="text" color="info" onClick={handleCancel}>
                    Mégse
                </Button>

                <Button
                    variant="contained"
                    color="success"
                    onClick={handleSave}
                    disabled={!yieldAmount}
                >
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withTheme(SamplingModal);
