import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { LAYERS } from 'shared';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Select from 'ol/interaction/Select';
import { pointerMove } from 'ol/events/condition';
import PopupFeature from 'ol-ext/overlay/PopupFeature';
import moment from 'moment';

moment.locale('hu');

export default class KarnaviPhotosLayer {
    map = null;
    source = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.source = new VectorSource();

        this.layer = new VectorLayer({
            title: 'Kárnavi fotók',
            type: LAYERS.TYPES.KARNAVI_PHOTOS,
            visible: false,
            source: this.source,
            displayInLayerSwitcher: false,
            style: (feature) => {
                const sequenceId = feature.get('sequenceId');
                return new Style({
                    image: new Circle({
                        radius: 8,
                        fill: new Fill({ color: 'black' }),
                        stroke: new Stroke({
                            color: [0, 0, 255],
                            width: 2,
                        }),
                    }),
                    text: new Text({
                        font: '13px sans-serif',
                        overflow: true,
                        placement: 'above',
                        fill: new Fill({
                            color: 'white',
                        }),
                        text: `${sequenceId}`,
                    }),
                });
            },
        });

        this.initInteractions();
    }

    initInteractions() {
        this.pointerSelect = new Select({
            condition: pointerMove,
            layers: [this.layer],
        });

        const popup = new PopupFeature({
            select: this.pointerSelect,
            canFix: true,
            template: {
                title: (feature) => {
                    return `Kárfotó`;
                },
                attributes: {
                    sequenceId: {
                        title: 'Sorszám',
                        format: function (value, feature) {
                            const sequenceId = feature.get('sequenceId');
                            return sequenceId || 'Nem elérhető';
                        },
                    },
                    damageNumber: {
                        title: 'Kárszám',
                        format: function (value, feature) {
                            return feature.get('photo').damageNumber || 'Nem elérhető';
                        },
                    },
                    inspectionNumber: {
                        title: 'Szemle azonosító',
                        format: function (value, feature) {
                            return feature.get('photo').inspectionNumber || 'Nem elérhető';
                        },
                    },
                    externalId: {
                        title: 'Kárnavi azonosító',
                        format: function (value, feature) {
                            return feature.get('photo').externalId || 'Nem elérhető';
                        },
                    },
                    createdAt: {
                        title: 'Kép készítésének időpontja',
                        format: function (value, feature) {
                            const photo = feature.get('photo');
                            return moment.utc(photo.photoCreatedAt).format('YYYY. MM. DD HH:mm');
                        },
                    },
                },
            },
        });

        this.map.map.addOverlay(popup);
        this.map.map.addInteraction(this.pointerSelect);
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (
            layer.photos
        ) {
            const photos = layer.photos;
            this.source.clear();

            photos.forEach((photo, p) => {
                const polygon = JSON.parse(photo.geom);
                const feature = new Feature({
                    geometry: new Point(polygon.coordinates),
                    photo,
                    sequenceId: p + 1,
                });

                this.source.addFeature(feature);
            });

        }
        this.layer.setVisible(layer.visible);
    }
}
