import { errorActions } from '../actions/errorActions';

const initialState = {
    error: null,
};

export function error(state = initialState, action) {
    switch (action.type) {
        case errorActions.SET_ERROR:
            return {
                error: action.payload.error,
            };
        default:
            return state;
    }
}
