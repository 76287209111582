import { damagePlotAreaConstants } from '../constants';

export const damagePlotAreaActions = {
    setDamagePlotAreas,
    saveDamagePlotArea,
    removeDamagePlotArea,
};

function setDamagePlotAreas(damagePlotAreas) {
    return {
        type: damagePlotAreaConstants.SET_DAMAGEPLOTAREAS,
        payload: {
            damagePlotAreas,
        },
    };
}

function saveDamagePlotArea(area) {
    return {
        type: damagePlotAreaConstants.SAVE_DAMAGEPLOTAREA,
        payload: {
            area,
        },
    };
}

function removeDamagePlotArea(id) {
    return {
        type: damagePlotAreaConstants.REMOVE_DAMAGEPLOTAREA,
        payload: {
            id,
        },
    };
}
