import { Divider, List, ListItem, makeStyles } from '@material-ui/core';
import React from 'react';
import moment from 'moment';

moment.locale('hu');

const useStyles = makeStyles((theme) => ({
    progress: {
        margin: theme.spacing(2),
    },
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    navigationRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    navigationNumbers: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    blockButtonHighlighted: {
        backgroundColor: 'rgba(200,200,200,0.5)',
    },
    buttonLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonInfoRowLayout: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    buttonTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    buttonLabel: {
        fontSize: '12px',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: '12px',
    },
    productListWrapper: {
        flex: 1,
        overflow: 'auto',
    },
    productList: {},
    expansion: {
        width: '100%',
    },
    list: {
        paddingTop: '0px',
    },
    listItem: {
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    panelLayout: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    description: {
        fontSize: '14px',
        paddingBottom: '10px',
    },
    drawingButton: {
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
    },
    productRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    dividerTop: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    dividerBottom: {
        marginTop: '10px',
    },
}));

export default function DamageChronologyPanel() {
    const classes = useStyles();

    return (
        <List className={classes.layout}>
            <ListItem>
                <div className={classes.layout}>
                    A térkép azt mutatja meg, hogy a GeoRisk indulása óta a bejelentett káresemények
                    mely területeken koncentrálódnak. Az egyes évek összevontan jelennek meg, hogy
                    ne torzítsa a térbeli eloszlást az, hogy az egyes növények külön-külön
                    káraktákba vannak rendszerezve. Minél erősebb a színezés annál több alkalommal
                    (legalább egy) volt kárbejelentés téve az adott MePar blokkról, annak
                    környezetéről.
                </div>
                <Divider />
            </ListItem>

            <ListItem className={classes.listItem}>
                <img
                    src={'/artifacts/Karkronologia/Karkronologia_legend.jpg'}
                    width={'290px'}
                    alt={''}
                />
            </ListItem>
        </List>
    );
}
