import React, { Component } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { huHU } from '@material-ui/core/locale';
import {
    themeBase,
    themeBaseWoodwiser,
    themeBaseProkat,
    themeBaseAgrarmonitor,
    themeBaseAgro,
    themeBaseCemetery,
} from './styles';
import { connect } from 'react-redux';
import { ROLE } from 'shared/src/constants/role';

const theme = createMuiTheme(themeBase, huHU);
const themeWoodwiser = createMuiTheme(themeBaseWoodwiser);
const themeProkat = createMuiTheme(themeBaseProkat);
const themeAgrarmonitor = createMuiTheme(themeBaseAgrarmonitor);
const themeAgro = createMuiTheme(themeBaseAgro);
const themeCemetery = createMuiTheme(themeBaseCemetery);

class StyledApp extends Component {
    render() {
        const { children, user } = this.props;

        const isWoodwiser = user && user.role === ROLE.WOODWISER;
        const isProkat = user && user.role === ROLE.PROKAT;
        const isAgrarmonitor = user && user.role === ROLE.AGRARMONITOR;
        const isAgro = user && user.role === ROLE.AGRO;
        const isCemetery =
            user && (user.role === ROLE.CEMETERY || user.role === ROLE.CEMETERY_PUBLIC);

        return (
            <MuiThemeProvider
                theme={
                    isWoodwiser
                        ? themeWoodwiser
                        : isProkat
                        ? themeProkat
                        : isAgrarmonitor
                        ? themeAgrarmonitor
                        : isAgro
                        ? themeAgro
                        : isCemetery
                        ? themeCemetery
                        : theme
                }
            >
                {children}
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.user;
    return {
        user,
    };
};

export default connect(mapStateToProps)(StyledApp);
