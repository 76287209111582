import { errorActions } from './errorActions';
import { SERVER_ERROR_INVALID_CREDENTIALS } from '../../services/baseService';
import { userActions } from './userActions';

export function simpleAction(type, payload) {
    return {
        type,
        payload,
    };
}

export class BaseActions {
    asyncAction(actionFunction, errorFunction = null) {
        return async (dispatch) => {
            try {
                await actionFunction(dispatch);
            } catch (error) {
                if (errorFunction) {
                    errorFunction(dispatch, error);
                    return;
                }

                this.handleError(dispatch, error).catch((error) => console.error(error));
            }
        };
    }

    action(type, payload = {}) {
        return {
            type,
            payload,
        };
    }

    async handleError(dispatch, error) {
        if (error.code === SERVER_ERROR_INVALID_CREDENTIALS) {
            dispatch(userActions.logoutUser());
        }

        dispatch(errorActions.setError(error));
    }
}
