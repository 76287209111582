import { LAYERS } from 'shared';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { Fill, Style } from 'ol/style';
import { asArray } from 'ol/color';
import GeoJSON from 'ol/format/GeoJSON';
import { bbox } from 'ol/loadingstrategy';
import Select from 'ol/interaction/Select';
import { pointerMove } from 'ol/events/condition';
import PopupFeature from 'ol-ext/overlay/PopupFeature';
import { blockPlotSoilManagementZoneService } from '../../services';

export default class BlockPlotSoilManagementZoneGeneticLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        const vectorSource = new VectorSource({
            format: new GeoJSON(),
            loader: async (extent) => {
                const extentPolygon = fromExtent(extent);
                const soilManagementZones =
                    await blockPlotSoilManagementZoneService.getSoilManagementZones(extentPolygon);
                soilManagementZones.forEach((soilManagementZone) => {
                    const soilManagementZonePolygon = soilManagementZone.geom;
                    const feature = new Feature({
                        geometry:
                            soilManagementZonePolygon.type === 'Polygon'
                                ? new Polygon(soilManagementZonePolygon.coordinates)
                                : new MultiPolygon(soilManagementZonePolygon.coordinates),
                        soilManagementZone,
                    });
                    vectorSource.addFeature(feature);
                });
            },
            strategy: bbox,
        });

        this.layer = new VectorLayer({
            title: `Menedzsment zóna`,
            type: LAYERS.TYPES.BLOCK_PLOT_SOIL_MANAGEMENT_ZONE,
            visible: false,
            renderMode: 'vector',
            source: vectorSource,
            maxResolution: 25,
            style: (feature) => {
                const soilManagementZone = feature.get('soilManagementZone');
                const color = asArray(soilManagementZone.color);
                const rgbColor = color.slice();
                rgbColor[3] = 1;
                return new Style({
                    fill: new Fill({
                        color: rgbColor,
                    }),
                });
            },
        });
        this.initInteractions();
        this.addInteractions();
    }

    initInteractions() {
        this.pointerSelect = new Select({
            condition: pointerMove,
            layers: [this.layer],
        });

        const popup = new PopupFeature({
            select: this.pointerSelect,
            canFix: true,
            template: {
                title: (feature) => {
                    const soilZone = feature.get('soilManagementZone');
                    return soilZone.areaName;
                },
                attributes: {
                    category: {
                        title: 'Kategória',
                        format: function (value, feature) {
                            const soilZone = feature.get('soilManagementZone');
                            return soilZone.category;
                        },
                    },
                },
            },
        });

        this.map.map.addOverlay(popup);
    }

    addInteractions() {
        this.removeInteractions();
        this.map.map.addInteraction(this.pointerSelect);
    }

    removeInteractions() {
        this.map.map.removeInteraction(this.pointerSelect);
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        this.layer.setVisible(layer.visible);
    }
}
