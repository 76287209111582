import { LAYERS } from 'shared';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { Fill, Stroke, Style, Text } from 'ol/style';

export default class MeparLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new VectorLayer({
            title: 'MePAR',
            type: LAYERS.TYPES.MEPAR,
            visible: false,
            displayInLayerSwitcher: true,
            declutter: true,
            source: new VectorSource(),
            style: (feature) => {
                const mepar = feature.get('mepar');
                const selectedBlockPlot = this.layer.get('blockPlot');
                const selectedFeature =
                    selectedBlockPlot && selectedBlockPlot.meparCode === mepar.meparCode;
                const text = selectedFeature ? '' : this.getFeatureText(feature);

                return new Style({
                    stroke: new Stroke({
                        color: 'yellow',
                        width: 2,
                    }),
                    fill: new Fill({
                        color: 'rgba(0,0,0,0.00000001)',
                    }),
                    text: new Text({
                        font: '14px Calibri,sans-serif',
                        fill: new Fill({ color: '#000' }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 2,
                        }),
                        text: text,
                    }),
                });
            },
        });
    }

    getFeatureText(feature) {
        const blockPlot = feature.get('blockPlot');
        const mepar = feature.get('mepar');
        if (blockPlot) {
            return blockPlot.name;
        }

        return mepar.meparCode;
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (layer.mepars !== this.layer.get('mepars') && layer.visible) {
            const meparSource = new VectorSource();

            const mepars = layer.mepars;

            mepars.forEach((mepar) => {
                if (mepar.geom) {
                    const polygon = mepar.geom;

                    const feature = new Feature({
                        geometry:
                            polygon.type === 'Polygon'
                                ? new Polygon(polygon.coordinates)
                                : new MultiPolygon(polygon.coordinates),
                        mepar,
                    });

                    feature.set('mepar', mepar);

                    meparSource.addFeature(feature);
                }
            });

            this.layer.set('mepars', mepars);
            this.layer.setSource(meparSource);

            if (layer.fittingLayer) {
                this.map.map.getView().fit(meparSource.getExtent(), this.map.map.getSize());
            }
        }

        this.layer.set('blockPlot', layer.blockPlot);

        this.layer.setVisible(layer.visible);
    }
}
