import { userConstants } from '../actions';
import { USER_STATUS } from 'shared';

const initialState = {
    status: USER_STATUS.NOT_INITIALISED,
    user: null,
};

export function user(state = initialState, action) {
    switch (action.type) {
        case userConstants.SET_USER:
            return {
                ...state,
                user: action.payload.user,
            };
        case userConstants.REMOVE_USER:
            return {
                ...state,
                user: null,
            };
        case userConstants.SET_STATUS:
            return {
                ...state,
                status: action.payload.status,
            };
        default:
            return state;
    }
}
