import { damageConstants } from '../constants';

export const damageActions = {
    setDamage,
    setDamagePlot,
    updateDamagePlot,
    setProduct,
    setHighlightedDamagePlot,
    setHighlightedDamageMepar,
};

function setDamage(
    damage,
    damagePlot = null,
    productType = null,
    manageDrawings = false,
    manageSamplings = false
) {
    return {
        type: damageConstants.SET_DAMAGE,
        payload: {
            damage,
            damagePlot,
            productType,
            manageDrawings,
            manageSamplings,
        },
    };
}

function setDamagePlot(damagePlot) {
    return {
        type: damageConstants.SET_DAMAGE_PLOT,
        payload: {
            damagePlot,
        },
    };
}

function updateDamagePlot(damagePlot) {
    return {
        type: damageConstants.UPDATE_DAMAGE_PLOT,
        payload: {
            damagePlot,
        },
    };
}

function setProduct(product) {
    return {
        type: damageConstants.SET_PRODUCT,
        payload: {
            product,
        },
    };
}

function setHighlightedDamagePlot(highlightedDamagePlot) {
    return {
        type: damageConstants.SET_HIGHLIGHTED_DAMAGE_PLOT,
        payload: {
            highlightedDamagePlot,
        },
    };
}

function setHighlightedDamageMepar(highlightedDamageMepar) {
    return {
        type: damageConstants.SET_HIGHLIGHTED_DAMAGE_MEPAR,
        payload: {
            highlightedDamageMepar,
        },
    };
}
