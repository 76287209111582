import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@material-ui/core';
import { withTheme } from '@material-ui/styles';

const StatusModal = ({ title, status, messages, onClose, showModal }) => {
    return (
        <Dialog open={showModal}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <Grid container mt={2}>
                    <Grid item xs={12}>
                        {messages.map(message => (
                            <Typography>{message}</Typography>
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="info" onClick={onClose}>
                    Bezár
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withTheme(StatusModal);
