import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { meparActions } from '../state/actions/meparActions';
import { mapActions, viewActions } from '../state/actions';
import { LAYERS, VIEWS } from 'shared';

export default function AgroHomeController() {
    const dispatch = useDispatch();
    const mepars = useSelector((state) => state.mepar.mepars);

    useEffect(() => {
        if (mepars) {
            return;
        }

        dispatch(meparActions.requestAgroMepars());
    }, [mepars, dispatch]);

    useEffect(() => {
        if (!mepars) {
            return;
        }

        dispatch(meparActions.setProduct(null));

        dispatch(viewActions.setInfoPanelState({ open: true }));
        dispatch(viewActions.setViewState({ view: VIEWS.PROGRESS }));

        dispatch(mapActions.resetLayers());
        dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.COUNTRY,
                visible: true,
                fittingLayer: false,
            })
        );

        dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.MEPAR,
                visible: true,
                mepars,
                fittingLayer: false,
            })
        );
        dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.BLOCK_PLOT,
                visible: true,
                mepars,
                fittingLayer: true,
            })
        );

        dispatch(viewActions.setViewState({ view: VIEWS.AGRO_HOME }));
    }, [mepars, dispatch]);

    return null;
}
