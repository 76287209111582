import { layerService } from '../services';

export function toColor(numStr) {
    let num = parseInt(numStr, 10);
    num >>>= 0;
    const b = num & 0xff;
    const g = (num & 0xff00) >>> 8;
    const r = (num & 0xff0000) >>> 16;
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

export function calcColor(num, percentBase) {
    const percent = Math.min(1, num / percentBase) * 100;
    let r, b, g;

    b = 0;
    if (percent < 50) {
        r = 255;
        g = Math.round(5.1 * percent);
    } else {
        g = 255;
        r = Math.round(510 - 5.1 * percent);
    }

    let h = r * 0x10000 + g * 0x100 + b;
    return '#' + ('000000' + h.toString(16)).slice(-6);
}

function padZero(str, len) {
    if (`${str}`.length > len) {
        return str;
    }
    len = len || 2;
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

export function hexToRGB(hex) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    let r = parseInt(hex.slice(0, 2), 16);
    let g = parseInt(hex.slice(2, 4), 16);
    let b = parseInt(hex.slice(4, 6), 16);
    return {
        string: `${r}, ${g}, ${b}`,
        colors: {
            r,
            g,
            b,
        },
    };
}

export function invertColor(hexColor, bw) {
    let hex = typeof hexColor === 'undefined' ? '#fff' : hexColor;
    let { r, g, b } = hexToRGB(hex).colors;
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return `#${padZero(r)}${padZero(g)}${padZero(b)}`;
}

export async function calculateColorBase(plant, table, type, field) {
    const { max } = await layerService.getMaxValue(plant, table, type, field);
    if (max) {
        return max[field];
    }
    return null;
}
