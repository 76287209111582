import { tokenModule } from 'shared/src/modules/tokenModule';
import { userService } from '../../services';
import { BaseActions } from './baseActions';
import { SERVER_ERROR_INVALID_CREDENTIALS } from '../../services/baseService';
import { USER_STATUS } from 'shared';

export const userConstants = {
    SET_USER: 'USER_SET_USER',
    REMOVE_USER: 'USER_REMOVE_USER',
    SET_STATUS: 'USER_SET_STATUS',
};

class UserActions extends BaseActions {
    loginUser(username, password) {
        return this.asyncAction(
            async (dispatch) => {
                await userService.login(username, password);
                dispatch(this.requestUser());
            },
            (dispatch, error) => {
                if (error.code === SERVER_ERROR_INVALID_CREDENTIALS) {
                    dispatch(this._setStatus(USER_STATUS.AUTHENTICATION_FAILED));
                    return;
                }

                super.handleError(dispatch, error).catch((error) => console.error(error));
            }
        );
    }

    logoutUser() {
        return this.asyncAction(async (dispatch) => {
            await userService.logout();
            dispatch(this._removeUser());
        });
    }

    requestUser(location = null) {
        return this.asyncAction(async (dispatch) => {
            dispatch(this._setStatus(USER_STATUS.IS_FETCHING));
            let user = null;

            if (location) {
                user = await this._getUserFromLinkToken(dispatch, location);
            }

            if (!user) {
                user = await userService.getCurrentUser();
            }

            dispatch(this.setUser(user));
            dispatch(this._setStatus(USER_STATUS.FETCHED));
        });
    }

    suppressWelcomeScreen(user) {
        return this.asyncAction(async (dispatch) => {
            userService.suppressWelcomeScreen().catch((error) => console.log(error));
            dispatch(this.setUser({ ...user, welcomeScreen: false }));
        });
    }

    setUser(user) {
        return {
            type: userConstants.SET_USER,
            payload: {
                user,
            },
        };
    }

    _removeUser() {
        return {
            type: userConstants.REMOVE_USER,
        };
    }

    _setStatus(status) {
        return {
            type: userConstants.SET_STATUS,
            payload: {
                status,
            },
        };
    }

    async _getUserFromLinkToken(dispatch, location) {
        try {
            const searchParams = new URLSearchParams(location.search);
            let compoundLinkToken = searchParams.get('linkToken');

            if (!compoundLinkToken) {
                compoundLinkToken = searchParams.get('t');
            }

            if (!compoundLinkToken) {
                const match = location.pathname.match('/t/([^/]+)');
                if (match && match.length === 2) {
                    compoundLinkToken = match[1];
                }
            }

            const isAdmin = searchParams.get('admin');

            if (!compoundLinkToken) {
                return null;
            }

            const { userId, linkToken } = tokenModule.parseCompoundLinkToken(compoundLinkToken);

            await userService.loginWithLinkToken(userId, linkToken, isAdmin);
        } catch (error) {
            console.error(`Link token error ${error}, location: ${location}`);
            super.handleError(dispatch, error).catch((error) => console.error(error));
            return null;
        }
    }
}

export const userActions = new UserActions();
