import React from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';

export default function PolicyYieldsTable({ yields }) {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="body2">
                    Szerződés szerinti referencia hozam értékek:
                </Typography>
            </Grid>
            {yields && (
                <Grid item container>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Növény
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Növény kód
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Hozam (t/ha)
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {yields.map((yieldData, y) => (
                                    <TableRow key={`plant-${y}`}>
                                        <TableCell
                                            style={{ fontSize: '13px', textAlign: 'center' }}
                                        >
                                            {yieldData.plantName}
                                        </TableCell>
                                        <TableCell
                                            style={{ fontSize: '13px', textAlign: 'center' }}
                                        >
                                            {yieldData.plantCode}
                                        </TableCell>
                                        <TableCell
                                            style={{ fontSize: '13px', textAlign: 'center' }}
                                        >
                                            {yieldData.yield}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Grid>
    );
}
