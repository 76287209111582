import { ekConstants } from '../constants';

const initialState = {
    ekCode: null,
    product: null,
};

export function ek(state = initialState, action) {
    switch (action.type) {
        case ekConstants.SET_EK:
            return {
                ekCode: action.payload.ekCode,
                product: null,
            };
        case ekConstants.SET_PRODUCT:
            return {
                ...state,
                product: action.payload.product,
            };
        default:
            return state;
    }
}
