export const woodwiserProjectActions = {
    setWoodwiserProject,
    SET_WOODWISER_PROJECT: 'SET_WOODWISER_PROJECT',
};

function setWoodwiserProject(woodwiserProject) {
    return {
        type: woodwiserProjectActions.SET_WOODWISER_PROJECT,
        payload: {
            woodwiserProject,
        },
    };
}
