import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { mapActions, policyActions, viewActions } from '../state/actions';
import { LAYERS } from 'shared/src/constants/layers';
import { VIEWS } from 'shared/src/constants/views';
import { policyService } from '../services';

class PolicyController extends React.Component {
    componentDidMount() {
        return this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.match.params.id !== prevProps.match.params.id ||
            this.props.location.search !== prevProps.location.search
        ) {
            return this.getData();
        }
    }

    async getData() {
        const {
            match: { params },
            location,
        } = this.props;

        this.props.dispatch(viewActions.setInfoPanelState({ open: true }));
        this.props.dispatch(viewActions.setViewState({ view: VIEWS.PROGRESS }));

        this.props.dispatch(mapActions.resetLayers());
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.COUNTRY,
                visible: true,
                fittingLayer: false,
            })
        );

        const policy = await policyService.getPolicy(params.id);
        let searchParams = new URLSearchParams(location.search);
        const policyPlotId = parseInt(searchParams.get('policyPlotId'), 10);

        this.props.dispatch(policyActions.setPolicy(policy));

        if (policy) {
            if (policyPlotId) {
                const policyPlot = policy.policyPlots.find(
                    (policyPlot) => policyPlot.id === policyPlotId
                );
                this.props.dispatch(policyActions.setPolicyPlot(policyPlot));
                if (policyPlot && policyPlot.meparPolygon && JSON.parse(policyPlot.meparPolygon)) {
                    this.props.dispatch(
                        mapActions.updateLayer({
                            type: LAYERS.TYPES.PLOT,
                            visible: true,
                            plots: [policyPlot],
                            policy,
                            fittingLayer: true,
                        })
                    );
                } else {
                    this.props.dispatch(
                        mapActions.updateLayer({ type: LAYERS.TYPES.PLOT, visible: false })
                    );
                    this.props.dispatch(
                        mapActions.updateLayer({
                            type: LAYERS.TYPES.COUNTRY,
                            visible: true,
                            fittingLayer: true,
                        })
                    );
                }
            } else {
                if (
                    policy.policyPlots.some(
                        (plot) => plot.meparPolygon && JSON.parse(plot.meparPolygon)
                    )
                ) {
                    this.props.dispatch(
                        mapActions.updateLayer({
                            type: LAYERS.TYPES.PLOT,
                            visible: true,
                            plots: policy.policyPlots,
                            policy,
                            fittingLayer: true,
                        })
                    );
                } else {
                    this.props.dispatch(
                        mapActions.updateLayer({ type: LAYERS.TYPES.PLOT, visible: false })
                    );
                    this.props.dispatch(
                        mapActions.updateLayer({
                            type: LAYERS.TYPES.COUNTRY,
                            visible: true,
                            fittingLayer: true,
                        })
                    );
                }
            }
        }

        if (policyPlotId) {
            this.props.dispatch(viewActions.setViewState({ view: VIEWS.POLICY_PLOT }));
        } else {
            this.props.dispatch(viewActions.setViewState({ view: VIEWS.POLICY }));
        }
    }

    render() {
        return null;
    }
}

export default connect()(withRouter(PolicyController));
