import { YIELDS } from 'shared/src/constants/yields';

/**
 * Creates items for yields menu
 *
 * @param {string} type - Type of yield: reference/real
 * @returns {Object[]} - List of menuitems with label and params for linkpath
 */
export default function SetYieldItems(type) {
    return Object.keys(YIELDS.PLANTS).map((p) => {
        return {
            label: YIELDS.PLANTS[p],
            params: [type, p],
        };
    });
}
