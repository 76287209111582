import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { mapActions, viewActions } from '../state/actions';
import { LAYERS } from 'shared/src/constants/layers';
import { VIEWS } from 'shared/src/constants/views';
import { layerService } from '../services';

class FrostDamageController extends React.Component {
    componentDidMount() {
        return this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id ||
            this.props.location.search !== prevProps.location.search) {
            return this.getData();
        }
    }

    async getData() {
        this.props.dispatch(viewActions.setInfoPanelState({ open: true }));
        this.props.dispatch(viewActions.setViewState({ view: VIEWS.PROGRESS }));

        this.props.dispatch(mapActions.resetLayers());

        const frostDamageChronology = await layerService.getFrostDamageChronology();

        this.props.dispatch(mapActions.updateLayer({ type: LAYERS.TYPES.FROST_DAMAGE, visible: true, fittingLayer: false }));
        this.props.dispatch(mapActions.updateLayer({ type: LAYERS.TYPES.FROST_DANGER, visible: true, fittingLayer: false }));
        this.props.dispatch(mapActions.updateLayer({ type: LAYERS.TYPES.FROST_SURFACE, visible: true, fittingLayer: false }));
        this.props.dispatch(mapActions.updateLayer({ type: LAYERS.TYPES.FROST_DAMAGE_CHRONOLOGY, visible: false, damageChronology: frostDamageChronology, fittingLayer: false }));

        this.props.dispatch(viewActions.setViewState({ view: VIEWS.FROST_DAMAGE }));
    }

    render() {
        return null;
    }
}

export default connect()(withRouter(FrostDamageController));
