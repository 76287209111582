import { List, ListItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button/Button';
import { ROUTES } from 'shared/src/constants/routes';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { policyActions } from '../../state/actions';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Left from '@material-ui/icons/ChevronLeft';
import Right from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton/IconButton';
import PolicyYieldsTable from './PolicyYieldsTable';

const styles = (theme) => ({
    progress: {
        margin: theme.spacing(2),
    },
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    navigationRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    navigationNumbers: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    blockButtonHighlighted: {
        backgroundColor: 'rgba(200,200,200,0.5)',
    },
    buttonLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonInfoRowLayout: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    buttonTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    buttonLabel: {
        fontSize: '12px',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: '12px',
    },
});

const pageLength = 5;

class PolicyPanel extends React.Component {
    state = {
        page: 1,
    };

    onPolicyPlotButtonMouseEnter = (policy, policyPlot) => () => {
        this.props.dispatch(policyActions.setPolicyPlot(policyPlot));
    };

    onPolicyPlotButtonMouseLeave = () => () => {
        this.props.dispatch(policyActions.setPolicyPlot(null));
    };

    onPolicyPlotButton = (policy, policyPlot) => () => {
        this.props.history.push(
            `${ROUTES.POLICY(policy.policyNumber)}?policyPlotId=${policyPlot.id}`
        );
    };

    onNavigateFirst = () => () => {
        this.setState({
            page: 1,
        });
    };

    onNavigatePrevious = () => () => {
        this.setState({
            page: this.state.page - 1,
        });
    };

    onNavigateNext = () => () => {
        this.setState({
            page: this.state.page + 1,
        });
    };

    onNavigateLast = (maxPage) => () => {
        this.setState({
            page: maxPage,
        });
    };

    render() {
        const { classes, policy, policyPlot } = this.props;
        const { page } = this.state;

        moment.locale('hu');

        if (!policy) {
            return (
                <List>
                    <ListItem>
                        <div className={classes.layout}>
                            <div className={classes.row}>
                                <Typography className={classes.label}>
                                    Nincs rendelkezésre álló adat!
                                </Typography>
                            </div>
                        </div>
                    </ListItem>
                </List>
            );
        }

        const policyPlotId = policyPlot && policyPlot.id;
        const maxPage = Math.ceil(policy.policyPlots.length / pageLength);

        return (
            <List>
                <ListItem>
                    <div className={classes.layout}>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Kötvényszám:</Typography>
                            <Typography className={classes.value}>{policy.policyNumber}</Typography>
                        </div>
                    </div>
                </ListItem>
                {policy.policyYields && policy.policyYields.length > 0 && (
                    <ListItem>
                        <PolicyYieldsTable yields={policy.policyYields} />
                    </ListItem>
                )}

                {policy.damages.map((damage) => (
                    <ListItem key={damage.id} className={classes.row}>
                        <Typography className={classes.label}>Kárszám:</Typography>
                        <Typography className={classes.value}>
                            <Link className={classes.value} to={ROUTES.DAMAGE(damage.damageNumber)}>
                                {damage.damageNumber}
                            </Link>
                        </Typography>
                    </ListItem>
                ))}
                <ListItem>
                    {policy.policyPlots.length > pageLength && (
                        <div className={classes.navigationRow}>
                            <IconButton onClick={this.onNavigateFirst()} disabled={page === 1}>
                                <FirstPage />
                            </IconButton>
                            <IconButton onClick={this.onNavigatePrevious()} disabled={page === 1}>
                                <Left />
                            </IconButton>

                            <div className={classes.navigationNumbers}>
                                <Typography className={classes.buttonTitle}>
                                    {(page - 1) * pageLength + 1} -{' '}
                                    {Math.min(page * pageLength, policy.policyPlots.length)} /{' '}
                                    {policy.policyPlots.length}
                                </Typography>
                            </div>
                            <IconButton onClick={this.onNavigateNext()} disabled={page === maxPage}>
                                <Right />
                            </IconButton>
                            <IconButton
                                onClick={this.onNavigateLast(maxPage)}
                                disabled={page === maxPage}
                            >
                                <LastPage />
                            </IconButton>
                        </div>
                    )}
                </ListItem>
                {policy.policyPlots
                    .filter(
                        (policyPlot, index) =>
                            index >= (page - 1) * pageLength && index < page * pageLength
                    )
                    .map((policyPlot) => (
                        <ListItem key={policyPlot.id}>
                            <Button
                                className={classNames(
                                    classes.button,
                                    policyPlot.id === policyPlotId
                                        ? classes.blockButtonHighlighted
                                        : null
                                )}
                                variant="contained"
                                color="default"
                                size="small"
                                onClick={this.onPolicyPlotButton(policy, policyPlot)}
                                onMouseEnter={this.onPolicyPlotButtonMouseEnter(policy, policyPlot)}
                                onMouseLeave={this.onPolicyPlotButtonMouseLeave()}
                            >
                                <div className={classes.buttonLayout}>
                                    <Typography className={classes.buttonTitle}>
                                        {`Tábla: ${policyPlot.meparCode} / T${policyPlot.id}`}
                                    </Typography>
                                    <div className={classes.buttonInfoRowLayout}>
                                        <Typography className={classes.buttonLabel}>
                                            {`${policyPlot.plantCode}`} -{' '}
                                            {`${policyPlot.insuredArea} ha`}
                                        </Typography>
                                    </div>
                                </div>
                            </Button>
                        </ListItem>
                    ))}
            </List>
        );
    }
}

const mapStateToProps = (state) => {
    const { policy, policyPlot } = state.policy;
    return {
        policy,
        policyPlot,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(PolicyPanel)));
