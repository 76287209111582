import { useContext, useEffect } from 'react';
import { GoogleMapContext } from '@googlemap-react/core';
import { useDispatch, useSelector } from 'react-redux';
import { mapActions, meparActions } from '../../../state/actions';
import _ from 'lodash';
import { toWgs84 } from 'reproject';
import epsg from 'epsg';

export default function DrawingManager() {
    const {
        state: { map },
    } = useContext(GoogleMapContext);
    const dispatch = useDispatch();
    const policyPlotToDraw = useSelector((state) => state.mepar.policyPlotToDraw);
    const drawingManager = useSelector((state) => state.map.drawingManager);

    useEffect(() => {
        if (!map) {
            return;
        }

        const drawingManager = new DrawingManagerCls(map, dispatch);
        dispatch(mapActions.setDrawingManager(drawingManager));
    }, [dispatch, map]);

    useEffect(() => {
        if (!drawingManager || !policyPlotToDraw) {
            return;
        }

        drawingManager.startDrawing(policyPlotToDraw);
    }, [policyPlotToDraw, drawingManager]);

    return null;
}

class DrawingManagerCls {
    drawingManager = null;
    map = null;
    polygon = null;
    dispatch = null;

    constructor(map, dispatch) {
        this.map = map;

        // eslint-disable-next-line no-undef
        const drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: false,
        });
        drawingManager.setMap(map);

        drawingManager.addListener('polygoncomplete', (polygon) => {
            polygon.setEditable(true);
            this.drawingManager.setDrawingMode(null);
            this.polygon = polygon;
            this.dispatch(meparActions.setEditedPolygon(polygon));
        });

        this.drawingManager = drawingManager;
        this.dispatch = dispatch;
    }

    startDrawing(policyPlot) {
        const drawingManager = this.drawingManager;

        if (policyPlot && policyPlot.tableGeometry) {
            const geom = toWgs84(policyPlot.tableGeometry, 'EPSG:23700', epsg);
            const coordinates = geom.coordinates;

            const paths = _.map(coordinates, function (entry) {
                return _.reduce(
                    entry,
                    function (list, point) {
                        // eslint-disable-next-line no-undef
                        list.push(new google.maps.LatLng(point[1], point[0]));
                        return list;
                    },
                    []
                );
            });

            // eslint-disable-next-line no-undef
            const polygon = new google.maps.Polygon({
                paths: paths,
                editable: true,
            });

            polygon.setMap(this.map);
            polygon.setEditable(true);
            drawingManager.setDrawingMode(null);
            this.polygon = polygon;
            this.dispatch(meparActions.setEditedPolygon(polygon));
        } else {
            // eslint-disable-next-line no-undef
            drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
        }
    }

    stopDrawing() {
        // eslint-disable-next-line no-undef
        if (this.polygon) {
            this.polygon.setMap(null);
            this.dispatch(meparActions.setEditedPolygon(null));
        }
        this.drawingManager.setDrawingMode(null);
    }
}
