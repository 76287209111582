import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@material-ui/core';
import { withTheme } from '@material-ui/styles';

const ConfirmModal = ({ title, message, onCancel, onConfirm, showModal, MODAL_TYPE }) => {
    return (
        <Dialog open={showModal}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Grid container mt={2}>
                    <Grid item xs={12}>
                        <Typography>{message}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="info" onClick={onCancel}>
                    Mégse
                </Button>

                <Button variant="contained" color="success" onClick={onConfirm}>
                    Megerősít
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withTheme(ConfirmModal);
