import { woodwiserForestUnitActions } from '../actions/woodwiserForestUnitActions';

const initialState = {
    woodwiserForestUnit: null,
};

export function woodwiserForestUnit(state = initialState, action) {
    switch (action.type) {
        case woodwiserForestUnitActions.SET_WOODWISER_FOREST_UNIT:
            return {
                woodwiserForestUnit: action.payload.woodwiserForestUnit,
            };
        default:
            return state;
    }
}
