import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { meparActions } from '../state/actions/meparActions';
import { useLocation, useRouteMatch } from 'react-router';
import { initialiseViewState } from './FarmerHomeController';
import { farmerModule } from 'shared/src/modules/farmerModule';

export default function FarmerBlockController() {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const mepars = useSelector((state) => state.mepar.mepars);
    const location = useLocation();

    useEffect(() => {
        if (mepars) {
            return;
        }

        dispatch(meparActions.requestFarmerMepars());
    }, [mepars, dispatch]);

    useEffect(() => {
        if (mepars && match && match.params && match.params.id) {
            const selectedMeparCode = match.params.id;
            let selectedMepar = selectedMeparCode
                ? mepars.find((mepar) => mepar.meparCode === selectedMeparCode)
                : null;

            let searchParams = new URLSearchParams(location.search);
            const view = searchParams.get('view');
            const date = searchParams.get('date');

            const viewState = view && farmerModule.parseViewState(view);
            const productDate = date && farmerModule.parseProductDate(date);

            initialiseViewState(dispatch, mepars, selectedMepar, viewState, productDate);
        }
    }, [match, mepars, dispatch, location]);

    return null;
}
