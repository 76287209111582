import { LAYERS } from 'shared';
import { Tile as TileLayer } from 'ol/layer';
import XYZSource from 'ol/source/XYZ';

export default class FrostDangerLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new TileLayer({
            title: 'Fagyveszély',
            type: LAYERS.TYPES.FROST_DANGER,
            visible: false,
            opacity: 0.65,
            displayInLayerSwitcher: true,

            source: new XYZSource({
                url: `https://generali.envimap.hu/content/tiles/frostMap/{z}/{x}/{y}.png`,
            }),
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        this.layer.setVisible(layer.visible);
    }
}