export const YIELDS = {
    PLANTS: {
        KALASZOS: 'Kalászosok',
        KUKORICA: 'Kukorica',
        NAPRAFORGO: 'Napraforgó',
        REPCE: 'Repce',
    },
    TYPES: { reference: 'Referencia', real: 'Számított' },
    SCALES: {
        Nuts4: 'kistérség',
        Mepar: 'MePAR',
    },
};