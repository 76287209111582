import { useContext, useEffect } from 'react';
import { GoogleMapContext } from '@googlemap-react/core';
import { useSelector } from 'react-redux';
import epsg from 'epsg';
import { mapHelper } from '../../../helpers/mapHelper';
import { toWgs84 } from 'reproject';
import { farmerModule } from 'shared/src/modules/farmerModule';

export default function ProductLayer() {
    const {
        state: { map },
    } = useContext(GoogleMapContext);
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const showNdviLayer = useSelector((state) => state.mepar.showNdviLayer);
    const productDate = useSelector((state) => state.mepar.productDate);

    useEffect(() => {
        function createLayer(map, selectedMepar) {
            const products = farmerModule.getMeparNdvisForDate(selectedMepar, productDate);

            if (!products || products.length === 0) {
                return;
            }

            // we remove drawing requirement for now
            if (
                true /*farmerModule.meparDateHasAlert(selectedMepar, productDate) || farmerModule.meparHasSomeTableGeometries(selectedMepar)*/
            ) {
                products.forEach((product) => {
                    const rasterMapType = new ProductMapType(selectedMepar.geom, product, map);
                    map.overlayMapTypes.insertAt(0, rasterMapType);
                });

                return;
            }
        }

        if (!map) {
            return;
        }

        map.overlayMapTypes.clear();

        if (!selectedMepar || !productDate) {
            return;
        }

        if (!showNdviLayer) {
            return;
        }

        createLayer(map, selectedMepar);
    }, [map, selectedMepar, showNdviLayer, productDate]);

    return null;
}

class ProductMapType {
    geometry = null;
    product = null;
    map = null;
    // eslint-disable-next-line no-undef
    tileSize = new google.maps.Size(256, 256);

    constructor(geometry, product, map) {
        this.geometry = geometry;
        this.product = product;
        this.map = map;
    }

    getTile(coord, zoom, ownerDocument) {
        const map = this.map;
        const polygonJson = toWgs84(this.geometry, 'EPSG:23700', epsg);
        const productUrl = this.product.url;
        const url = mapHelper.getTileUrl(coord, zoom, productUrl);

        const polygonArray = polygonJson.coordinates.map((items) =>
            items.map((item) => ({ lng: item[0], lat: item[1] }))
        );
        // eslint-disable-next-line no-undef
        const polygon = new google.maps.Data.Polygon(polygonArray);

        const scale = Math.pow(2, zoom);

        if (coord.y < 0 || coord.y >= scale) return ownerDocument.createElement('div');

        const image = new Image();
        image.src = url;

        const canvas = ownerDocument.createElement('canvas');
        canvas.width = this.tileSize.width;
        canvas.height = this.tileSize.height;
        const context = canvas.getContext('2d');

        const xdif = coord.x * this.tileSize.width;
        const ydif = coord.y * this.tileSize.height;

        const ring = polygon.getArray()[0];
        const points = ring.getArray().map((x) => {
            const worldPoint = map.getProjection().fromLatLngToPoint(x);
            // eslint-disable-next-line no-undef
            return new google.maps.Point(worldPoint.x * scale - xdif, worldPoint.y * scale - ydif);
        });

        image.onload = () => {
            context.beginPath();
            context.moveTo(points[0].x, points[0].y);
            const count = points.length;
            for (var i = 0; i < count; i++) {
                context.lineTo(points[i].x, points[i].y);
            }
            context.lineTo(points[count - 1].x, points[count - 1].y);

            context.clip();
            context.drawImage(image, 0, 0);
            context.closePath();
        };

        return canvas;
    }
}
