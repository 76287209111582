import { connect } from 'react-redux';
import SamplingPanel from './SamplingPanel';

const mapStateToProps = (state) => {
    const { damage, damagePlot, productType, product } = state.damage;
    const { drawing } = state.drawing;
    return {
        damage,
        damagePlot,
        productType,
        product,
        drawing,
        layers: state.map.layers,
    };
};

export default connect(mapStateToProps)(SamplingPanel);