import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { store } from '../state/store';
import Routes from './Routes';
import StyledApp from '../styles/StyledApp';
import { ModalContextProvider, Modals } from '../hooks/useModal';

export default function App() {
    return (
        <Provider store={store}>
            <StyledApp>
                <ModalContextProvider>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale="hu">
                        <CssBaseline />
                        <BrowserRouter>
                            <Routes />
                            <Modals />
                        </BrowserRouter>
                    </MuiPickersUtilsProvider>
                </ModalContextProvider>
            </StyledApp>
        </Provider>
    );
}
