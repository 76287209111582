import { viewConstants } from '../constants';
import { FARMER_VIEWS } from 'shared';

const initialState = {
    farmerViewState: FARMER_VIEWS.NDVI,
    viewState: null,
    infoPanelState: null,
};

export function view(state = initialState, action) {
    switch (action.type) {
        case viewConstants.SET_VIEW_STATE:
            return {
                ...state,
                viewState: action.payload.viewState,
            };
        case viewConstants.SET_FARMER_VIEW_STATE:
            return {
                ...state,
                farmerViewState: action.payload.viewState,
            };
        case viewConstants.SET_INFO_PANEL_STATE:
            return {
                ...state,
                infoPanelState: action.payload.infoPanelState,
            };
        default:
            return state;
    }
}
