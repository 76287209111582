import { BaseActions } from './baseActions';
import { logService } from '../../services/logService';
import { LOGS } from 'shared';

export const dialogConstants = {
    SHOW_POLICY_PLOT_DIALOG: 'DIALOG_SHOW_POLICY_PLOT_DIALOG',
};

class DialogActions extends BaseActions {
    showPolicyPlotsDialog() {
        logService
            .saveLog(LOGS.TYPES.SHOW_POLICY_PLOT_DIALOG, null)
            .catch((error) => console.log(error));
        return this.action(dialogConstants.SHOW_POLICY_PLOT_DIALOG, { show: true });
    }

    hidePolicyPlotsDialog() {
        logService
            .saveLog(LOGS.TYPES.HIDE_POLICY_PLOT_DIALOG, null)
            .catch((error) => console.log(error));
        return this.action(dialogConstants.SHOW_POLICY_PLOT_DIALOG, { show: false });
    }
}

export const dialogActions = new DialogActions();
