import { damagePlotAreaConstants } from '../constants';

const initialState = {
    damagePlotAreas: null,
};

export function damagePlotAreas(state = initialState, action) {
    switch (action.type) {
        case damagePlotAreaConstants.SET_DAMAGEPLOTAREAS:
            return {
                damagePlotAreas: action.payload.damagePlotAreas,
            };
        case damagePlotAreaConstants.SAVE_DAMAGEPLOTAREA:
            const areas = state.damagePlotAreas;
            const area = action.payload.area;
            area.color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            return {
                damagePlotAreas: [...areas.filter((a) => a.id !== area.id), area],
            };
        case damagePlotAreaConstants.REMOVE_DAMAGEPLOTAREA:
            const ar = state.damagePlotAreas;
            return {
                damagePlotAreas: [...ar.filter((a) => a.id != action.payload.id)],
            };
        default:
            return state;
    }
}
