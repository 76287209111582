export const cemeteryActions = {
    setGraveyard,
    setGraves,
    setSelectedDeads,
    setSelectedGraves,
    SET_GRAVEYARD: 'CEMETERY_SET_GRAVEYARD',
    SET_GRAVES: 'CEMETERY_SET_GRAVES',
    SET_SELECTED_DEADS: 'CEMETERY_SET_SELECTED_DEADS',
    SET_SELECTED_GRAVES: 'CEMETERY_SET_SELECTED_GRAVES',
};

function setGraveyard(graveyard) {
    return {
        type: cemeteryActions.SET_GRAVEYARD,
        payload: {
            graveyard,
        },
    };
}

function setGraves(graves) {
    return {
        type: cemeteryActions.SET_GRAVES,
        payload: {
            graves,
        },
    };
}

function setSelectedDeads(deads) {
    return {
        type: cemeteryActions.SET_SELECTED_DEADS,
        payload: {
            deads,
        },
    };
}

function setSelectedGraves(graves) {
    return {
        type: cemeteryActions.SET_SELECTED_GRAVES,
        payload: {
            graves,
        },
    };
}
