export default async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 60000 } = options;

    const controller = new AbortController();
    const timer = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
        ...options,
        signal: controller.signal
    });
    clearTimeout(timer);
    return response;
}