import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { damageService } from '../services/damageService';
import { damageActions } from '../state/actions/damageActions';
import { damagePlotAreaActions } from '../state/actions/damagePlotAreaActions';
import { mapActions, viewActions } from '../state/actions';
import { LAYERS } from 'shared/src/constants/layers';
import { VIEWS } from 'shared/src/constants/views';

class DamageController extends React.Component {
    componentDidMount() {
        return this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.match.params.id !== prevProps.match.params.id ||
            this.props.location.search !== prevProps.location.search
        ) {
            return this.getData();
        }
    }

    async getData() {
        const {
            match: { params },
            location,
        } = this.props;

        this.props.dispatch(viewActions.setInfoPanelState({ open: true }));
        this.props.dispatch(viewActions.setViewState({ view: VIEWS.PROGRESS }));

        let searchParams = new URLSearchParams(location.search);
        const damagePlotId = parseInt(searchParams.get('damagePlotId'), 10);
        const productType = searchParams.get('productType');
        const manageDrawingsParam = searchParams.get('manageDrawings');
        const manageSamplingsParam = searchParams.get('manageSamplings');
        const manageDrawings = manageDrawingsParam === 'true';
        const manageSamplings = manageSamplingsParam === 'true';

        if (!manageDrawings) {
            this.props.dispatch(mapActions.resetLayers());
        }

        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.COUNTRY,
                visible: true,
                fittingLayer: false,
            })
        );

        const damage = await damageService.getDamage(params.id);

        if (!damage) {
            return;
        }

        if (damage.damagePhotos.length > 0) {
            if (!manageSamplings && !manageDrawings) {
            this.props.dispatch(
                mapActions.updateLayer({
                    type: LAYERS.TYPES.KARNAVI_PHOTOS,
                    visible: true,
                    photos: damage.damagePhotos,
                    fittingLayer: true,
                })
            );
            } else {
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.KARNAVI_PHOTOS,
                        visible: false,
                    })
                );
            }
        }

        if (damagePlotId) {
            const damagePlotSummary = damage.damagePlots.find(
                (damagePlot) => damagePlot.id === damagePlotId
            );
            const damagePlot = await damageService.getDamagePlot(
                damagePlotSummary.id,
                manageDrawings
            );
            const damagePlotAreas = await damageService.getDamagePlotAreas(damagePlotId);

            if (damagePlotAreas) {
                damagePlotAreas.forEach(
                    (area) => (area.color = `#${Math.floor(Math.random() * 16777215).toString(16)}`)
                );
            }

            this.props.dispatch(damagePlotAreaActions.setDamagePlotAreas(damagePlotAreas));
            this.props.dispatch(
                damageActions.setDamage(
                    damage,
                    damagePlot,
                    productType,
                    manageDrawings,
                    manageSamplings
                )
            );

            if (JSON.parse(damagePlot.meparPolygon)) {
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.PLOT,
                        visible: true,
                        plots: damage.damagePlots,
                        damagePlotAreas,
                        damage,
                        damagePlot,
                        fittingLayer: true,
                    })
                );
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.DRAWING,
                        visible: manageDrawings,
                        fittingLayer: false,
                    })
                );
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.SAMPLING,
                        visible: manageSamplings,
                        damage,
                        damagePlot,
                        fittingLayer: false,
                    })
                );
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.SAMPLING_ZONE_RASTER,
                        visible: manageSamplings,
                        damage,
                        damagePlot,
                        fittingLayer: false,
                    })
                );
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.SAMPLING_ZONE_VECTOR,
                        visible: manageSamplings,
                        damage,
                        damagePlot,
                        fittingLayer: false,
                    })
                );
            } else {
                this.props.dispatch(
                    mapActions.updateLayer({ type: LAYERS.TYPES.PLOT, visible: false })
                );
                this.props.dispatch(
                    mapActions.updateLayer({ type: LAYERS.TYPES.SAMPLING, visible: false })
                );
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.SAMPLING_ZONE_RASTER,
                        visible: false,
                    })
                );
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.SAMPLING_ZONE_VECTOR,
                        visible: false,
                    })
                );
                this.props.dispatch(
                    mapActions.updateLayer({ type: LAYERS.TYPES.DRAWING, visible: false })
                );
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.COUNTRY,
                        visible: true,
                        fittingLayer: true,
                    })
                );
            }

            if (productType) {
                const product = damagePlot.products.filter(
                    (product) => product.type === productType
                )[0];
                this.props.dispatch(damageActions.setProduct(product));
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.PRODUCT,
                        visible: true,
                        product,
                        damagePlot,
                    })
                );
            }
        } else {
            this.props.dispatch(damageActions.setDamage(damage));
            if (damage.damagePlots.some((plot) => JSON.parse(plot.meparPolygon))) {
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.PLOT,
                        visible: true,
                        plots: damage.damagePlots,
                        damage,
                        fittingLayer: true,
                    })
                );
            } else {
                this.props.dispatch(
                    mapActions.updateLayer({ type: LAYERS.TYPES.PLOT, visible: false })
                );
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.COUNTRY,
                        visible: true,
                        fittingLayer: true,
                    })
                );
            }
        }

        if (!damagePlotId) {
            this.props.dispatch(viewActions.setViewState({ view: VIEWS.DAMAGE }));
            return;
        }

        if (manageDrawings) {
            this.props.dispatch(viewActions.setViewState({ view: VIEWS.DRAWING }));
            return;
        }

        if (manageSamplings) {
            this.props.dispatch(viewActions.setViewState({ view: VIEWS.SAMPLING }));
            return;
        }

        if (productType) {
            this.props.dispatch(viewActions.setViewState({ view: VIEWS.PRODUCT }));
            return;
        }

        this.props.dispatch(viewActions.setViewState({ view: VIEWS.DAMAGE_PLOT }));
    }

    render() {
        return null;
    }
}

export default connect()(withRouter(DamageController));
