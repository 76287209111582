import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dialogActions } from '../../../state/actions/dialogActions';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SnackbarContent from '@material-ui/core/SnackbarContent/SnackbarContent';
import InfoTooltip from '../../common/InfoTooltip';

const useStyles = makeStyles((theme) => ({
    layout: {
        position: 'absolute',
        top: '10px',
        left: '5px',
        right: '5px',
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            right: 'auto',
            transform: 'translateX(-50%)',
        },
        '&.MuiSnackbarContent-root': {
            backgroundColor: '#ff9800',
        },
    },
    tooltip: {
        backgroundColor: '#333333',
        color: 'rgba(255, 255, 255, 1)',
        fontSize: '18px',
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
}));

export default function MissingPlotMessage() {
    const classes = useStyles();
    const [drawingNeeded, setDrawingNeeded] = useState(false);
    const [isDrawingNeededMessageOpen, setIsDrawingNeededMessageOpen] = useState(false);
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const policyPlotToDraw = useSelector((state) => state.mepar.policyPlotToDraw);

    const dispatch = useDispatch();

    useEffect(() => {
        // we remove drawing requirement for now
        // const drawingNeeded = selectedMepar && !farmerModule.meparHasAllTableGeometries(selectedMepar);
        setDrawingNeeded(false /*drawingNeeded*/);
    }, [selectedMepar, policyPlotToDraw]);

    useEffect(() => {
        if (policyPlotToDraw) {
            setIsDrawingNeededMessageOpen(false);
            return;
        }

        if (drawingNeeded) {
            setIsDrawingNeededMessageOpen(true);
            return;
        }

        setIsDrawingNeededMessageOpen(false);
    }, [drawingNeeded, policyPlotToDraw]);

    function handlePlotDialogOpen() {
        dispatch(dialogActions.showPolicyPlotsDialog());
    }

    if (!isDrawingNeededMessageOpen) {
        return null;
    }

    return (
        <SnackbarContent
            message={'Hiányzó táblahatár!'}
            action={
                <>
                    <Button color="inherit" size="small" onClick={handlePlotDialogOpen}>
                        {'Megrajzolom'}
                    </Button>
                    <InfoTooltip
                        title={`A riasztási szolgáltatás lokalizálásához és a biomassza intenzitási (NDVI) térképek folyamatos előállításához tudnunk kell, hogy a MePAR
                blokkon belül hol helyezkednek el a biztosított táblái.`}
                    />
                </>
            }
            className={classes.layout}
        />
    );
}
