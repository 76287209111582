import { Divider, List, ListItem, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ROUTES } from 'shared';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import clsx from 'clsx';

moment.locale('hu');

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),
    },
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    navigationRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    navigationNumbers: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    blockButtonHighlighted: {
        backgroundColor: 'rgba(200,200,200,0.5)',
    },
    buttonLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonInfoRowLayout: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    buttonTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    buttonLabel: {
        fontSize: '12px',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: '12px',
    },
    productListWrapper: {
        flex: 1,
        overflow: 'auto',
    },
    productList: {},
    expansion: {
        width: '100%',
    },
    list: {
        paddingTop: '0px',
    },
    listItem: {
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    panelLayout: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    description: {
        fontSize: '14px',
        paddingBottom: '10px',
    },
    drawingButton: {
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
    },
    productRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    dividerTop: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    dividerBottom: {
        marginTop: '10px',
    },
    listItemPadding: {
        paddingTop: '10px'
    }
}));

export default function FrostDamagePanel() {
    const damages = useSelector(state => state.frostDamage.damages);
    const danger = useSelector(state => state.frostDamage.danger);
    const mepar = useSelector(state => state.frostDamage.mepar);
    const classes = useStyles();

    return (
        <List className={classes.layout}>
            <ListItem>
                <div className={classes.layout}>
                    A Fagykár térkép azt mutatja meg, hogy mely Generali által biztosított területen fordult elő tavaszi fagykár. A térképen a MePAR blokkra
                    kattintva, láthatjuk a MePAR-hoz tartozó kárakta legfőbb adatait. A keresőmezőben közvetlenül is kereshetünk a MePAR blokk nevére.
                    <br/>
                    <br/>
                    A Fagyveszély térkép azt mutatja meg, hogy az egyes régiókban mekkora a fagyveszély mértéke.
                    <br/>
                    <br/>
                    Az alaptérkép színei a fagyveszélyesség mértékét fejezik ki.
                </div>
                <Divider/>
            </ListItem>

            <ListItem className={classes.listItem}>
                <img src={'/artifacts/Frost/FrostMap_Legend.jpg'} width={'290px'} alt={''}/>
            </ListItem>
            <br/>
            <br/>
            {mepar &&
                <>
                    <ListItem className={classes.listItem}>
                        <div className={classes.layout}>
                            <div className={classes.row}>
                                <Typography className={classes.label}>
                                    Mepar blokk:
                                </Typography>
                                <Typography className={classes.value}>
                                    {mepar.meparCode}
                                </Typography>
                            </div>
                            <div className={classes.row}>
                                <Typography className={classes.label}>
                                    Fagyveszély:
                                </Typography>
                                <Typography className={classes.value}>
                                    {danger}
                                </Typography>
                            </div>
                        </div>
                    </ListItem>
                    <br/>
                    <Divider/>
                    <br/>
                    {damages && damages.map(damage => (
                        <ListItem key={damage.id} className={clsx(classes.listItem, classes.listItemPadding)}>
                            <div className={classes.layout}>
                                <div>
                                    <div className={classes.row}>
                                        <Typography className={classes.label}>
                                            Kárakta:
                                        </Typography>
                                        <Typography className={classes.value}>
                                            <Link className={classes.value} to={ROUTES.DAMAGE(damage.damageNumber)}>{damage.damageNumber}</Link>
                                        </Typography>
                                    </div>
                                    <div className={classes.row}>
                                        <Typography className={classes.label}>
                                            Kár típusa:
                                        </Typography>
                                        <Typography className={classes.value}>
                                            {damage.dangerType}
                                        </Typography>
                                    </div>
                                    <div className={classes.row}>
                                        <Typography className={classes.label}>
                                            Kár. terület:
                                        </Typography>
                                        <Typography className={classes.value}>
                                            {damage.damagedArea} ha
                                        </Typography>
                                    </div>
                                    <div className={classes.row}>
                                        <Typography className={classes.label}>
                                            Kifizetés:
                                        </Typography>
                                        <Typography className={classes.value}>
                                            {numeral(damage.damageAmount).format('0,0').replace(/,/g, ' ')} Ft
                                        </Typography>
                                    </div>
                                    <div className={classes.row}>
                                        <Typography className={classes.label}>
                                            Kárszázalék:
                                        </Typography>
                                        <Typography className={classes.value}>
                                            {numeral(damage.damageProportion).format('0')}%
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </ListItem>
                    ))}
                </>
            }

        </List>
    );
}
