import { LAYERS } from 'shared';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import { Fill, Stroke, Style } from 'ol/style';
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import GeoJSON from 'ol/format/GeoJSON';
import { bbox } from 'ol/loadingstrategy';
import { cemeteryService } from '../../services/cemeteryService';
import Point from 'ol/geom/Point';
import Circle from 'ol/geom/Circle';
import LineString from 'ol/geom/LineString';
import RegularShape from 'ol/style/RegularShape';

export default class CemeteryObjectLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    getGeometryFromGeom(geom, cemeteryObject) {
        switch (geom.type) {
            case 'Polygon':
                return new Polygon(geom.coordinates);
            case 'Point':
                if (cemeteryObject.symbol === 'triangle') {
                    return new Point(geom.coordinates);
                } else {
                    return new Circle(geom.coordinates, cemeteryObject.size);
                }
            //return new Point();
            case 'MultiPolygon':
                return new MultiPolygon(geom.coordinates);
            case 'LineString':
                return new LineString(geom.coordinates);
            default:
                return null;
        }
    }

    init() {
        const self = this;
        const vectorSource = new VectorSource({
            format: new GeoJSON(),
            loader: async (extent) => {
                const extentPolygon = fromExtent(extent);
                const cemeteryObjects = await cemeteryService.getCemeteryObjects(extentPolygon);
                cemeteryObjects.forEach((cemeteryObject) => {
                    const cemeteryObjectGeom = cemeteryObject.geom;
                    const feature = new Feature({
                        geometry: self.getGeometryFromGeom(cemeteryObjectGeom, cemeteryObject),
                        cemeteryObject,
                    });
                    vectorSource.addFeature(feature);
                });
            },
            strategy: bbox,
        });

        this.layer = new VectorLayer({
            title: 'Környezeti elemek',
            type: LAYERS.TYPES.CEMETERY_OBJECT,
            visible: false,
            displayInLayerSwitcher: true,
            renderMode: 'vector',
            source: vectorSource,
            style: (feature) => {
                const cemeteryObject = feature.get('cemeteryObject');

                if (!cemeteryObject.color) {
                    return null;
                }

                switch (cemeteryObject.geomType) {
                    case 'polygon':
                        return new Style({
                            fill: new Fill({
                                color: cemeteryObject.color,
                            }),
                        });
                    case 'polyline':
                        switch (cemeteryObject.symbol) {
                            case 'solid':
                                return new Style({
                                    stroke: new Stroke({
                                        color: cemeteryObject.color,
                                        width: 2,
                                    }),
                                });
                            case 'dash':
                                return new Style({
                                    stroke: new Stroke({
                                        color: cemeteryObject.color,
                                        width: 2,
                                        lineDash: [5, 5],
                                    }),
                                });
                            default:
                                return null;
                        }
                    case 'point':
                        switch (cemeteryObject.symbol) {
                            case 'circle':
                                return new Style({
                                    stroke: new Stroke({
                                        color: 'rgba(0,0,0,1)',
                                        width: 1,
                                    }),
                                    fill: new Fill({
                                        color: cemeteryObject.color,
                                    }),
                                });
                            case 'triangle':
                                return new Style({
                                    image: new RegularShape({
                                        fill: new Fill({
                                            color: cemeteryObject.color,
                                        }),
                                        stroke: new Stroke({
                                            color: 'rgba(0,0,0,1)',
                                            width: 1,
                                        }),
                                        points: 3,
                                        radius: 10,
                                        rotation: Math.PI / 4,
                                        angle: 0,
                                    }),
                                });
                            default:
                                return null;
                        }
                    default:
                        return null;
                }
            },
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {}
}
