export const ROLE = {
    API: 1,
    ADMIN: 2,
    MANAGER: 3,
    EXPERT_DAMAGE: 4,
    EXPERT_UW: 5,
    FARMER: 6,
    WOODWISER: 7,
    OPERATOR: 8,
    PROKAT: 9,
    AGRARMONITOR: 10,
    AGRO: 11,
    CEMETERY: 12,
    CEMETERY_PUBLIC: 13,
    EXPERT_RISK_TAKING: 14,
};

export function hasRiskTakingRole(user) {
    if (user.role === ROLE.ADMIN || user.role === ROLE.EXPERT_RISK_TAKING) {
        return true;
    }

    if (user.companyId === 4 && user.role === ROLE.MANAGER) {
        return true;
    }

    const userNames = [
        'y078923',
        'y079084',
        'y085820',
        'Y0A2544',
        'y080431',
        'y095454',
        'RGS003',
        'RGS001',
        'y017584',
        'y011218',
        'sarkany.zoltan.autotal',
        'fodor.akos.autotal'];

    if (userNames.includes(user.userName)) {
        return true;
    }

    return false;
}
