import { LAYERS } from 'shared';
import TileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps.js';

export default class BaseLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new TileLayer({
            title: 'Alaptérkép',
            type: LAYERS.TYPES.BASE,
            visible: true,
            source: new BingMaps({
                key: 'AkeEA2smohBmDAMR5JG4WOP2xCSL2VIAqufB5Yz71BdMd8IwakpqUMjnHrY00VsE',
                imagerySet: 'Aerial',
                // use maxZoom 19 to see stretched tiles instead of the BingMaps
                // "no photos at this zoom level" tiles
                // maxZoom: 19
            }),
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        this.layer.setVisible(layer.visible);
    }
}
