import React, { useCallback, useContext, useState } from 'react';
import SamplingModal from '../components/modals/SamplingModal';
import StatusModal from '../components/modals/StatusModal';
import ConfirmModal from '../components/modals/ConfirmModal';

export const MODAL_TYPE = {
    CONFIRM: 'confirm',
    SAMPLING: 'sampling',
    STATUS: 'status',
    NONE: false,
};

const ModalContext = React.createContext();

export function ModalContextProvider({ children }) {
    const [modalState, setModalState] = useState({
        currentlyShowingModal: false, // name should be false
        props: {},
    });

    const showModal = useCallback(
        (currentlyShowingModal, newprops = {}) => {
            setModalState({
                currentlyShowingModal,
                props: newprops,
            });
        },
        [setModalState]
    );
    // TODO: the next line might need some work so the object is memoized
    return (
        <ModalContext.Provider value={{ ...modalState, showModal }}>
            {children}
        </ModalContext.Provider>
    );
}
ModalContext.displayName = 'ModalContext';

export function Modals() {
    return (
        <ModalContext.Consumer>
            {(context) => {
                const allProps = {
                    ...context.props,
                    currentlyShowingModal: context.currentlyShowingModal,
                    showModal: context.showModal,
                    MODAL_TYPE,
                };
                return (
                    <div>
                        {context.currentlyShowingModal === MODAL_TYPE.CONFIRM &&
                            <ConfirmModal {...allProps} />}
                        {context.currentlyShowingModal === MODAL_TYPE.SAMPLING &&
                            <SamplingModal {...allProps} />}
                        {context.currentlyShowingModal === MODAL_TYPE.STATUS &&
                            <StatusModal {...allProps} />}
                    </div>
                );
            }}
        </ModalContext.Consumer>
    );
}

export function useModals() {
    return useContext(ModalContext);
}
