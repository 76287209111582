import React from 'react';
import { Divider, Grid, List, ListItem, makeStyles, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import moment from 'moment';
import { YIELDS } from 'shared/src/constants/yields';

moment.locale('hu');

const useStyles = makeStyles((theme) => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
}));

export default function YieldsPanel() {
    const classes = useStyles();
    const { type, plant } = useParams();

    return (
        <List className={classes.layout}>
            <ListItem>
                <div className={classes.title}>
                    <Typography>{YIELDS.TYPES[type]} hozamtérkép</Typography>
                    {YIELDS.PLANTS[plant]}
                </div>
                <Divider />
            </ListItem>
            <ListItem>
                <Grid container id="yieldsLegend"></Grid>
            </ListItem>
        </List>
    );
}
