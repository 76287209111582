import { Fill, Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { LAYERS } from 'shared';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import CircleStyle from 'ol/style/Circle';
import Geolocation from 'ol/Geolocation.js';

export default class PositionLayer {
    map = null;
    source = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        const geolocation = new Geolocation({
            trackingOptions: {
                enableHighAccuracy: true,
            },
            projection: this.map.map.getView().getProjection(),
        });

        geolocation.setTracking(true);

        const accuracyFeature = new Feature();
        geolocation.on('change:accuracyGeometry', function () {
            accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
        });

        const positionFeature = new Feature();
        positionFeature.setStyle(
            new Style({
                image: new CircleStyle({
                    radius: 6,
                    fill: new Fill({
                        color: '#3399CC',
                    }),
                    stroke: new Stroke({
                        color: '#fff',
                        width: 2,
                    }),
                }),
            })
        );

        geolocation.on('change:position', function () {
            const coordinates = geolocation.getPosition();
            positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
        });

        this.source = new VectorSource({
            features: [accuracyFeature, positionFeature],
        });

        this.layer = new VectorLayer({
            title: 'Aktuális pozíció',
            type: LAYERS.TYPES.POSITION,
            visible: window.positionLayerVisible,
            source: this.source,
            displayInLayerSwitcher: true,
        });

        this.layer.on('change:visible', (event) => {
            window.positionLayerVisible = event.target.getVisible();
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {}
}
