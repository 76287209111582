import { LAYERS, PRODUCTS } from 'shared';
import { Tile as TileLayer } from 'ol/layer';
import TileWMS from 'ol/source/TileWMS';
import { ROLE } from 'shared/src/constants/role';
import VectorLayer from 'ol/layer/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Polygon from 'ol/geom/Polygon';

export default class ProductLayer {
    constructor(map, user) {
        this.map = map;
        this.init(user);
    }

    init(user) {
        this.layerTile = new TileLayer({
            title: 'Terméktérkép',
            type: LAYERS.TYPES.PRODUCT,
            visible: false,
            displayInLayerSwitcher: user.role !== ROLE.FARMER,
        });
        this.layerVector = new VectorLayer({
            title: 'Riasztás térkép',
            type: LAYERS.TYPES.PRODUCT,
            visible: false,
            displayInLayerSwitcher: false,
            style: new Style({
                stroke: new Stroke({
                    color: 'red',
                    width: 2,
                }),
                fill: new Fill({
                    color: 'rgba(255, 0, 0, 0.1)',
                }),
            }),
        });
    }

    getLayer() {
        return [this.layerTile, this.layerVector];
    }

    update(layer) {
        if (layer.product !== this.layerTile.get('product') && layer.visible) {
            if (layer.product.type === PRODUCTS.TYPES.ALERT) {
                const productSource = new VectorSource();

                const polygon = JSON.parse(layer.product.productPolygon);
                const feature = new Feature({
                    geometry:
                        polygon.type === 'Polygon'
                            ? new Polygon(polygon.coordinates)
                            : new MultiPolygon(polygon.coordinates),
                });
                feature.setStyle(
                    new Style({
                        stroke: new Stroke({
                            color: 'red',
                            width: 2,
                        }),
                        fill: new Fill({
                            color: 'rgba(255, 0, 0, 0.4)',
                        }),
                    })
                );
                productSource.addFeature(feature);

                this.layerVector.setSource(productSource);

                this.layerTile.setVisible(false);
                this.layerVector.setVisible(true);
            } else {
                const match = layer.product.url.match('^([^:]*):(.*)$');
                if (!match) {
                    console.log('Match failure', layer);
                    return;
                }

                const group = match[1];

                const urlParam = group;
                const productSource = new TileWMS({
                    url: `/geoserver/${urlParam}/wms?service=WMS`,
                    crossOrigin: 'anonymous',
                    params: {
                        LAYERS: layer.product.url,
                        FORMAT: 'image/png',
                    },
                });
                this.layerTile.setSource(productSource);
                this.layerTile.setVisible(true);
                this.layerVector.setVisible(false);
            }

            this.layerTile.set('product', layer.product);
        }

        if (!layer.visible) {
            this.layerTile.setVisible(false);
            this.layerVector.setVisible(false);
        }
    }
}
