import { LAYERS, ROUTES } from 'shared';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Select from 'ol/interaction/Select';
import { agroModule } from 'shared/src/modules/agroModule';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { click, pointerMove } from 'ol/events/condition';

export default class BlockPlotLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new VectorLayer({
            title: 'Tábla',
            type: LAYERS.TYPES.BLOCK_PLOT,
            visible: false,
            displayInLayerSwitcher: true,
            declutter: true,
            source: new VectorSource(),
            style: (feature) => {
                const selectedBlockPlot = this.layer.get('blockPlot');
                const blockPlot = feature.get('blockPlot');
                const selectedFeature = selectedBlockPlot && selectedBlockPlot.id === blockPlot.id;
                const text = selectedFeature ? '' : this.getFeatureText(feature);

                return new Style({
                    stroke: new Stroke({
                        color: selectedFeature ? 'green' : 'blue',
                        width: selectedFeature ? 3 : 2,
                    }),
                    fill: new Fill({
                        color: 'rgba(0,0,0,0.00000001)',
                    }),
                    text: new Text({
                        font: '14px Calibri,sans-serif',
                        fill: new Fill({ color: '#000' }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 2,
                        }),
                        text: text,
                    }),
                });
            },
        });

        this.initInteractions();
        this.addInteractions();
    }

    initInteractions() {
        this.blockPlotLayerPointerSelect = new Select({
            condition: pointerMove,
            style: (feature) => {
                const isBlockPlot = Boolean(feature.get('blockPlot'));
                if (isBlockPlot) {
                    const selectedBlockPlot = this.layer.get('blockPlot');
                    const blockPlot = feature.get('blockPlot');
                    const selectedFeature =
                        selectedBlockPlot && selectedBlockPlot.id === blockPlot.id;
                    const text = selectedFeature ? '' : this.getFeatureText(feature);

                    return new Style({
                        stroke: new Stroke({
                            color: selectedFeature ? 'green' : 'orange',
                            width: selectedFeature ? 3 : 2,
                        }),
                        text: new Text({
                            font: '14px Calibri,sans-serif',
                            fill: new Fill({ color: '#000' }),
                            stroke: new Stroke({
                                color: '#fff',
                                width: 2,
                            }),
                            text: text,
                        }),
                    });
                }
            },
            layers: [this.layer],
        });

        this.blockPlotLayerClickSelect = new Select({
            condition: click,
            style: new Style({}),

            layers: [this.layer],
        });

        this.blockPlotLayerClickSelect.on('select', (event) => {
            const features = event.target.getFeatures();
            features.forEach((feature) => {
                const blockPlot = feature.get('blockPlot');

                if (blockPlot) {
                    this.map.props.history.push(ROUTES.BLOCK_PLOT(blockPlot.id));
                }
            });
        });
    }

    addInteractions() {
        this.removeInteractions();
        this.map.map.addInteraction(this.blockPlotLayerPointerSelect);
        this.map.map.addInteraction(this.blockPlotLayerClickSelect);
    }

    removeInteractions() {
        this.map.map.removeInteraction(this.blockPlotLayerPointerSelect);
        this.map.map.removeInteraction(this.blockPlotLayerClickSelect);
    }

    getFeatureText(feature) {
        const blockPlot = feature.get('blockPlot');
        const mepar = feature.get('mepar');
        if (blockPlot) {
            return blockPlot.name;
        }

        return mepar.meparCode;
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        let blockPlotSource = null;
        let fitSource = null;

        if (layer.mepars !== this.layer.get('mepars') && layer.visible) {
            const mepars = layer.mepars;
            const blockPlots = agroModule.getBlockPlotsFromMepars(mepars);

            blockPlots.forEach((blockPlot) => {
                if (blockPlot.tableGeometry) {
                    const polygon = blockPlot.tableGeometry;
                    if (polygon) {
                        if (!blockPlotSource) {
                            blockPlotSource = new VectorSource();
                        }

                        const feature = new Feature({
                            geometry:
                                polygon.type === 'Polygon'
                                    ? new Polygon(polygon.coordinates)
                                    : new MultiPolygon(polygon.coordinates),
                            blockPlot,
                        });

                        blockPlotSource.addFeature(feature);
                    }
                }
            });

            if (blockPlotSource) {
                this.layer.setSource(blockPlotSource);
                this.layer.set('mepars', mepars);
            }
        }

        if (layer.blockPlot && layer.blockPlot !== this.layer.get('blockPlot') && layer.visible) {
            const mepars = layer.mepars;
            const blockPlots = agroModule.getBlockPlotsFromMepars(mepars);
            const selectedBlockPlot = layer.blockPlot;

            blockPlots.forEach((blockPlot) => {
                if (selectedBlockPlot && blockPlot.id === selectedBlockPlot.id) {
                    if (!fitSource) {
                        fitSource = new VectorSource();
                    }
                    if (blockPlot.tableGeometry) {
                        const polygon = blockPlot.tableGeometry;
                        if (polygon) {
                            const feature = new Feature({
                                geometry:
                                    polygon.type === 'Polygon'
                                        ? new Polygon(polygon.coordinates)
                                        : new MultiPolygon(polygon.coordinates),
                                blockPlot,
                            });

                            fitSource.addFeature(feature);
                        }
                    }
                }
            });
        }

        this.layer.set('blockPlot', layer.blockPlot);

        if (layer.fittingLayer && (fitSource || blockPlotSource) && layer.visible) {
            this.map.map
                .getView()
                .fit(
                    fitSource ? fitSource.getExtent() : blockPlotSource.getExtent(),
                    this.map.map.getSize()
                );
        }

        this.layer.setVisible(layer.visible);
    }
}
