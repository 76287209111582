import { mapConstants } from '../constants';
import { LAYERS } from 'shared/src/constants/layers';

const initialState = {
    infoWindow: null,
    drawingManager: null,
    layers: [
        {
            type: LAYERS.TYPES.BASE,
            visible: true,
        },
        {
            type: LAYERS.TYPES.STREET,
            visible: false,
        },
        {
            type: LAYERS.TYPES.PRODUCT,
            visible: false,
        },
        {
            type: LAYERS.TYPES.RADAR,
            visible: false,
        },
        {
            type: LAYERS.TYPES.CROP,
            visible: false,
        },
        {
            type: LAYERS.TYPES.PLOT,
            visible: false,
        },
        {
            type: LAYERS.TYPES.SAMPLING,
            visible: false,
        },
        {
            type: LAYERS.TYPES.SAMPLING_ZONE_RASTER,
            visible: false,
        },
        {
            type: LAYERS.TYPES.SAMPLING_ZONE_VECTOR,
            visible: false,
        },
        {
            type: LAYERS.TYPES.POSITION,
            visible: window.positionLayerVisible || false,
        },
        {
            type: LAYERS.TYPES.DRAWING,
            visible: false,
        },
        {
            type: LAYERS.TYPES.COUNTRY,
            visible: true,
            fittingLayer: true,
        },
        {
            type: LAYERS.TYPES.CITY,
            visible: true,
        },
        {
            type: LAYERS.TYPES.WOODWISER_FOREST_UNIT,
            visible: true,
        },
        {
            type: LAYERS.TYPES.WOODWISER_DTM,
            visible: false,
        },
        {
            type: LAYERS.TYPES.WOODWISER_HEIGHT,
            visible: false,
        },
        {
            type: LAYERS.TYPES.WOODWISER_ASPECT,
            visible: false,
        },
        {
            type: LAYERS.TYPES.WOODWISER_SLOPE,
            visible: false,
        },
        {
            type: LAYERS.TYPES.WOODWISER_RGB,
            visible: true,
        },
        {
            type: LAYERS.TYPES.WOODWISER_STEM,
            visible: false,
        },
        {
            type: LAYERS.TYPES.WOODWISER_CROWN,
            visible: false,
        },
        {
            type: LAYERS.TYPES.PROKAT_SOIL_ZONE_GENETIC,
            visible: false,
        },
        {
            type: LAYERS.TYPES.PROKAT_SOIL_ZONE_PHYSICAL,
            visible: false,
        },
        {
            type: LAYERS.TYPES.PROKAT_SOIL_SAMPLE,
            visible: false,
        },
        {
            type: LAYERS.TYPES.PROKAT_SOIL_SAMPLE_MEPAR,
            visible: false,
        },
        {
            type: LAYERS.TYPES.PROKAT_DTM,
            visible: false,
        },
        {
            type: LAYERS.TYPES.PROKAT_NDVI,
            visible: false,
        },
        {
            type: LAYERS.TYPES.PROKAT_SOIL_MANAGEMENT_ZONE,
            visible: false,
        },
        {
            type: LAYERS.TYPES.EK,
            visible: true,
        },
        {
            type: LAYERS.TYPES.EK_PRODUCT,
            visible: false,
        },
        {
            type: LAYERS.TYPES.MEPAR,
            visible: false,
        },
        {
            type: LAYERS.TYPES.BLOCK_PLOT,
            visible: false,
        },
        {
            type: LAYERS.TYPES.BLOCK_PLOT_PRODUCT,
            visible: false,
        },
        {
            type: LAYERS.TYPES.BLOCK_PLOT_SOIL_MANAGEMENT_ZONE,
            visible: false,
        },
        {
            type: LAYERS.TYPES.CEMETERY_UAV,
            visible: false,
        },
        {
            type: LAYERS.TYPES.CEMETERY_GRAVE,
            visible: false,
        },
        {
            type: LAYERS.TYPES.CEMETERY_OBJECT,
            visible: false,
        },
        {
            type: LAYERS.TYPES.DAMAGE_CHRONOLOGY,
            visible: false,
        },
        {
            type: LAYERS.TYPES.KARNAVI_PHOTOS,
            visible: false,
        },
        {
            type: LAYERS.TYPES.YIELD,
            visible: false,
        },
        {
            type: LAYERS.TYPES.FROST_DAMAGE,
            visible: false,
        },
        {
            type: LAYERS.TYPES.FROST_DANGER,
            visible: false,
        },
        {
            type: LAYERS.TYPES.FROST_SURFACE,
            visible: false,
        },
        {
            type: LAYERS.TYPES.FROST_DAMAGE_CHRONOLOGY,
            visible: false,
        },
    ],
};

export function map(state = initialState, action) {
    switch (action.type) {
        case mapConstants.SET_DRAWING_MANAGER:
            return {
                ...state,
                drawingManager: action.payload.drawingManager,
            };
        case mapConstants.SET_INFO_WINDOW:
            return {
                ...state,
                infoWindow: action.payload.infoWindow,
            };
        case mapConstants.RESET_LAYERS:
            return {
                ...state,
                layers: initialState.layers,
            };
        case mapConstants.SHOW_LAYER:
            const layers = state.layers.map((layer) =>
                layer.type === action.payload.layer.type ? action.payload.layer : layer
            );
            return {
                ...state,
                layers,
            };
        default:
            return state;
    }
}
