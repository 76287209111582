export const damageService = {
    getDamage,
    getDamagePlot,
    getDamagePlotAreas,
    saveDamagePlotArea,
    saveMeparAsArea,
    removeDamagePlotArea,
    saveDamagePlotOutline,
    createDamage,
    createDamageFromExcel,
};

async function getDamage(id) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await fetch(`/api/damages/${encodeURIComponent(id)}`, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function getDamagePlot(id, getDrawings = false) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await fetch(
        `/api/damagePlots/${encodeURIComponent(id)}?getDrawings=${encodeURIComponent(getDrawings)}`,
        options
    );

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function getDamagePlotAreas(damagePlotId) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await fetch(
        `/api/damagePlots/${encodeURIComponent(damagePlotId)}/areas`,
        options
    );

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function saveDamagePlotArea(damagePlotId, geom, area, id) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            geom,
            area,
        }),
    };

    const URI = id
        ? `/api/damagePlots/${damagePlotId}/areas/save/${id}`
        : `/api/damagePlots/${damagePlotId}/areas/save`;

    const response = await fetch(URI, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function saveMeparAsArea(damagePlotId, area, previousAreas) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            area,
            previousAreas,
        }),
    };

    const URI = `/api/damagePlots/${damagePlotId}/areas/saveMeparAsArea`;
    const response = await fetch(URI, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function removeDamagePlotArea(id, damagePlotId) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await fetch(`/api/damagePlots/${damagePlotId}/areas/remove/${id}`, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function saveDamagePlotOutline(damagePlotId, tablePolygon) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            damagePlotId,
            tablePolygon,
        }),
    };

    const response = await fetch(`/api/drawings`, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function createDamage(damage) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ damage }),
    };

    const response = await fetch(`/api/createDamage`, options);

    if (!response.ok) {
        console.log(response);
        throw new Error('Hiba történt a mentéskor, próbálja újra!');
    }

    return response.json();
}

async function createDamageFromExcel(excelFile) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    let formData = new FormData();

    formData.append('excelFile', excelFile);

    const options = {
        method: 'POST',
        headers: {
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
        body: formData,
    };

    console.log(excelFile);

    const response = await fetch(`/api/createDamageFromExcel`, options);

    console.log(response);

    if (!response.ok) {
        console.log(response);
        const responseBody = await response.json();
        throw new Error(`Hiba történt a mentéskor, próbálja újra! ${responseBody.error}`);
    }

    return response.json();
}
