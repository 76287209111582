import { Divider, List, ListItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button/Button';
import { ROUTES } from 'shared/src/constants/routes';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { ROLE } from 'shared';
import PolicyYieldsTable from './PolicyYieldsTable';

const styles = (theme) => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    blockButtonHighlighted: {
        backgroundColor: 'rgba(170,170,170,1)',
    },
    buttonLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonInfoRowLayout: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    buttonTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    groupTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    buttonLabel: {
        fontSize: '12px',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: '12px',
    },
    divider: {
        marginTop: '10px',
    },
    groupTitleRow: {
        paddingBottom: '0px',
    },
});

class DamagePanel extends React.Component {
    onBlockButton = (damage, damagePlot) => () => {
        this.props.history.push(
            `${ROUTES.DAMAGE(damage.damageNumber)}?damagePlotId=${damagePlot.id}`
        );
    };

    render() {
        const { classes, user, damage, highlightedDamagePlot, highlightedDamageMepar } = this.props;
        moment.locale('hu');

        if (!damage) {
            return null;
        }

        return (
            <List>
                <ListItem>
                    <div className={classes.layout}>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Kárszám:</Typography>
                            <Typography className={classes.value}>{damage.damageNumber}</Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Kötvényszám:</Typography>
                            <Typography className={classes.value}>
                                {user.role === ROLE.EXPERT_DAMAGE && <>{damage.policyNumber}</>}
                                {user.role !== ROLE.EXPERT_DAMAGE && (
                                    <Link
                                        className={classes.value}
                                        to={ROUTES.POLICY(damage.policyNumber)}
                                    >
                                        {damage.policyNumber}
                                    </Link>
                                )}
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Kár dátuma:</Typography>
                            <Typography className={classes.value}>
                                {moment(damage.damageDate).format('YYYY. MM. DD.')}
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Kár. terület:</Typography>
                            <Typography className={classes.value}>{`${damage.area} ha`}</Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Kártípus:</Typography>
                            <Typography className={classes.value}>{damage.dangerType}</Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Kárszemle:</Typography>
                            <Typography className={classes.label}>
                                <a
                                    className={classes.value}
                                    href={`http://maps.google.com/maps?q=${encodeURIComponent(
                                        damage.inspectionAddress
                                    )}`}
                                >
                                    {damage.inspectionAddress}
                                </a>
                            </Typography>
                        </div>
                    </div>
                    <Divider />
                </ListItem>
                {damage.policyYields && damage.policyYields.length > 0 && (
                    <ListItem>
                        <PolicyYieldsTable yields={damage.policyYields} />
                    </ListItem>
                )}
                {damage.damagePlots.map((damagePlot, index) => (
                    <div key={damagePlot.id}>
                        {(index === 0 ||
                            damage.damagePlots[index - 1].meparCode !== damagePlot.meparCode) && (
                            <>
                                <Divider className={classes.divider} />
                                <ListItem className={classes.groupTitleRow}>
                                    <div className={classes.row}>
                                        <Typography className={classes.label}>MePAR:</Typography>
                                        <Typography className={classes.value}>
                                            <Link
                                                className={classes.value}
                                                to={ROUTES.BLOCK(damagePlot.meparCode)}
                                            >
                                                {damagePlot.meparCode}
                                            </Link>
                                        </Typography>
                                    </div>
                                </ListItem>
                            </>
                        )}
                        <ListItem>
                            <Button
                                className={classNames(
                                    classes.button,
                                    (highlightedDamagePlot &&
                                        highlightedDamagePlot.id === damagePlot.id) ||
                                        (highlightedDamageMepar &&
                                            highlightedDamageMepar === damagePlot.meparCode)
                                        ? classes.blockButtonHighlighted
                                        : null
                                )}
                                variant="contained"
                                color="default"
                                size="small"
                                onClick={this.onBlockButton(damage, damagePlot)}
                            >
                                <div className={classes.buttonLayout}>
                                    <Typography className={classes.buttonTitle}>
                                        {`${damagePlot.plantCode}`} -{' '}
                                        {`${damagePlot.damagedArea} ha`}
                                        {/*{`Tábla: ${damagePlot.meparCode} / T${damagePlot.id}`}*/}
                                    </Typography>
                                    {/*
                                    <div className={classes.buttonInfoRowLayout}>
                                        <Typography className={classes.buttonLabel}>
                                            {`${damagePlot.plantCode}`} - {`${damagePlot.damagedArea} ha`}
                                        </Typography>
                                    </div>
*/}
                                </div>
                            </Button>
                        </ListItem>
                    </div>
                ))}
            </List>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.user;
    const { damage, highlightedDamagePlot, highlightedDamageMepar } = state.damage;
    return {
        user,
        damage,
        highlightedDamagePlot,
        highlightedDamageMepar,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(DamagePanel)));
