export const DASHBOARD = {
    CATEGORIES: [
        {
            LABEL: 'Kötvények',
            CATEGORIES: [
                {
                    LABEL: 'Biztosított terület',
                    CATEGORIES: [
                        {
                            LABEL: 'Országos vetéstérkép növénykategóriáinak területmérete (ha)',
                            SQL: `select "category", "value"
                                  from "Dashboard"
                                  where type = 'cropmap'
                                    and date = (select max(date) from "Dashboard" where type = 'cropmap')
                                  order by "value" DESC
                            `,
                        },
                        {
                            LABEL: 'Idei év összes kötvényének főnövényei és azok területmérete (ha)',
                            SQL: `select "category", "value"
                                  from "Dashboard"
                                  where type = 'policy-plantcategory'
                                    and date = (select max(date) from "Dashboard" where type = 'policy-plantcategory')
                                  order by "value" DESC
                            `,
                        },
                        {
                            LABEL: 'Idei év összes kötvényének összes növénye és azok területmérete (ha)',
                            SQL: `select "category", "value" from "Dashboard" where type='policy-plantname' and date = (select max(date) from "Dashboard" where type='policy-plantname')
                                  order by "value" desc
                            `,
                        },
                    ],
                },
                {
                    LABEL: 'Riasztás szolgáltatás',
                    CATEGORIES: [
                        {
                            LABEL: 'Ügyfelelek száma (db)',
                            SQL: `select "value"
                                  from "Dashboard"
                                  where type = 'alert-farmerusercount'
                                    and date = (select max(date) from "Dashboard" where type = 'alert-farmerusercount')
                            `,
                        },
                        {
                            LABEL: 'Területméret növényeként (ha)',
                            SQL: `select "PolicyPlot"."plantName", sum("PolicyPlot"."insuredArea") as "insuredArea"
                                  from "Policy",
                                       "PolicyPlot"
                                  where "Policy"."id" = "PolicyPlot"."policyId"
                                    and "Policy"."updatedAt"::date = (select max("Policy"."updatedAt"::date) from "Policy")
                                    and "farmerUserId" in (select distinct "farmerUserId"
                                                           from "Policy"
                                                           where "Policy"."updatedAt"::date = (select max("Policy"."updatedAt"::date) from "Policy")
                                                             and not "farmerUserId" in (62)
                                                             and not "farmerUserId" is null
                                                           order by "farmerUserId"
                                  )
                                  group by "PolicyPlot"."plantName"
                                  order by "insuredArea" desc
                            `,
                        },
                    ],
                },
                {
                    LABEL: 'Viharesemény érintettség',
                    CATEGORIES: [
                        {
                            LABEL: 'Vihareseménnyel érintett biztosított növények és azok területmérete (ha)',
                            SQL: `select "plantName", sum("insuredArea") as "insuredArea"
                                  from "PolicyPlot",
                                       "Policy"
                                  where "Policy"."updatedAt"::date = (select max("Policy"."updatedAt"::date) from "Policy")
                                    and "PolicyPlot"."policyId" = "Policy".id
                                    and "meparCode" in (
                                      select m."meparCode"
                                      from (
                                               select "meparCode", "geom"
                                               from "Mepar"
                                               where "status" = 'checked'
                                                 and "meparCode" in (select distinct "meparCode"
                                                                     from "PolicyPlot",
                                                                          "Policy"
                                                                     where "Policy"."updatedAt"::date = (select max("Policy"."updatedAt"::date) from "Policy")
                                                                       and "PolicyPlot"."policyId" = "Policy".id)) as m,
                                           (select "geom" from "Radar" where "dateRadar" = current_date - 1) as r
                                      where ST_Intersects(m."geom", r."geom")
                                  )
                                  group by "plantName"
                                  order by "insuredArea" DESC
                            `,
                        },
                        {
                            LABEL: 'Vihareseménnyel érintett biztosított táblák MePAR blokkjainak száma (db)',
                            SQL: `
                                select "plantName", count(distinct "meparCode") as "cnt"
                                from "PolicyPlot",
                                     "Policy"
                                where "Policy"."updatedAt"::date = (select max("Policy"."updatedAt"::date) from "Policy")
                                  and "PolicyPlot"."policyId" = "Policy".id
                                  and "meparCode" in (
                                    select m."meparCode"
                                    from (
                                             select "meparCode", "geom"
                                             from "Mepar"
                                             where "status" = 'checked'
                                               and "meparCode" in (select distinct "meparCode"
                                                                   from "PolicyPlot",
                                                                        "Policy"
                                                                   where "Policy"."updatedAt"::date = (select max("Policy"."updatedAt"::date) from "Policy")
                                                                     and "PolicyPlot"."policyId" = "Policy".id)) as m,
                                         (select "geom" from "Radar" where "dateRadar" = current_date - 1) as r
                                    where ST_Intersects(m."geom", r."geom")
                                )
                                group by "plantName"
                                order by "cnt" DESC
                            `,
                        },
                    ],
                },
            ],
        },
        {
            LABEL: 'Káresetek',
            CATEGORIES: [
                {
                    LABEL: 'Áttekintő',
                    CATEGORIES: [
                        {
                            LABEL: 'Lezárt kárakták száma (db)',
                            SQL: `
                                select sum("Lezart") as "Biztosan_lezart"
                                from (select "damageNumber",
                                             count(distinct "damageNumber" = data ->> 'karszam') filter (where "damageNumber" = data ->> 'karszam' and
                                                                                                               (type = 'A_MG_e_JKV_4' or
                                                                                                                (upper(data ->> 'tovabbi_szemle') = 'NEM' and type = 'A_MG_e_JKV_2'))) as "Lezart"
                                      from "Damage"
                                               left join "DamageInspectionDocument" on "damageNumber" = data ->> 'karszam'
                                      where year = (select max("year") from "Damage")
                                        and length("damageNumber") = 11
                                      group by "damageNumber") m
                            `,
                        },
                        {
                            LABEL: 'Aktív kárakták száma (db)',
                            SQL: `
                                select count(*) as "Osszes_karakta"
                                from (select "damageNumber",
                                             count(distinct "damageNumber" = data ->> 'karszam') filter (where "damageNumber" = data ->> 'karszam' and
                                                                                                               (type = 'A_MG_e_JKV_4' or
                                                                                                                (upper(data ->> 'tovabbi_szemle') = 'NEM' and type = 'A_MG_e_JKV_2'))) as "Lezart"
                                      from "Damage"
                                               left join "DamageInspectionDocument" on "damageNumber" = data ->> 'karszam'
                                      where year = (select max("year") from "Damage")
                                        and length("damageNumber") = 11
                                      group by "damageNumber") m
                            `,
                        },
                        {
                            LABEL: 'Idei összes kifizetési javaslat összege (ft)',
                            SQL: `
                                select sum("kifizetes")
                                from (select distinct data ->> 'karszam' as "karszam", (data ->> 'karterites_javaslat')::numeric as "kifizetes"
                                      from "DamageInspectionDocument"
                                      where upper(data ->> 'kifizetest_ker') = 'IGEN'
                                        and (data ->> 'karterites_javaslat')::numeric > 0) m
                            `,
                        },
                        {
                            LABEL: 'Idei kártartalék összege (ft)',
                            SQL: `
                                select sum(reserve) from "DamageInspection", "Damage"
                                where "DamageInspection"."damageId" = "Damage".id and "Damage".year = (select max(year) from "DamageInspection", "Damage")                            `,
                        },
                    ],
                },
                {
                    LABEL: 'Károsodott növények',
                    CATEGORIES: [
                        {
                            LABEL: 'Károsodott biztosított növények káraktáinak száma (db) és területmérete (ha)',
                            SQL: `
                                select LOWER("DamagePlot"."plantCode") as "plantCode", count(distinct "Damage"."damageNumber"), sum("area") as "area"
                                from "Damage",
                                     "DamagePlot"
                                where "Damage".id = "DamagePlot"."damageId"
                                  and "year" = (select max("year") from "Damage")
                                  and not "DamagePlot"."plantCode" is null
                                  and length("DamagePlot"."plantCode") > 0
                                group by LOWER("DamagePlot"."plantCode")
                                order by "area" DESC
                            `,
                        },
                    ],
                },
                {
                    LABEL: 'Kártérítési javaslatok',
                    CATEGORIES: [
                        {
                            LABEL: 'Kártérítési javaslatok növénybontásban (ft)',
                            SQL: `
                                select LOWER("noveny_nev") as "plantName", sum("kifizetes") as "kifizetes"
                                from (select distinct data ->> 'karszam'                        as "karszam",
                                                      upper(trim(data ->> 'noveny_nev'))        as "noveny_nev",
                                                      (data ->> 'karterites_javaslat')::numeric as "kifizetes"
                                      from "DamageInspectionDocument"
                                      where type = 'A_MG_e_JKV_4') m
                                group by LOWER("noveny_nev")
                                order by "kifizetes" DESC
                            `,
                        },
                    ],
                },
                {
                    LABEL: 'Károk',
                    CATEGORIES: [
                        {
                            LABEL: 'Kárakták száma (db) és területmérete (ha) károkonként',
                            SQL: `
                                select "Damage"."dangerType", count(distinct "Damage"."damageNumber"), round(sum("area")::numeric, 2) as "area"
                                from "Damage",
                                     "DamagePlot"
                                where "Damage".id = "DamagePlot"."damageId"
                                  and "year" = (select max("year") from "Damage")
                                  and not "DamagePlot"."plantCode" is null
                                  and length("DamagePlot"."plantCode") > 0
                                  and not "Damage"."dangerType" = 'Teszt kár'  
                                group by "Damage"."dangerType"
                                order by "area" DESC
                            `,
                        },
                    ],
                },
            ],
        },
        {
            LABEL: 'Kárszakértők',
            CATEGORIES: [
                {
                    LABEL: 'Kárszakértők - Kárakták',
                    CATEGORIES: [
                        {
                            LABEL: 'Kárszakértők káraktáinak (db), károsodott tábláinak (db), megrajzolt tábláinak (db) száma és károsodott területeinek mérete (ha)',
                            SQL: `
                                select "User"."name", m."DamageCount", m."DamagePlotCount", m."PlotCount", "DamagedArea"
                                from (select "Damage"."expertId",
                                             count(distinct "Damage"."damageNumber")                                        as "DamageCount",
                                             count("DamagePlot".id)                                                         as "DamagePlotCount",
                                             count("DamagePlot".id) FILTER (where not "DamagePlot"."tableGeometry" is null) as "PlotCount",
                                             round(sum("area")::numeric, 2)                                                 as "DamagedArea"
                                      from "Damage",
                                           "DamagePlot"
                                      where "Damage".id = "DamagePlot"."damageId"
                                        and "year" = (select max("year") from "Damage")
                                        and not "DamagePlot"."plantCode" is null
                                        and length("DamagePlot"."plantCode") > 0
                                      group by "Damage"."expertId"
                                      order by "Damage"."expertId") m
                                         left join "User" on "User"."externalId" = "m"."expertId"
                                where not "User"."name" = 'Demo felhasználó'
                                order by "User".name
                            `,
                        },
                        {
                            LABEL: 'Kárszakértők aktív és lezárt káraktáinak száma (db)',
                            SQL: `

                                select COALESCE("szakerto", 'Nincs megadva'), count(*) filter (where "Lezart" = 0) as "Nyitott", count(*) filter (where "Lezart" = 1) as "Lezart"
                                from (select "damageNumber",
                                             data ->> 'szakerto'                                                                                                                       as "szakerto",
                                             count(distinct "damageNumber" = data ->> 'karszam') filter (where "damageNumber" = data ->> 'karszam' and
                                                                                                               (type = 'A_MG_e_JKV_4' or
                                                                                                                (upper(data ->> 'tovabbi_szemle') = 'NEM' and type = 'A_MG_e_JKV_2'))) as "Lezart"
                                      from "Damage"
                                               left join "DamageInspectionDocument" on "damageNumber" = data ->> 'karszam'
                                      where year = (select max("year") from "Damage")
                                        and length("damageNumber") = 11
                                      group by "damageNumber", data ->> 'szakerto' ) m
                                    group by "szakerto"
                            `,
                        },
                        {
                            LABEL: 'Kárszakértők által vett mintavételek száma (db)',
                            SQL: `
                                select "User"."name", sum("sampling_count") from
                                    (select "damageNumber", "Damage"."expertId", count(*) as "sampling_count" from "Damage"
                                                                                                                       left join "Sampling" on "Damage"."id" = "Sampling"."damageId"
                                     where year = (select max("year") from "Damage") and length("damageNumber") = 11 and not yield is null
                                     group by "damageNumber", "Damage"."expertId") m

                                        left join "User" on "expertId" = "User"."externalId"
                                group by "User"."name"
                            `,
                        },
                    ],
                },
                {
                    LABEL: 'Kárszakértői aktivitás',
                    CATEGORIES: [
                        {
                            LABEL: 'GeoRisk felületen történt aktivitás mértéke legutóbbi héten, legutóbbi hónapban és az elmúlt egy évben összesen (db)',
                            SQL: `
                                select i."user", "last_week", "last_month", "last_year" from (select "Company"."name" as "company", "User"."name" as "user" from "User", "Company"
                                                                                                          where "Company".id = "User"."companyId" and not "User"."id" in (3,6) and "Company"."name" in ('Autotal', 'GPC')) i
                                                                                                             left join
                                                                                                         (select "User"."name" as "user", count(*) filter(where "Log"."createdAt" > current_date - 7) as "last_week", count(*) filter(where "Log"."createdAt" > current_date - 30) as "last_month", count(*) filter(where "Log"."createdAt" > current_date - 365) as "last_year" from "Log", "User"
                                                                                                          where "User".id = "Log"."userId"
                                                                                                          group by "User".name) m
                                                                                                         on "m".user = i."user"
                                order by "user"
                            `,
                        },
                        {
                            LABEL: 'Kárszakértők által megtekintett károk száma (db)',
                            SQL: `
                                select i."user", COALESCE(count, 0) from (select "Company"."name" as "company", "User"."name" as "user" from "User", "Company"
                                                                          where "Company".id = "User"."companyId" and not "User"."id" in (3,6) and "Company"."name" in ('Autotal', 'GPC')) i
                                                                             left join
                                                                         (select distinct "User"."name" as "user", count(distinct "requestParams" ->> 'damageNumber') from "Log", "User"
                                                                          where "User".id = "Log"."userId" and "requestParams" ->> 'damageNumber' is not null
                                                                          group by "User".name) m
                                                                         on "m".user = i."user"
                                order by "user"

                            `,
                        },
                    ],
                },
            ],
        },
        /*{
            LABEL: 'Kárakták',
            CATEGORIES: [
                {
                    CATEGORIES: [
                        {
                            FILTER: {
                                LABEL: 'Kárakta száma:',
                                PROPERTY: 'damageNumber',
                            },
                            SQL: `
                                select "damageNumber", "User"."name", "damageDate"::date, area, "dangerType", "policyNumber", "inspectionAddress" from  "Damage"
                                    left join "User" on "User"."externalId" = "Damage"."expertId"
                                where "damageNumber" = 'GP210268152'`,
                        },
                    ],
                },
            ],
        },*/
    ],
};
