import { LAYERS } from 'shared';
import { Fill, Stroke, Style, Text } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { bbox } from 'ol/loadingstrategy';
import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import { layerService } from '../../services';
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';

export default class CityLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        const vectorSource = new VectorSource({
            format: new GeoJSON(),
            loader: async (extent) => {
                const extentPolygon = fromExtent(extent);
                const response = await layerService.getCity(extentPolygon);
                response.forEach((city) => {
                    const cityPolygon = JSON.parse(city.cityPolygon);
                    const feature = new Feature({
                        geometry:
                            cityPolygon.type === 'Polygon'
                                ? new Polygon(cityPolygon.coordinates)
                                : new MultiPolygon(cityPolygon.coordinates),
                        name: city.name,
                    });
                    vectorSource.addFeature(feature);
                });
            },
            strategy: bbox,
        });

        this.layer = new VectorLayer({
            title: `Település`,
            type: LAYERS.TYPES.CITY,
            visible: true,
            renderMode: 'vector',
            declutter: true,
            source: vectorSource,
            maxResolution: 25,
            style: (feature) => {
                const name = feature.get('name');
                return new Style({
                    stroke: new Stroke({
                        color: 'white',
                        width: 1,
                    }),
                    text: new Text({
                        font: '14px sans-serif',
                        overflow: true,
                        placement: 'center',
                        fill: new Fill({
                            color: 'white',
                        }),
                        text: name,
                    }),
                });
            },
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        this.layer.setVisible(layer.visible);
    }
}
