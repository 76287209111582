export const cemeteryService = {
    getGraveyard,
    getGraves,
    getCemeteryObjects,
};

async function getGraveyard() {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await fetch(`/api/cemetery/graveyards`, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function getGraves() {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await fetch(`/api/cemetery/graves`, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}

async function getCemeteryObjects(extent) {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const extentPolygon = { type: 'Polygon', coordinates: extent.getCoordinates() };
    const extentPolygonString = JSON.stringify(extentPolygon);

    const response = await fetch(
        `/api/cemetery/objects?extent=${encodeURIComponent(extentPolygonString)}`,
        options
    );

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}
