import { blockConstants } from '../constants';
import { BaseActions } from './baseActions';

class BlockActions extends BaseActions {
    setBlock(block, productType, manageDrawings = false) {
        return {
            type: blockConstants.SET_BLOCK,
            payload: {
                block,
                productType,
                manageDrawings,
            },
        };
    }

    setProduct(product) {
        return {
            type: blockConstants.SET_PRODUCT,
            payload: {
                product,
            },
        };
    }
}

export const blockActions = new BlockActions();
