import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField/TextField';
import classNames from 'classnames';
import SecondaryScreen from '../components/screens/SecondaryScreen';
import { withRouter } from 'react-router';
import { screens } from '../styles/styles';
import { FieldArray, Formik } from 'formik';
import Button from '@material-ui/core/Button/Button';
import { connect } from 'react-redux';
import 'moment/locale/hu';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import { damageService } from '../services/damageService';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const styles = (theme) => ({
    layout: {
        minWidth: '400px',
        maxWidth: '400px',
        padding: '30px',
        marginTop: '30px',
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: '100%',
            maxWidth: '100%',
            flex: 1,
            padding: '30px',
            marginTop: '0px',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    controlGroup: {
        display: 'flex',
        flexDirection: 'column',
    },
    error: {
        color: 'rgba(255,0,0,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
        paddingBottom: '15px',
    },
    text: {
        color: 'rgba(50,50,50,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
    },
    checkLabel: {
        color: 'rgba(142,142,147,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
    },
    textField: {
        marginTop: '11px',
        '&:not(:last-child)': {
            marginBottom: '30px',
        },
    },
    saveButton: {
        marginTop: '30px',
        marginBottom: '50px',
        minHeight: '60px',
        maxHeight: '60px',
        width: '100%',
        borderRadius: '4px',
        /*
        backgroundColor: 'rgba(0,84,255,1)',
        '&:hover': {
            backgroundColor: '#004EEE',
        },
*/
        boxShadow: '0 2px 4px 0 rgba(5,33,81,0.07)',
        /*
        color: theme.palette.text.primaryInverse,
*/
        fontSize: '18px',
        textTransform: 'none',
    },
    passwordButton: {
        marginTop: '30px',
        minHeight: '60px',
        maxHeight: '60px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        },
        boxShadow: '0 2px 4px 0 rgba(5,33,81,0.07)',
        color: theme.palette.text.primaryInverse,
        fontSize: '18px',
        textTransform: 'none',
    },
    blockTitleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    removeButton: {
        margin: '2px',
        color: 'red',
    },
    blockTitle: {
        fontSize: '16px',
    },
    uploadBlock: {
        marginBottom: '20px',
    },
    uploadBlockDivider: {
        marginBottom: '20px',
    },
});

class CreateDamage extends React.Component {
    state = {
        error: null,
    };

    validate = (values) => {
        const errors = {};

        const requiredFields = [
            'damageNumber',
            'policyNumber',
            'damageDate',
            'area',
            'dangerType',
            'inspectionAddress',
            'inspectionAddressZip',
        ];

        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field] = 'Kötelező kitölteni';
            }
        });

        values.blocks.forEach((block, index) => {
            const requiredBlockFields = ['mepar', 'area', 'plants'];

            requiredBlockFields.forEach((field) => {
                if (!values.blocks[index][field]) {
                    this.createBlockError(errors, index, field, 'Kötelező kitölteni');
                }

                if (values.blocks[index][field]) {
                    const mepar = values.blocks[index]['mepar'];
                    if (!/^[A-Z0-9]{8}$/.test(mepar)) {
                        this.createBlockError(
                            errors,
                            index,
                            'mepar',
                            'A mepar kódnak 8 számjegyűnek kell lennie, és csak Nagy betűket és számokat tartalmazhat.'
                        );
                    }
                }
            });
        });

        return errors;
    };

    createBlockError(errors, index, field, message) {
        if (!errors.blocks) {
            errors.blocks = [];
        }
        if (!errors.blocks[index]) {
            errors.blocks[index] = {};
        }
        errors.blocks[index][field] = message;
    }

    onSubmit = async (values, { setSubmitting, setStatus }) => {
        try {
            setStatus(undefined);
            await damageService.createDamage(values);
            this.props.history.goBack();
        } catch (error) {
            this.setState({
                error: error.message,
            });
            setSubmitting(false);
        }
    };

    onDateChange = (setFieldValue) => (date) => {
        setFieldValue('damageDate', moment.utc(date.format('YYYY-MM-DD'), 'YYYY-MM-DD'));
    };

    handleFileUpload = async (event) => {
        try {
            const excelFile = event.target.files[0];
            event.target.value = '';
            await damageService.createDamageFromExcel(excelFile);
            alert('A feltöltés sikeresen befejeződött!');
        } catch (error) {
            alert(error);
        }
    };

    render() {
        const { classes } = this.props;
        const { error } = this.state;

        moment.locale('hu');

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} locale="hu" moment={moment}>
                <SecondaryScreen title={'Új kárakta'} showClose={true}>
                    <div className={classNames(classes.layout)}>
                        <div className={classes.uploadBlock}>
                            <input
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                                style={{ display: 'none' }}
                                onChange={this.handleFileUpload}
                            />
                            <label htmlFor="contained-button-file">
                                <Button
                                    variant="contained"
                                    component="span"
                                    className={classes.button}
                                >
                                    Excel táblázat feltöltése
                                </Button>
                            </label>
                        </div>
                        <Divider className={classes.uploadBlockDivider} />
                        <Formik
                            initialValues={{
                                damageNumber: '',
                                policyNumber: '',
                                damageDate: moment.utc(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD'),
                                area: 0,
                                dangerType: '',
                                inspectionAddress: '',
                                inspectionAddressZip: '',
                                expertId: '',
                                blocks: [
                                    {
                                        mepar: '',
                                        plants: '',
                                        area: 0,
                                    },
                                ],
                            }}
                            validate={this.validate}
                            onSubmit={this.onSubmit}
                        >
                            {({
                                touched,
                                errors,
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                setFieldValue,
                            }) => (
                                <form
                                    name="newDamage"
                                    onSubmit={handleSubmit}
                                    className={classes.form}
                                >
                                    {error && (
                                        <Typography className={classes.error}>{error}</Typography>
                                    )}

                                    <div className={classes.controlGroup}>
                                        <Typography className={classes.text}>Kárszám</Typography>
                                        <TextField
                                            name="damageNumber"
                                            autoComplete={'damageNumber'}
                                            error={!!(touched.damageNumber && errors.damageNumber)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.damageNumber}
                                            variant="outlined"
                                            helperText={touched.damageNumber && errors.damageNumber}
                                            className={classes.textField}
                                        />
                                        <Typography className={classes.text}>
                                            Kötvényszám
                                        </Typography>
                                        <TextField
                                            name="policyNumber"
                                            autoComplete={'policyNumber'}
                                            error={!!(touched.policyNumber && errors.policyNumber)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.policyNumber}
                                            variant="outlined"
                                            helperText={touched.policyNumber && errors.policyNumber}
                                            className={classes.textField}
                                        />
                                        <Typography className={classes.text}>
                                            Káresemény időpontja
                                        </Typography>
                                        <DatePicker
                                            name="damageDate"
                                            autoOk={true}
                                            margin="normal"
                                            variant="outlined"
                                            cancelLabel="Mégse"
                                            value={values.damageDate}
                                            onChange={this.onDateChange(setFieldValue)}
                                            onBlur={handleBlur}
                                            invalidDateMessage="Érvénytelen dátum"
                                            disableFuture
                                            maxDateMessage="Nem lehet későbbi a mai dátumnál"
                                            className={classes.textField}
                                        />
                                        <Typography className={classes.text}>
                                            Károsodott terület összesen (Ha)
                                        </Typography>
                                        <TextField
                                            name="area"
                                            type="number"
                                            autoComplete={'area'}
                                            error={!!(touched.area && errors.area)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.area}
                                            variant="outlined"
                                            helperText={touched.area && errors.area}
                                            className={classes.textField}
                                        />
                                        <Typography className={classes.text}>
                                            Káresemény típusa (Vihar, Jégverés, stb.)
                                        </Typography>
                                        <TextField
                                            name="dangerType"
                                            autoComplete={'dangerType'}
                                            error={!!(touched.dangerType && errors.dangerType)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.dangerType}
                                            variant="outlined"
                                            helperText={touched.dangerType && errors.dangerType}
                                            className={classes.textField}
                                        />
                                        <Typography className={classes.text}>
                                            Kárszemle helyszíne
                                        </Typography>
                                        <TextField
                                            name="inspectionAddress"
                                            autoComplete={'inspectionAddress'}
                                            error={
                                                !!(
                                                    touched.inspectionAddress &&
                                                    errors.inspectionAddress
                                                )
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.inspectionAddress}
                                            variant="outlined"
                                            helperText={
                                                touched.inspectionAddress &&
                                                errors.inspectionAddress
                                            }
                                            className={classes.textField}
                                        />
                                        <Typography className={classes.text}>
                                            Kárszemle irányítószáma
                                        </Typography>
                                        <TextField
                                            name="inspectionAddressZip"
                                            autoComplete={'inspectionAddressZip'}
                                            error={
                                                !!(
                                                    touched.inspectionAddressZip &&
                                                    errors.inspectionAddressZip
                                                )
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.inspectionAddressZip}
                                            variant="outlined"
                                            helperText={
                                                touched.inspectionAddressZip &&
                                                errors.inspectionAddressZip
                                            }
                                            className={classes.textField}
                                        />
                                        <FieldArray
                                            name="blocks"
                                            render={({ remove, push, form }) => (
                                                <>
                                                    {values.blocks.map((plot, index) => (
                                                        <div
                                                            key={index}
                                                            className={classes.controlGroup}
                                                        >
                                                            <Divider />
                                                            <div className={classes.blockTitleRow}>
                                                                <Typography
                                                                    className={classes.blockTitle}
                                                                >
                                                                    Károsodott tábla #{index + 1}
                                                                </Typography>
                                                                <IconButton
                                                                    className={classes.removeButton}
                                                                    onClick={() => remove(index)}
                                                                    disabled={
                                                                        values.blocks.length === 1
                                                                    }
                                                                >
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            </div>
                                                            <Typography className={classes.text}>
                                                                MePAR kód
                                                            </Typography>
                                                            <TextField
                                                                name={`blocks.${index}.mepar`}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                variant="outlined"
                                                                value={
                                                                    form.values.blocks[index].mepar
                                                                }
                                                                className={classes.textField}
                                                                error={
                                                                    !!(
                                                                        form.touched.blocks &&
                                                                        form.touched.blocks[
                                                                            index
                                                                        ] &&
                                                                        form.touched.blocks[index]
                                                                            .mepar &&
                                                                        form.errors.blocks &&
                                                                        form.errors.blocks[index] &&
                                                                        form.errors.blocks[index]
                                                                            .mepar
                                                                    )
                                                                }
                                                                helperText={
                                                                    form.touched.blocks &&
                                                                    form.touched.blocks[index] &&
                                                                    form.touched.blocks[index]
                                                                        .mepar &&
                                                                    form.errors.blocks &&
                                                                    form.errors.blocks[index] &&
                                                                    form.errors.blocks[index].mepar
                                                                }
                                                            />
                                                            <Typography className={classes.text}>
                                                                Károsodott terület (Ha)
                                                            </Typography>
                                                            <TextField
                                                                name={`blocks.${index}.area`}
                                                                type="number"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                variant="outlined"
                                                                value={
                                                                    form.values.blocks[index].area
                                                                }
                                                                className={classes.textField}
                                                                error={
                                                                    !!(
                                                                        form.touched.blocks &&
                                                                        form.touched.blocks[
                                                                            index
                                                                        ] &&
                                                                        form.touched.blocks[index]
                                                                            .area &&
                                                                        form.errors.blocks &&
                                                                        form.errors.blocks[index] &&
                                                                        form.errors.blocks[index]
                                                                            .area
                                                                    )
                                                                }
                                                                helperText={
                                                                    form.touched.blocks &&
                                                                    form.touched.blocks[index] &&
                                                                    form.touched.blocks[index]
                                                                        .area &&
                                                                    form.errors.blocks &&
                                                                    form.errors.blocks[index] &&
                                                                    form.errors.blocks[index].area
                                                                }
                                                            />
                                                            <Typography className={classes.text}>
                                                                Növény
                                                            </Typography>
                                                            <TextField
                                                                name={`blocks.${index}.plants`}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                variant="outlined"
                                                                value={
                                                                    form.values.blocks[index].plants
                                                                }
                                                                className={classes.textField}
                                                                error={
                                                                    !!(
                                                                        form.touched.blocks &&
                                                                        form.touched.blocks[
                                                                            index
                                                                        ] &&
                                                                        form.touched.blocks[index]
                                                                            .plants &&
                                                                        form.errors.blocks &&
                                                                        form.errors.blocks[index] &&
                                                                        form.errors.blocks[index]
                                                                            .plants
                                                                    )
                                                                }
                                                                helperText={
                                                                    form.touched.blocks &&
                                                                    form.touched.blocks[index] &&
                                                                    form.touched.blocks[index]
                                                                        .plants &&
                                                                    form.errors.blocks &&
                                                                    form.errors.blocks[index] &&
                                                                    form.errors.blocks[index].plants
                                                                }
                                                            />
                                                            <div />
                                                        </div>
                                                    ))}
                                                    <Button
                                                        variant="contained"
                                                        color="default"
                                                        onClick={() =>
                                                            push({ mepar: '', area: 0, plants: '' })
                                                        }
                                                    >
                                                        <AddIcon />
                                                    </Button>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classNames(classes.saveButton)}
                                        type="submit"
                                    >
                                        Mentés
                                    </Button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </SecondaryScreen>
            </MuiPickersUtilsProvider>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.user;
    return {
        user,
    };
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(CreateDamage)));
