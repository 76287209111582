class AgroModule {
    getBlockPlotsFromMepars(mepars) {
        return mepars.reduce((accumulator, mepar) => {
            return [...accumulator, ...mepar.blockPlots];
        }, []);
    }

    getBlockPlotFromMepars(mepars, blockPlotId) {
        const blockPlots = mepars.reduce((accumulator, mepar) => {
            return [...accumulator, ...mepar.blockPlots];
        }, []);

        return blockPlots.find((blockPlot) => blockPlot.id === blockPlotId);
    }
}

export const agroModule = new AgroModule();
