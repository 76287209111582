import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cemeteryActions, mapActions, viewActions } from '../state/actions';
import { LAYERS, VIEWS } from 'shared';
import { cemeteryService } from '../services/cemeteryService';
import { useLocation } from 'react-router';

export default function CemeteryHomeController() {
    const dispatch = useDispatch();
    const graves = useSelector((state) => state.cemetery.graves);
    const graveyard = useSelector((state) => state.cemetery.graveyard);
    const location = useLocation();

    useEffect(() => {
        if (graves && graves.length > 0) {
            return;
        }

        async function loadGraves() {
            dispatch(viewActions.setInfoPanelState({ hidden: true }));
            dispatch(viewActions.setViewState({ view: VIEWS.PROGRESS }));
            const graveyard = await cemeteryService.getGraveyard();
            const graves = await cemeteryService.getGraves();
            dispatch(cemeteryActions.setGraveyard(graveyard));
            dispatch(cemeteryActions.setGraves(graves));
        }

        loadGraves().catch((error) => console.error(error));
    }, [dispatch, graves]);

    useEffect(() => {
        if (!graveyard || !graves || graves.length === 0) {
            return;
        }

        let searchParams = new URLSearchParams(location.search);
        const parcel = searchParams.get('parcel');
        const row = searchParams.get('row');
        const pos = searchParams.get('grave');

        if (parcel && row && pos) {
            const selectedGrave = graves.find(
                (grave) => grave.parcel === parcel && grave.row === row && grave.pos === pos
            );
            if (selectedGrave) {
                dispatch(cemeteryActions.setSelectedGraves([selectedGrave]));
            }
        }

        dispatch(viewActions.setInfoPanelState({ hidden: false, open: false }));
        dispatch(viewActions.setViewState({ view: VIEWS.HOME }));
        dispatch(mapActions.resetLayers());
        dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.STREET,
                visible: true,
                fittingLayer: false,
            })
        );
        dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.CEMETERY_UAV,
                visible: true,
                fittingGeometry: graveyard.geom,
                geoserverUrl: graveyard.geoserverUrl,
                tiles: graveyard.tiles,
                fittingLayer: true,
            })
        );
        dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.CEMETERY_GRAVE,
                visible: true,
                graves,
                fittingLayer: false,
            })
        );
    }, [dispatch, graveyard, graves, location]);
    return null;
}
