import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { meparActions } from '../state/actions/meparActions';
import { mapActions, viewActions } from '../state/actions';
import { LAYERS, VIEWS } from 'shared';
import { useLocation, useParams } from 'react-router';
import { agroModule } from 'shared/src/modules/agroModule';

export default function AgroBlockPlotController() {
    const dispatch = useDispatch();
    const mepars = useSelector((state) => state.mepar.mepars);
    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        if (mepars) {
            return;
        }

        dispatch(meparActions.requestAgroMepars());
    }, [mepars, dispatch]);

    useEffect(() => {
        if (!mepars) {
            return;
        }

        const blockPlotId = parseInt(params.id, 10);
        const blockPlot = agroModule.getBlockPlotFromMepars(mepars, blockPlotId);
        let searchParams = new URLSearchParams(location.search);
        const productType = searchParams.get('productType');

        dispatch(meparActions.setBlockPlot(blockPlot));
        dispatch(meparActions.setProductType(productType));
        dispatch(meparActions.setProduct(null));

        dispatch(viewActions.setInfoPanelState({ open: true }));
        dispatch(viewActions.setViewState({ view: VIEWS.PROGRESS }));

        dispatch(mapActions.resetLayers());
        dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.COUNTRY,
                visible: true,
                fittingLayer: false,
            })
        );

        dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.MEPAR,
                visible: true,
                mepars,
                blockPlot,
                fittingLayer: false,
            })
        );
        dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.BLOCK_PLOT,
                visible: true,
                mepars,
                blockPlot,
                fittingLayer: true,
            })
        );

        dispatch(viewActions.setViewState({ view: VIEWS.AGRO_BLOCK_PLOT }));
    }, [mepars, dispatch, params, location]);

    return null;
}
