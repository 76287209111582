import { LAYERS } from 'shared';
import { Tile as TileLayer } from 'ol/layer';
import TileWMS from 'ol/source/TileWMS';

export default class ProkatDTMLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new TileLayer({
            title: 'Domborzat modell',
            type: LAYERS.TYPES.PROKAT_DTM,
            visible: false,
            displayInLayerSwitcher: true,
            source: new TileWMS({
                url: `/geoserver/wms?service=WMS`,
                crossOrigin: 'anonymous',
                params: {
                    LAYERS: 'PROKAT:HB_DTM',
                    FORMAT: 'image/png',
                },
            }),
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {}
}
