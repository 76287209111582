export const geometryService = {
    getCountry,
};

async function getCountry() {
    const token = localStorage.getItem('jwt');

    let isAdmin = false;
    try {
        isAdmin = localStorage.getItem('envimap-admin') === 'true';
    } catch (error) {}

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-admin': `${isAdmin}`,
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await fetch(`/api/geometry/country`, options);

    if (!response.ok) {
        console.log(response);
        return null;
    }

    return response.json();
}
