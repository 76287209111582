import { LAYERS } from 'shared';
import { Tile as TileLayer } from 'ol/layer';
import TileWMS from 'ol/source/TileWMS';

export default class BlockPlotProductLayer {
    constructor(map, user) {
        this.map = map;
        this.init(user);
    }

    init(user) {
        this.layer = new TileLayer({
            title: 'NDVI',
            type: LAYERS.TYPES.BLOCK_PLOT_PRODUCT,
            visible: false,
            displayInLayerSwitcher: true,
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (layer.product !== this.layer.get('product') && layer.visible) {
            const match = layer.product.url.match('^([^:]*):(.*)$');
            if (!match) {
                console.log('Match failure', layer);
                return;
            }

            const group = match[1];

            const urlParam = group;
            const productSource = new TileWMS({
                url: `/geoserver/${urlParam}/wms?service=WMS`,
                crossOrigin: 'anonymous',
                params: {
                    LAYERS: layer.product.url,
                    FORMAT: 'image/png',
                },
            });
            this.layer.setSource(productSource);
            this.layer.setVisible(true);
        }

        this.layer.set('product', layer.product);

        this.layer.setVisible(layer.visible);
    }
}
