import { viewConstants } from '../constants';
import { logService } from '../../services/logService';
import { LOGS } from 'shared';

export const viewActions = {
    setViewState,
    setFarmerViewState,
    setInfoPanelState,
};

function setViewState(viewState) {
    return {
        type: viewConstants.SET_VIEW_STATE,
        payload: {
            viewState,
        },
    };
}

function setFarmerViewState(viewState) {
    logService.saveLog(LOGS.TYPES.SET_VIEW_STATE, viewState).catch((error) => console.log(error));
    return {
        type: viewConstants.SET_FARMER_VIEW_STATE,
        payload: {
            viewState,
        },
    };
}

function setInfoPanelState(infoPanelState) {
    return {
        type: viewConstants.SET_INFO_PANEL_STATE,
        payload: {
            infoPanelState,
        },
    };
}
