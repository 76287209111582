import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../state/actions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

export default function WelcomeDialog() {
    const [isOpen, setIsOpen] = useState(true);
    const dispatch = useDispatch();
    const theme = useTheme();

    const user = useSelector((state) => state.user.user);
    const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    async function handleClose() {
        dispatch(userActions.suppressWelcomeScreen(user));
        setIsOpen(false);
    }

    if (!user || !user.welcomeScreen) {
        return null;
    }

    return (
        <Dialog fullScreen={!isTabletOrDesktop} open={isOpen} onClose={handleClose}>
            <DialogTitle>Tisztelt Ügyfelünk!</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Üdvözöljük a Generali műholdas „Riasztás” csomag online térképi felületén!
                    <br />
                    <br />
                    Az oldalon Ön bármikor megtekintheti a területét ért károk előfordulási helyét,
                    és hozzáférhet a rendszeresen frissülő műholdas NDVI (biomassza intenzitás)
                    térképekhez is.
                    <br />
                    <br />
                    A könnyebb tájékozódás érdekében megrajzolhatja a MePAR blokkjain belüli táblák
                    határvonalát.
                    <br />
                    <br />
                    A biztosított területek adatfeldolgozása folyamatosan zajlik, ezért
                    elképzelhető, hogy néhány MePAR blokkja későbbi időpontban kerül megjelenítésre.
                    <br />
                    <br />
                    Eredményes gazdálkodást kíván a Generali Biztosító és az Envirosense Hungary
                    Kft.!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Tovább
                </Button>
            </DialogActions>
        </Dialog>
    );
}
