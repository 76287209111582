import { LAYERS } from 'shared';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import { fromExtent } from 'ol/geom/Polygon';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import { bbox } from 'ol/loadingstrategy';
import { prokatSoilSampleService } from '../../services/prokatSoilSampleService';
import Point from 'ol/geom/Point';
import Select from 'ol/interaction/Select';
import { pointerMove } from 'ol/events/condition';
import PopupFeature from 'ol-ext/overlay/PopupFeature';

export default class ProkatSoilSampleLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        const vectorSource = new VectorSource({
            format: new GeoJSON(),
            loader: async (extent) => {
                const extentPolygon = fromExtent(extent);
                const soilSamples = await prokatSoilSampleService.getSoilSamples(extentPolygon);
                soilSamples.forEach((soilSample) => {
                    const soilSamplePolygon = soilSample.geom;
                    const feature = new Feature({
                        geometry: new Point(soilSamplePolygon.coordinates),
                        soilSample,
                    });
                    vectorSource.addFeature(feature);
                });
            },
            strategy: bbox,
        });

        this.layer = new VectorLayer({
            title: `Talajmintavételek`,
            type: LAYERS.TYPES.PROKAT_SOIL_ZONE_GENETIC,
            visible: false,
            renderMode: 'vector',
            source: vectorSource,
            maxResolution: 25,
            style: (feature) => {
                return new Style({
                    image: new Circle({
                        radius: 7,
                        fill: new Fill({ color: 'black' }),
                        stroke: new Stroke({
                            color: 'rgb(255, 0, 0, 1)',
                            width: 2,
                        }),
                    }),
                });
            },
        });

        this.initInteractions();
        this.addInteractions();
    }

    initInteractions() {
        this.pointerSelect = new Select({
            condition: pointerMove,
            layers: [this.layer],
        });

        const popup = new PopupFeature({
            select: this.pointerSelect,
            template: {
                title: (feature) => {
                    const soilSample = feature.get('soilSample');
                    return soilSample.soilPlan;
                },
                attributes: {
                    settlement: {
                        title: 'Település',
                        format: function (value, feature) {
                            const soilSample = feature.get('soilSample');
                            return soilSample.settlement;
                        },
                    },
                    hrsz: {
                        title: 'Hrsz',
                        format: function (value, feature) {
                            const soilSample = feature.get('soilSample');
                            return soilSample.hrsz;
                        },
                    },
                    meparCode: {
                        title: 'MePAR kód',
                        format: function (value, feature) {
                            const soilSample = feature.get('soilSample');
                            return soilSample.meparCode;
                        },
                    },
                    landUse: {
                        title: 'Földhasználat',
                        format: function (value, feature) {
                            const soilSample = feature.get('soilSample');
                            return soilSample.landUse;
                        },
                    },
                    area: {
                        title: 'Területméret',
                        format: function (value, feature) {
                            const soilSample = feature.get('soilSample');
                            return `${soilSample.area} ha`;
                        },
                    },
                    genetic: {
                        title: 'Genetikus talajtípus',
                        format: function (value, feature) {
                            const soilSample = feature.get('soilSample');
                            return soilSample.genetic;
                        },
                    },
                    organicContent: {
                        title: 'Humuszkategória',
                        format: function (value, feature) {
                            const soilSample = feature.get('soilSample');
                            return soilSample.organicContent;
                        },
                    },
                },
            },
        });

        this.map.map.addOverlay(popup);
    }

    addInteractions() {
        this.removeInteractions();
        this.map.map.addInteraction(this.pointerSelect);
    }

    removeInteractions() {
        this.map.map.removeInteraction(this.pointerSelect);
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        this.layer.setVisible(layer.visible);
    }
}
