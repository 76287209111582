import { List, ListItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { ROUTES } from 'shared/src/constants/routes';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider/Divider';
import { roundHectareArea } from '../../helpers/areaCalculator';
import { PRODUCTS, ROLE } from 'shared';
import Button from '@material-ui/core/Button/Button';

const styles = (theme) => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    redLabel: {
        color: 'red',
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    blockButton: {
        width: '100%',
        height: '60px',
    },
    blockButtonHighlighted: {
        backgroundColor: 'rgba(200,200,200,0.5)',
    },
    buttonLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonInfoRowLayout: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    buttonTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    buttonLabel: {
        fontSize: '12px',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: '12px',
    },
    gap: {
        marginTop: '10px',
    },
    button: {
        width: '100%',
    },
});

class BlockPanel extends React.Component {
    onShowProduct = (block, product) => () => {
        this.props.history.push(`${ROUTES.BLOCK(block.meparCode)}?productType=${product.type}`);
    };

    render() {
        const { classes, user, block } = this.props;
        moment.locale('hu');

        if (!block) {
            return null;
        }

        const meparBorderIsMissing = !block.meparPolygon;

        const policies = block.policies.reduce((accumulator, policy) => {
            if (
                accumulator.find(
                    (accumulatedPolicy) =>
                        accumulatedPolicy.policyNumber === policy.policyNumber &&
                        accumulatedPolicy.year === policy.year
                )
            ) {
                return accumulator;
            }
            return [...accumulator, policy];
        }, []);

        const damages = block.damages.reduce((accumulator, damage) => {
            if (
                accumulator.find(
                    (accumulatedDamage) => accumulatedDamage.damageNumber === damage.damageNumber
                )
            ) {
                return accumulator;
            }
            return [...accumulator, damage];
        }, []);

        const productTypeMap = block.products
            ? block.products.reduce((accumulator, product) => {
                  const productsInType = accumulator[product.type] || [];
                  productsInType.push(product);
                  return Object.assign(accumulator, {
                      [product.type]: productsInType,
                  });
              }, {})
            : {};

        return (
            <List>
                <ListItem>
                    <div className={classes.layout}>
                        {meparBorderIsMissing && (
                            <div className={classes.row}>
                                <Typography className={classes.redLabel}>
                                    A MePAR körvonal hiányzik!
                                </Typography>
                            </div>
                        )}
                        <div className={classes.row}>
                            <Typography className={classes.label}>MePAR kód:</Typography>
                            <Typography className={classes.value}>{block.meparCode}</Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>MePAR terület:</Typography>
                            <Typography className={classes.value}>
                                {`${roundHectareArea(block.geomHa)} Ha`}
                            </Typography>
                        </div>
                    </div>
                </ListItem>
                {user.id !== 57 && (
                    <>
                        {policies.length > 0 && (
                            <>
                                <Divider />
                                <ListItem>
                                    <div className={classes.layout}>
                                        {policies.map((policy, index) => (
                                            <div key={policy.id}>
                                                {index > 0 && <div className={classes.gap} />}
                                                <div className={classes.row}>
                                                    <Typography className={classes.label}>
                                                        Kötvényszám:
                                                    </Typography>
                                                    <Typography className={classes.value}>
                                                        <Link
                                                            className={classes.value}
                                                            to={ROUTES.POLICY(policy.policyNumber)}
                                                        >
                                                            {policy.policyNumber}
                                                        </Link>{' '}
                                                        ({policy.year})
                                                    </Typography>
                                                </div>
                                                {policy.policyPlots.map((policyPlot) => (
                                                    <div
                                                        key={policyPlot.id}
                                                        className={classes.row}
                                                    >
                                                        <Typography className={classes.label}>
                                                            Tábla:
                                                        </Typography>
                                                        <Typography className={classes.value}>
                                                            <Link
                                                                className={classes.value}
                                                                to={`${ROUTES.POLICY(
                                                                    policy.policyNumber
                                                                )}?policyPlotId=${policyPlot.id}`}
                                                            >{`${policyPlot.plantCode} - ${policyPlot.insuredArea} Ha`}</Link>
                                                        </Typography>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </ListItem>
                            </>
                        )}
                        <Divider />
                        <ListItem>
                            <div className={classes.layout}>
                                {damages.map((damage, index) => (
                                    <div key={damage.id}>
                                        {index > 0 && <div className={classes.gap} />}
                                        <div className={classes.row}>
                                            <Typography className={classes.label}>
                                                Kárszám:
                                            </Typography>
                                            <Typography className={classes.value}>
                                                <Link
                                                    className={classes.value}
                                                    to={ROUTES.DAMAGE(damage.damageNumber)}
                                                >
                                                    {damage.damageNumber}
                                                </Link>
                                            </Typography>
                                        </div>
                                        <div className={classes.row}>
                                            <Typography className={classes.label}>
                                                Kötvényszám:
                                            </Typography>
                                            <Typography className={classes.value}>
                                                {user.role === ROLE.EXPERT_DAMAGE && (
                                                    <>{damage.policyNumber}</>
                                                )}
                                                {user.role !== ROLE.EXPERT_DAMAGE && (
                                                    <Link
                                                        className={classes.value}
                                                        to={ROUTES.POLICY(damage.policyNumber)}
                                                    >
                                                        {damage.policyNumber}
                                                    </Link>
                                                )}
                                            </Typography>
                                        </div>
                                        {block.damages
                                            .filter(
                                                (damagePlot) =>
                                                    damagePlot.damageNumber === damage.damageNumber
                                            )
                                            .map((damagePlot) => (
                                                <div key={damagePlot.id} className={classes.row}>
                                                    <Typography className={classes.label}>
                                                        Tábla:
                                                    </Typography>
                                                    <Typography className={classes.value}>
                                                        <Link
                                                            className={classes.value}
                                                            to={`${ROUTES.DAMAGE(
                                                                damage.damageNumber
                                                            )}?damagePlotId=${damagePlot.id}`}
                                                        >{`${damagePlot.plantCode} - ${damagePlot.damagedArea} Ha`}</Link>
                                                    </Typography>
                                                </div>
                                            ))}
                                    </div>
                                ))}
                            </div>
                        </ListItem>
                    </>
                )}
                {productTypeMap[PRODUCTS.TYPES.NDVI] && user.role !== ROLE.FARMER && (
                    <ListItem>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.button}
                            onClick={this.onShowProduct(
                                block,
                                productTypeMap[PRODUCTS.TYPES.NDVI][0]
                            )}
                        >
                            {`NDVI`}
                        </Button>
                    </ListItem>
                )}
                {productTypeMap[PRODUCTS.TYPES.ALERT] && user.role === ROLE.FARMER && (
                    <ListItem>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.button}
                            onClick={this.onShowProduct(
                                block,
                                productTypeMap[PRODUCTS.TYPES.ALERT][0]
                            )}
                        >
                            {`Riasztások`}
                        </Button>
                    </ListItem>
                )}
                {productTypeMap[PRODUCTS.TYPES.AGRI_NDVI] && user.role === ROLE.FARMER && (
                    <ListItem>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.button}
                            onClick={this.onShowProduct(
                                block,
                                productTypeMap[PRODUCTS.TYPES.AGRI_NDVI][0]
                            )}
                        >
                            {`NDVI`}
                        </Button>
                    </ListItem>
                )}
                {productTypeMap[PRODUCTS.TYPES.CHANGE] && user.role !== ROLE.FARMER && (
                    <ListItem>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.button}
                            onClick={this.onShowProduct(
                                block,
                                productTypeMap[PRODUCTS.TYPES.CHANGE][0]
                            )}
                        >
                            {`Változás térkép`}
                        </Button>
                    </ListItem>
                )}
                {productTypeMap[PRODUCTS.TYPES.CHRONOLOGY] && (
                    <ListItem>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.button}
                            onClick={this.onShowProduct(
                                block,
                                productTypeMap[PRODUCTS.TYPES.CHRONOLOGY][0]
                            )}
                        >
                            {user.role === ROLE.FARMER ? 'NDVI' : `Kronológia`}
                        </Button>
                    </ListItem>
                )}
                {productTypeMap[PRODUCTS.TYPES.CHANGE_MAP] && user.role !== ROLE.FARMER && (
                    <ListItem>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.button}
                            onClick={this.onShowProduct(
                                block,
                                productTypeMap[PRODUCTS.TYPES.CHANGE_MAP][0]
                            )}
                        >
                            {`Változás térkép`}
                        </Button>
                    </ListItem>
                )}
                {productTypeMap[PRODUCTS.TYPES.DAMAGE_MAP] && user.role !== ROLE.FARMER && (
                    <ListItem>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.button}
                            onClick={this.onShowProduct(
                                block,
                                productTypeMap[PRODUCTS.TYPES.DAMAGE_MAP][0]
                            )}
                        >
                            {`Káros - nem káros térkép`}
                        </Button>
                    </ListItem>
                )}
                {productTypeMap[PRODUCTS.TYPES.HETEROGENITY_MAP] && user.role !== ROLE.FARMER && (
                    <ListItem>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.button}
                            onClick={this.onShowProduct(
                                block,
                                productTypeMap[PRODUCTS.TYPES.HETEROGENITY_MAP][0]
                            )}
                        >
                            {`Táblamonitoring`}
                        </Button>
                    </ListItem>
                )}
            </List>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.user;
    const { block } = state.block;
    return {
        user,
        block,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(BlockPanel)));
