import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField/TextField';
import clsx from 'clsx';
import { connect } from 'react-redux';
import SecondaryScreen from '../components/screens/SecondaryScreen';
import { withFormik } from 'formik';
import { withRouter } from 'react-router';
import { screens } from '../styles/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    layout: {
        minWidth: '400px',
        maxWidth: '400px',
        padding: '30px',
        marginTop: '30px',
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: '100%',
            maxWidth: '100%',
            flex: 1,
            padding: '30px',
            marginTop: '0px',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    error: {
        color: 'rgba(255,0,0,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
        paddingBottom: '15px',
    },
    text: {
        color: 'rgba(142,142,147,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
    },
    checkLabel: {
        color: 'rgba(142,142,147,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
    },
    check: {
        '&$checked': {
            color: 'rgba(0,84,255,1)',
        },
    },
    checked: {},
    loginButton: {
        marginTop: '30px',
        minHeight: '60px',
        maxHeight: '60px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: 'rgba(0,84,255,1)',
        '&:hover': {
            backgroundColor: '#004EEE',
        },
        boxShadow: '0 2px 4px 0 rgba(5,33,81,0.07)',
        color: theme.palette.text.primaryInverse,
        fontSize: '18px',
        textTransform: 'none',
    },
    titleBlock: {
        marginBottom: '20px',
    },
    textBlock: {
        marginBottom: '30px',
    },
});

class PasswordRenew extends React.Component {
    render() {
        const { classes, values, errors, touched, handleChange, handleBlur, handleSubmit } =
            this.props;

        return (
            <SecondaryScreen title={'Jelszó megadása'}>
                <div className={clsx(classes.layout)}>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <p>
                            <Typography variant="body2">
                                A rendszerbe való belépéshez kérjük adja meg új jelszavát.
                            </Typography>
                        </p>
                        <br />
                        <div className={classes.text}>Jelszó</div>
                        <br />
                        <TextField
                            name="password"
                            type="password"
                            autoComplete="password"
                            error={!!(touched.password && errors.password)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            variant="outlined"
                            helperText={touched.password && errors.password}
                        />
                        <Button className={classNames(classes.loginButton)} type="submit">
                            Jelszó beállítása
                        </Button>
                    </form>
                </div>
            </SecondaryScreen>
        );
    }
}

PasswordRenew.propTypes = {
    classes: PropTypes.object.isRequired,
};

const formikOptions = {
    mapPropsToValues: () => ({ username: '', password: '' }),
    validate: (values, props) => {
        let errors = {};
        const requiredFields = ['password'];
        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field] = 'Kötelező kitölteni!';
            }
        });

        return errors;
    },
    handleSubmit: (values, { setSubmitting, props }) => {
        // const { password } = values;
        // const { match: { params } } = props;
        // const token = params.token;
        /*props.dispatch(loginActions.passwordRenew(password, token));*/
        setSubmitting(false);
    },
    displayName: 'BasicForm',
};

function mapStateToProps(state) {
    return {
        login: state.login,
        /*error: state.error.error,*/
    };
}

export default withRouter(
    connect(mapStateToProps)(withFormik(formikOptions)(withStyles(styles)(PasswordRenew)))
);
