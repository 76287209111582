import { LAYERS } from 'shared';
import { Tile as TileLayer } from 'ol/layer';
import TileWMS from 'ol/source/TileWMS';

export default class EKProductLayer {
    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new TileLayer({
            title: 'Terméktérkép',
            type: LAYERS.TYPES.EK_PRODUCT,
            visible: true,
            displayInLayerSwitcher: true,
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (layer.product && layer.product !== this.layer.get('product') && layer.visible) {
            const match = layer.product.url.match('^([^:]*):(.*)$');
            if (!match) {
                console.log('Match failure', layer);
                return;
            }

            const group = match[1];

            const urlParam = group;
            const productSource = new TileWMS({
                url: `/geoserver/${urlParam}/wms?service=WMS`,
                crossOrigin: 'anonymous',
                params: {
                    LAYERS: layer.product.url,
                    FORMAT: 'image/png',
                },
            });
            this.layer.setSource(productSource);
            this.layer.setVisible(true);

            this.layer.set('product', layer.product);
        }

        if (!layer.visible) {
            this.layer.setVisible(false);
        }
    }
}
