import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { screens } from '../../styles/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
    align: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: theme.header.size[screens.desktop],
        maxHeight: theme.header.size[screens.desktop],
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            minHeight: theme.header.size[screens.tablet],
            maxHeight: theme.header.size[screens.tablet],
        },
        [theme.breakpoints.down(screens.mobile)]: {
            minHeight: theme.header.size[screens.mobile],
            maxHeight: theme.header.size[screens.mobile],
        },
    },
    color: {
        backgroundColor: theme.palette.background.paper,
    },
    size: {
        minWidth: theme.main.width,
        maxWidth: theme.main.width,
        minHeight: theme.header.size[screens.desktop],
        maxHeight: theme.header.size[screens.desktop],
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            minWidth: '100%',
            maxWidth: '100%',
            minHeight: theme.header.size[screens.tablet],
            maxHeight: theme.header.size[screens.tablet],
        },
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: '100%',
            maxWidth: '100%',
            minHeight: theme.header.size[screens.mobile],
            maxHeight: theme.header.size[screens.mobile],
        },
    },
    alignItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            paddingLeft: '20px',
            paddingRight: '10px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            paddingLeft: '30px',
            paddingRight: '20px',
        },
    },
    border: {
        borderBottom: '2px solid rgba(239,239,239,1)',
    },
    closeButton: {
        color: theme.palette.primary.main,
        minWidth: '30px',
        maxWidth: '30px',
        minHeight: '30px',
        maxHeight: '30px',
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: '20px',
            maxWidth: '20px',
            minHeight: '20px',
            maxHeight: '20px',
        },
    },
    title: {
        fontSize: '30px',
        fontWeight: '900',
        lineHeight: '35px',
        [theme.breakpoints.down(screens.mobile)]: {
            fontSize: '24px',
            fontWeight: '900',
            lineHeight: '28px',
        },
    },
});

class SimpleHeader extends React.Component {
    onClick = () => {
        this.props.history.goBack();
    };

    render() {
        const { classes, className, title, showClose } = this.props;
        return (
            <div className={classNames(classes.align, classes.border, classes.color, className)}>
                <div className={classNames(classes.size, classes.alignItems)}>
                    {showClose ? null : <div />}
                    <Typography className={classes.title}>{title}</Typography>
                    {showClose ? (
                        <IconButton aria-label="Close" onClick={this.onClick}>
                            <CloseIcon className={classes.closeButton} />
                        </IconButton>
                    ) : (
                        <div />
                    )}
                </div>
            </div>
        );
    }
}

SimpleHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SimpleHeader));
