import { List, ListItem, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button/Button';
import moment from 'moment';
import { agroModule } from 'shared/src/modules/agroModule';
import { ROUTES } from 'shared';
import { useHistory } from 'react-router';
import _ from 'lodash';

moment.locale('hu');

const useStyles = makeStyles((theme) => ({
    progress: {
        margin: theme.spacing(2),
    },
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    navigationRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    navigationNumbers: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    blockButtonHighlighted: {
        backgroundColor: 'rgba(200,200,200,0.5)',
    },
    buttonLayout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    buttonInfoRowLayout: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    buttonTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    buttonLabel: {
        fontSize: '12px',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: '12px',
    },
}));

export default function AgroHomePanel() {
    const classes = useStyles();
    const mepars = useSelector((state) => state.mepar.mepars);
    const history = useHistory();

    const blockPlots = agroModule.getBlockPlotsFromMepars(mepars);

    function handleBlockPlotClick(blockPlot) {
        return () => {
            history.push(`${ROUTES.BLOCK_PLOT(blockPlot.id)}`);
        };
    }

    const orderedBlockPlots = _.orderBy(blockPlots, ['name'], ['asc']);

    return (
        <List className={classes.layout}>
            {orderedBlockPlots.map((blockPlot) => (
                <ListItem key={blockPlot.id}>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="default"
                        size="small"
                        onClick={handleBlockPlotClick(blockPlot)}
                    >
                        <div className={classes.buttonLayout}>
                            <Typography className={classes.buttonTitle}>
                                {`Tábla: ${blockPlot.name}`}
                            </Typography>
                            <div className={classes.buttonInfoRowLayout}>
                                <Typography className={classes.buttonLabel}>
                                    {`MePAR: ${blockPlot.meparCode}`}
                                </Typography>
                            </div>
                            <div className={classes.buttonInfoRowLayout}>
                                <Typography className={classes.buttonLabel}>
                                    {`Terület: ${blockPlot.area} ha`}
                                </Typography>
                            </div>
                            <div className={classes.buttonInfoRowLayout}>
                                <Typography className={classes.buttonLabel}>
                                    {`Növény: ${blockPlot.plantName}`}
                                </Typography>
                            </div>
                        </div>
                    </Button>
                </ListItem>
            ))}
        </List>
    );
}
