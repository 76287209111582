import { woodwiserProjectActions } from '../actions/woodwiserProjectActions';

const initialState = {
    woodwiserProject: null,
};

export function woodwiserProject(state = initialState, action) {
    switch (action.type) {
        case woodwiserProjectActions.SET_WOODWISER_PROJECT:
            return {
                woodwiserProject: action.payload.woodwiserProject,
            };
        default:
            return state;
    }
}
