import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { meparActions } from '../../../state/actions/meparActions';
import { dialogActions } from '../../../state/actions/dialogActions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    listItem: {
        '&.MuiListItem-secondaryAction': {
            paddingRight: '126px',
        },
    },
}));

export default function PolicyPlotsDialog() {
    const theme = useTheme();
    const classes = useStyles();
    const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const isOpen = useSelector((state) => state.dialog.isPolicyPlotDialogVisible);
    const dispatch = useDispatch();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        let isAdmin = false;
        try {
            isAdmin = localStorage.getItem('envimap-admin') === 'true';
        } catch (error) {}

        if (isAdmin) {
            setIsAdmin(true);
        }
    }, []);

    if (!selectedMepar) {
        return null;
    }

    function handleDeletePolicyPlotGeom(policyPlot) {
        return () => {
            dispatch(meparActions.deletePlotGeometry(policyPlot));
            handleClose();
        };
    }

    function handleEditPolicyPlotGeom(policyPlot) {
        return () => {
            dispatch(meparActions.drawPlotGeometry(policyPlot));
            handleClose();
        };
    }

    function handleDrawPolicyPlotGeom(policyPlot) {
        return () => {
            dispatch(meparActions.drawPlotGeometry(policyPlot));
            handleClose();
        };
    }

    function handleClose() {
        dispatch(dialogActions.hidePolicyPlotsDialog());
    }

    return (
        <Dialog fullScreen={!isTabletOrDesktop} open={isOpen} onClose={handleClose}>
            <DialogTitle>Táblahatárok kezelése</DialogTitle>
            <DialogContent>
                <List>
                    {selectedMepar.policyPlots.map((policyPlot) => (
                        <ListItem key={policyPlot.id} className={classes.listItem}>
                            <ListItemText
                                primary={`${policyPlot.plantName} - ${policyPlot.insuredArea} Ha`}
                                secondary={
                                    !policyPlot.tableGeometry ? (
                                        <div style={{ color: 'red' }}>Rajzolást igényel!</div>
                                    ) : (
                                        `Megrajzolva`
                                    )
                                }
                            />
                            <ListItemSecondaryAction>
                                {policyPlot.tableGeometry ? (
                                    <>
                                        <IconButton onClick={handleEditPolicyPlotGeom(policyPlot)}>
                                            <EditIcon />
                                        </IconButton>
                                        {isAdmin && (
                                            <IconButton
                                                onClick={handleDeletePolicyPlotGeom(policyPlot)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </>
                                ) : (
                                    <IconButton onClick={handleDrawPolicyPlotGeom(policyPlot)}>
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="contained">
                    Bezár
                </Button>
            </DialogActions>
        </Dialog>
    );
}
