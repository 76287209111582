import { meparConstants } from '../actions/meparActions';
import _ from 'lodash';

const initialState = {
    showNdviLayer: true,
    showAlertLayer: true,
    selectedMepar: null,
    policyPlotToDraw: null,
    editedPolygon: null,
    mepars: null,
    alertMepars: null,
    productDate: null,
    alertDate: null,
    blockPlot: null,
    productType: null,
    product: null,
};

export function mepar(state = initialState, action) {
    switch (action.type) {
        case meparConstants.SET_SHOW_NDVI_LAYER:
            return {
                ...state,
                showNdviLayer: action.payload.showNdviLayer,
            };
        case meparConstants.SET_SHOW_ALERT_LAYER:
            return {
                ...state,
                showAlertLayer: action.payload.showAlertLayer,
            };
        case meparConstants.SET_PRODUCT_DATE:
            return {
                ...state,
                productDate: action.payload.productDate,
            };
        case meparConstants.SET_ALERT_DATE:
            return {
                ...state,
                alertDate: action.payload.alertDate,
            };
        case meparConstants.SET_SELECTED_MEPAR:
            return {
                ...state,
                selectedMepar: action.payload.selectedMepar,
            };
        case meparConstants.SET_MEPARS:
            return {
                ...state,
                mepars: action.payload.mepars,
            };
        case meparConstants.SET_ALERT_MEPARS:
            return {
                ...state,
                alertMepars: action.payload.alertMepars,
            };
        case meparConstants.SET_POLICY_PLOT_TO_DRAW:
            return {
                ...state,
                policyPlotToDraw: action.payload.policyPlot,
            };
        case meparConstants.SET_EDITED_POLYGON:
            return {
                ...state,
                editedPolygon: action.payload.editedPolygon,
            };
        case meparConstants.UPDATE_POLICY_PLOT:
            return {
                ...state,
                mepars: _.cloneDeep(state.mepars),
                selectedMepar: _.cloneDeep(state.selectedMepar),
            };
        case meparConstants.SET_BLOCK_PLOT:
            return {
                ...state,
                blockPlot: action.payload.blockPlot,
            };
        case meparConstants.SET_PRODUCT_TYPE:
            return {
                ...state,
                productType: action.payload.productType,
            };
        case meparConstants.SET_PRODUCT:
            return {
                ...state,
                product: action.payload.product,
            };
        default:
            return state;
    }
}
