import { LAYERS, ROUTES } from 'shared';
import { Fill, Stroke, Style, Text } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Select from 'ol/interaction/Select';
import { click, pointerMove } from 'ol/events/condition';
import { damageActions } from '../../state/actions';

export default class PlotLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new VectorLayer({
            title: 'Táblakörvonal',
            type: LAYERS.TYPES.PLOT,
            visible: false,
            displayInLayerSwitcher: false,
            declutter: true,
            source: new VectorSource(),
            style: (feature) => {
                const text = this.getFeatureText(feature);
                return new Style({
                    stroke: new Stroke({
                        color: 'yellow',
                        width: 2,
                    }),
                    fill: new Fill({
                        color: 'rgba(0,0,0,0.00000001)',
                    }),
                    text: new Text({
                        font: '14px Calibri,sans-serif',
                        fill: new Fill({ color: '#000' }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 2,
                        }),
                        text: text,
                    }),
                });
            },
        });

        this.initInteractions();
        this.addInteractions();
    }

    initInteractions() {
        this.plotLayerPointerSelect = new Select({
            condition: pointerMove,
            style: (feature) => {
                const text = this.getFeatureText(feature);
                return new Style({
                    stroke: new Stroke({
                        color: 'orange',
                        width: 2,
                    }),
                    text: new Text({
                        font: '14px Calibri,sans-serif',
                        fill: new Fill({ color: '#000' }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 2,
                        }),
                        text: text,
                    }),
                });
            },
            layers: [this.layer],
        });

        this.plotLayerClickSelect = new Select({
            condition: click,
            style: new Style({}),

            layers: [this.layer],
        });

        this.plotLayerPointerSelect.on('select', (event) => {
            const features = event.target.getFeatures();
            this.map.props.dispatch(damageActions.setHighlightedDamagePlot(null));
            this.map.props.dispatch(damageActions.setHighlightedDamageMepar(null));

            features.forEach((feature) => {
                if (feature.get('meparCode')) {
                    this.map.props.dispatch(
                        damageActions.setHighlightedDamageMepar(feature.get('meparCode'))
                    );
                } else {
                    this.map.props.dispatch(
                        damageActions.setHighlightedDamagePlot(feature.get('plot'))
                    );
                }
            });
        });

        this.plotLayerClickSelect.on('select', (event) => {
            const features = event.target.getFeatures();
            this.map.props.dispatch(damageActions.setHighlightedDamagePlot(null));
            this.map.props.dispatch(damageActions.setHighlightedDamageMepar(null));
            features.forEach((feature) => {
                if (feature.get('plot') === this.map.props.damagePlot) {
                    return;
                }
                if (feature.get('meparCode')) {
                    const meparCode = feature.get('meparCode');
                    if (meparCode) {
                        this.map.props.history.push(`${ROUTES.BLOCK(meparCode)}`);
                    }
                } else {
                    const damagePlot = feature.get('plot');
                    if (damagePlot) {
                        this.map.props.history.push(
                            `${ROUTES.DAMAGE(damagePlot.damage.damageNumber)}?damagePlotId=${
                                damagePlot.id
                            }`
                        );
                    }
                }
            });
        });
    }

    addInteractions() {
        this.removeInteractions();
        this.map.map.addInteraction(this.plotLayerPointerSelect);
        this.map.map.addInteraction(this.plotLayerClickSelect);
    }

    removeInteractions() {
        this.map.map.removeInteraction(this.plotLayerPointerSelect);
        this.map.map.removeInteraction(this.plotLayerClickSelect);
    }

    getFeatureText(feature) {
        const damagePlot = this.map.props.damagePlot;
        const featurePlot = feature.get('plot');
        const meparCode = feature.get('meparCode');
        let text = '';
        if (!damagePlot || featurePlot.id !== damagePlot.id) {
            if (meparCode) {
                text = meparCode;
            } else {
                text = `${featurePlot.plantCode} - ${featurePlot.damagedArea} Ha`;
            }
        }

        return text;
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (layer.plots !== this.layer.get('plots') && layer.visible) {
            let plotSource = null;
            let fitSource = null;
            const damagePlot = this.map.props.damagePlot;

            const plots = layer.plots;

            plots.forEach((plot) => {
                if (plot.meparPolygon) {
                    const polygon = JSON.parse(plot.meparPolygon);
                    if (polygon) {
                        if (!plotSource) {
                            plotSource = new VectorSource();
                        }
                        const feature = new Feature({
                            geometry:
                                polygon.type === 'Polygon'
                                    ? new Polygon(polygon.coordinates)
                                    : new MultiPolygon(polygon.coordinates),
                            plot,
                            meparCode: plot.meparCode,
                        });
                        plotSource.addFeature(feature);

                        if (damagePlot && damagePlot.meparCode === plot.meparCode) {
                            fitSource = new VectorSource();
                            fitSource.addFeature(feature);
                        }
                    }
                }
            });

            const areas = layer.damagePlotAreas;

            if (areas) {
                areas.forEach((area) => {
                    const geom = JSON.parse(area.geom);
                    if (!plotSource) {
                        plotSource = new VectorSource();
                    }
                    const feature = new Feature({
                        geometry:
                            geom.type === 'Polygon'
                                ? new Polygon(geom.coordinates)
                                : new MultiPolygon(geom.coordinates),
                        area,
                    });

                    feature.setStyle(
                        new Style({
                            stroke: new Stroke({
                                color: area.color,
                                width: 2,
                            }),
                            fill: new Fill({
                                color: 'rgba(0,0,0,0.00000001)',
                            }),
                        })
                    );

                    plotSource.addFeature(feature);
                });
            }

            if (plotSource) {
                this.layer.setSource(plotSource);
                this.layer.set('plots', plots);

                if (layer.fittingLayer) {
                    this.map.map
                        .getView()
                        .fit(
                            fitSource ? fitSource.getExtent() : plotSource.getExtent(),
                            this.map.map.getSize()
                        );
                }
            }
        }
        this.layer.setVisible(layer.visible);
    }
}
