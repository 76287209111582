import { LAYERS } from 'shared';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { Fill, Stroke, Style, Text } from 'ol/style';

export default class WoodwiserForestUnitLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new VectorLayer({
            title: 'Terület határa',
            type: LAYERS.TYPES.WOODWISER_FOREST_UNIT,
            visible: true,
            displayInLayerSwitcher: true,
            source: new VectorSource(),
            style: (feature) => {
                const forestUnit = feature.get('forestUnit');
                return new Style({
                    stroke: new Stroke({
                        color: '#0984ff',
                        width: 2,
                    }),
                    text: new Text({
                        font: '14px Calibri,sans-serif',
                        fill: new Fill({ color: '#000' }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 2,
                        }),
                        text: `${forestUnit.settlement} ${forestUnit.tag}${
                            forestUnit.subTag ? `/${forestUnit.subTag}` : ''
                        }`,
                    }),
                });
            },
        });
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (layer.forestUnits !== this.layer.get('forestUnits') && layer.forestUnits) {
            let forestUnitSource = null;

            const forestUnits = layer.forestUnits;

            forestUnits.forEach((forestUnit) => {
                if (forestUnit.polygon) {
                    const polygon = JSON.parse(forestUnit.polygon);
                    if (polygon) {
                        if (!forestUnitSource) {
                            forestUnitSource = new VectorSource();
                        }
                        const feature = new Feature({
                            geometry:
                                polygon.type === 'Polygon'
                                    ? new Polygon(polygon.coordinates)
                                    : new MultiPolygon(polygon.coordinates),
                            forestUnit,
                        });
                        forestUnitSource.addFeature(feature);
                    }
                }
            });

            if (forestUnitSource) {
                this.layer.setSource(forestUnitSource);
                this.layer.set('forestUnits', forestUnits);

                if (layer.fittingLayer) {
                    this.map.map
                        .getView()
                        .fit(forestUnitSource.getExtent(), this.map.map.getSize());
                }
            }
        }
    }
}
