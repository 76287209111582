import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Button, Divider, IconButton, List, ListItem, Typography } from '@material-ui/core';
import { Add, CropLandscape, Delete, Done, Edit } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { LAYERS } from 'shared/src/constants/layers';
import { LOGS } from 'shared/src/constants/logs';
import {
    damageActions,
    damagePlotAreaActions,
    drawingActions,
    mapActions,
} from '../../state/actions';
import { damageService } from '../../services';
import { logService } from '../../services/logService';
import { getHectareGeomAreaFromDrawing, roundHectareArea } from '../../helpers/areaCalculator';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';

const styles = (theme) => ({
    panelLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    groupTitle: {
        fontSize: '18px',
    },
    description: {
        fontSize: '14px',
        paddingBottom: '30px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    divider: {
        marginTop: '10px;',
    },
    area: {
        marginLeft: '10px',
        flexGrow: 1,
        minWidth: '100px',
        fontSize: '13px',
    },
});

const DrawingsPanel = (props) => {
    const { classes, damage, damagePlot, damagePlotAreas, drawing } = props;
    const { showModal } = useModals();
    const [editing, setEditing] = useState(false);

    const onNewDrawing = async () => {
        setEditing('new');
        if (drawing) {
            await logService.saveLog(LOGS.TYPES.DRAWING_FINISHED, damagePlot && damagePlot.id);
        }
        props.dispatch(drawingActions.setDrawing({ type: 'Polygon' }));
        await logService.saveLog(LOGS.TYPES.DRAWING_NEW_STARTED, damagePlot && damagePlot.id);
    };

    // onOpenMeparPolygon = () => async () => {
    //     const damagePlot = this.props.damagePlot;
    //     const drawing = damagePlot.meparPolygon && JSON.parse(damagePlot.meparPolygon);
    //     this.props.dispatch(drawingActions.setDrawing(drawing));
    //     await logService.saveLog(
    //         LOGS.TYPES.DRAWING_MEPAR_STARTED,
    //         this.props.damagePlot && this.props.damagePlot.id
    //     );
    // };

    const onOpenAreaForDrawing = async (area) => {
        setEditing(area.id);
        const drawing = JSON.parse(area.geom);
        props.dispatch(drawingActions.setDrawing(drawing));
        await logService.saveLog(LOGS.TYPES.DRAWING_PLOT_STARTED, area.id);
    };

    const onCloseArea = async (areaId) => {
        setEditing(false);

        if (typeof drawing.coordinates === 'undefined') {
            props.dispatch(drawingActions.setDrawing(null));
            return;
        }

        const geom = {
            type: 'MultiPolygon',
            coordinates: drawing.type === 'Polygon' ? [drawing.coordinates] : drawing.coordinates,
        };
        const dbGeom = JSON.stringify(geom);
        const dbArea = getHectareGeomAreaFromDrawing(geom);

        const savedArea = await damageService.saveDamagePlotArea(
            damagePlot.id,
            dbGeom,
            dbArea,
            areaId
        );
        props.dispatch(damagePlotAreaActions.saveDamagePlotArea(savedArea));

        const updatedDamagePlot = await damageService.getDamagePlot(damagePlot.id);

        props.dispatch(damageActions.updateDamagePlot(updatedDamagePlot));

        props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.PLOT,
                visible: true,
                plots: [...damage.damagePlots],
                damagePlotAreas: [
                    ...damagePlotAreas.filter((a) => a.id !== savedArea.id),
                    savedArea,
                ],
                damage,
                damagePlot,
                fittingLayer: true,
            })
        );

        props.dispatch(drawingActions.setDrawing(null));

        await logService.saveLog(LOGS.TYPES.DRAWING_FINISHED, damagePlot && damagePlot.id);
    };

    const onConfirmDelete = async (areaId) => {
        showModal(MODAL_TYPE.NONE);

        const { removed } = await damageService.removeDamagePlotArea(areaId, damagePlot.id);

        props.dispatch(damagePlotAreaActions.removeDamagePlotArea(parseInt(removed, 10)));

        const updatedDamagePlot = await damageService.getDamagePlot(damagePlot.id);

        props.dispatch(damageActions.updateDamagePlot(updatedDamagePlot));
        props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.PLOT,
                visible: true,
                plots: [...damage.damagePlots],
                damagePlotAreas: [...damagePlotAreas.filter((a) => a.id !== parseInt(removed, 10))],
                damage,
                damagePlot,
                fittingLayer: true,
            })
        );
    };

    const onDeletePlotPolygon = (areaId) => {
        showModal(MODAL_TYPE.CONFIRM, {
            title: 'Terület törlése',
            message: 'Biztosan törölni szeretné?',
            onConfirm: () => onConfirmDelete(areaId),
            onCancel: () => showModal(MODAL_TYPE.NONE),
        });
    };

    const onConfirmSaveMeparAsArea = async () => {
        showModal(MODAL_TYPE.NONE);

        const geom = damagePlot.mepar.geom;
        const area = damagePlot.mepar.geomHa;
        const previousAreas = damagePlotAreas && damagePlotAreas.length > 0 ? true : false;

        const meparAsArea = await damageService.saveMeparAsArea(
            damagePlot.id,
            area,
            previousAreas
        );

        props.dispatch(damagePlotAreaActions.setDamagePlotAreas([meparAsArea]));

        const updatedDamagePlot = await damageService.getDamagePlot(damagePlot.id);

        props.dispatch(damageActions.updateDamagePlot(updatedDamagePlot));

        props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.PLOT,
                visible: true,
                plots: [...damage.damagePlots],
                damagePlotAreas: [meparAsArea],
                damage,
                damagePlot,
                fittingLayer: true,
            })
        );
    };

    const onSaveMeparAsArea = () => {
        showModal(MODAL_TYPE.CONFIRM, {
            title: 'Terület mentése',
            message:
                'A korábban megrajzolt területek elvesznek. Biztosan az egész MePAR blokkot szeretné kásrosodottként megjelölni?',
            onConfirm: onConfirmSaveMeparAsArea,
            onCancel: () => showModal(MODAL_TYPE.NONE),
        });
    };

    return (
        <div className={classes.panelLayout}>
            <List>
                <ListItem>
                    <div className={classes.layout}>
                        <div className={classes.row}>
                            <Typography className={classes.label}>MePAR kód:</Typography>
                            <Typography className={classes.value}>
                                {damagePlot.mepar.meparCode}
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>MePAR terület:</Typography>
                            <Typography className={classes.value}>
                                {`${roundHectareArea(damagePlot.mepar.geomHa)} ha`}
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Tábla terület:</Typography>
                            <Typography className={classes.value}>
                                {!damagePlotAreas || damagePlotAreas.length === 0
                                    ? 'Rajzolás szükséges!'
                                    : `${roundHectareArea(damagePlot.tableGeometryHa)} ha`}
                            </Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Kár. terület:</Typography>
                            <Typography className={classes.value}>
                                {`${damagePlot.damagedArea} ha`}
                            </Typography>
                        </div>
                    </div>
                </ListItem>
                <Divider />
                {/* <ListItem>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.button}
                            onClick={this.onOpenMeparPolygon(drawing)}
                        >
                            {`MePAR határvonal módosítása`}
                        </Button>
                    </ListItem>
                    <Divider className={classes.divider} /> */}
                <ListItem>
                    <Typography className={classes.groupTitle}>Károsodott területek</Typography>
                </ListItem>
                <ListItem>
                    <Button
                        variant="contained"
                        color="default"
                        size="small"
                        disabled={damagePlot.tableAsMepar}
                        className={classes.button}
                        onClick={onSaveMeparAsArea}
                    >
                        {`Teljes MePAR határvonal kijelölése károsodottként`}
                    </Button>
                </ListItem>
                {damagePlotAreas &&
                    damagePlotAreas.map((area) => (
                        <ListItem>
                            <CropLandscape
                                style={{
                                    color: area.color,
                                    fontSize: '36px',
                                }}
                            />
                            <Typography className={classes.area}>
                                Terület: {roundHectareArea(area.area)} ha
                            </Typography>
                            {editing === area.id ? (
                                <IconButton onClick={() => onCloseArea(area.id)}>
                                    <Done />
                                </IconButton>
                            ) : (
                                <IconButton onClick={() => onOpenAreaForDrawing(area)} disabled={editing}>
                                    <Edit />
                                </IconButton>
                            )}
                            <IconButton
                                onClick={() => onDeletePlotPolygon(area.id)}
                                disabled={editing}
                            >
                                <Delete />
                            </IconButton>
                        </ListItem>
                    ))}
                <ListItem>
                    <CropLandscape
                        style={{
                            color: 'rgba(0,0,0,0)',
                            fontSize: '36px',
                        }}
                    />
                    <Typography className={classes.area}>
                        Összterület: {roundHectareArea(damagePlot.tableGeometryHa)} ha
                    </Typography>
                </ListItem>
                {damagePlotAreas !== null &&
                    damagePlotAreas.length < 10 &&
                    !damagePlot.tableAsMepar && (
                        <ListItem>
                            {editing === 'new' ? (
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    className={classes.button}
                                    onClick={() => onCloseArea(0)}
                                >
                                    <Done /> Kész
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    className={classes.button}
                                    onClick={() => onNewDrawing(drawing)}
                                    disabled={editing}
                                >
                                    <Add /> További/Új terület rajzolása
                                </Button>
                            )}
                        </ListItem>
                    )}
            </List>
        </div>
    );
};

export default withStyles(styles)(withRouter(DrawingsPanel));
