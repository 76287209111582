import { policyConstants } from '../constants';

const initialState = {
    policies: null,
    policy: null,
    policyPlot: null,
};

export function policy(state = initialState, action) {
    switch (action.type) {
        case policyConstants.SET_POLICIES:
            return {
                ...state,
                policies: action.payload.policies,
            };
        case policyConstants.SET_POLICY:
            return {
                ...state,
                policy: action.payload.policy,
                policyPlot: action.payload.policyPlot,
            };
        case policyConstants.SET_POLICY_PLOT:
            return {
                ...state,
                policyPlot: action.payload.policyPlot,
            };
        default:
            return state;
    }
}
