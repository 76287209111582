import { List, ListItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { mapActions } from '../../state/actions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SaveAlt from '@material-ui/icons/SaveAlt';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button/Button';
import { Link } from 'react-router-dom';
import { ROUTES } from 'shared/src/constants/routes';
import { LAYERS } from 'shared/src/constants/layers';
import { PRODUCTS } from 'shared';
import { logService } from '../../services/logService';
import { LOGS } from 'shared/src/constants/logs';
import { blockActions } from '../../state/actions/blockActions';
import { ROLE } from 'shared/src/constants/role';

const styles = (theme) => ({
    expansion: {
        width: '100%',
    },
    list: {
        paddingTop: '0px',
    },
    listItem: {
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    panelLayout: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    description: {
        fontSize: '14px',
        paddingBottom: '10px',
    },
    label: {
        minWidth: '100px',
        fontSize: '13px',
    },
    value: {
        flex: 1,
        fontSize: '13px',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
    },
    button: {
        width: '100%',
    },
    drawingButton: {
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
    },
    productRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    productListWrapper: {
        flex: 1,
        overflow: 'auto',
    },
    productList: {},
    dividerTop: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    dividerBottom: {
        marginTop: '10px',
    },
    beforeDate: {
        fontSize: '9px',
    },
});

class BlockProductPanel extends React.Component {
    getLabelFromSubType(subType) {
        if (subType === 'BMI') {
            return 'Biomassza intenzitás';
        }

        if (subType === 'TCI') {
            return 'Látható felvétel';
        }

        return subType;
    }

    onShowProduct = (product) => () => {
        this.props.dispatch(blockActions.setProduct(product));
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.PRODUCT,
                visible: true,
                product,
                block: this.props.block,
            })
        );
    };

    onProductDownload = (type, resource) => async () => {
        await logService.saveLog(type, resource);
    };

    render() {
        const { classes, block, productType, product, user } = this.props;
        moment.locale('hu');

        if (!block || !productType) {
            return null;
        }

        const meparBorderIsMissing = !block.meparPolygon;

        const productTypeMap = block.products
            ? block.products.reduce((accumulator, product) => {
                  const productsInType = accumulator[product.type] || [];
                  productsInType.push(product);
                  return Object.assign(accumulator, {
                      [product.type]: productsInType,
                  });
              }, {})
            : {};

        return (
            <div className={classes.panelLayout}>
                <List className={classes.list}>
                    <ListItem>
                        <div className={classes.layout}>
                            {meparBorderIsMissing && (
                                <div className={classes.row}>
                                    <Typography className={classes.redLabel}>
                                        A MePAR körvonal hiányzik!
                                    </Typography>
                                </div>
                            )}
                            <div className={classes.row}>
                                <Typography className={classes.label}>MePAR kód:</Typography>
                                <Typography className={classes.value}>
                                    <Link
                                        className={classes.value}
                                        to={ROUTES.BLOCK(block.meparCode)}
                                    >
                                        {block.meparCode}
                                    </Link>
                                </Typography>
                            </div>
                        </div>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <img src={PRODUCTS.DETAILS[productType].legend} width={'290px'} alt={''} />
                    </ListItem>
                </List>
                <div className={classes.productListWrapper}>
                    <List className={classes.productList}>
                        {product && productTypeMap[product.type].length === 1 && (
                            <>
                                {product.dateBefore && (
                                    <ListItem>
                                        <div className={classes.layout}>
                                            <div className={classes.row}>
                                                <Typography className={classes.label}>
                                                    Előtte felvétel:
                                                </Typography>
                                                <Typography className={classes.value}>
                                                    {`${moment(product.dateBefore).format(
                                                        'YYYY. MM. DD.'
                                                    )}`}
                                                </Typography>
                                            </div>
                                            <div className={classes.row}>
                                                <Typography className={classes.label}>
                                                    Utána felvétel:
                                                </Typography>
                                                <Typography className={classes.value}>
                                                    {`${moment(product.date).format(
                                                        'YYYY. MM. DD.'
                                                    )}`}
                                                </Typography>
                                            </div>
                                        </div>
                                    </ListItem>
                                )}
                                {user.role !== ROLE.FARMER && (
                                    <ListItem>
                                        <form
                                            method="get"
                                            action={`/pdfs/${product.fileName}`}
                                            target="_blank"
                                        >
                                            <Button
                                                variant="contained"
                                                color="default"
                                                size="small"
                                                type="submit"
                                            >
                                                {`Letöltés`}
                                                <SaveAlt />
                                            </Button>
                                        </form>
                                    </ListItem>
                                )}
                            </>
                        )}
                        {product &&
                            productTypeMap[product.type].length > 1 &&
                            productTypeMap[product.type].map((productItem) => (
                                <ListItem key={productItem.id}>
                                    <div className={classes.productRow} key={productItem.id}>
                                        <Button
                                            className={classes.button}
                                            variant="contained"
                                            color={
                                                product.id === productItem.id
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                            size="small"
                                            onClick={this.onShowProduct(productItem)}
                                        >
                                            {productItem.date ? (
                                                productItem.subType && user.role !== ROLE.FARMER ? (
                                                    <>
                                                        {this.getLabelFromSubType(
                                                            productItem.subType
                                                        )}
                                                        <br />
                                                        {moment(productItem.date).format(
                                                            'YYYY. MM. DD.'
                                                        )}
                                                    </>
                                                ) : (
                                                    moment(productItem.date).format('YYYY. MM. DD.')
                                                )
                                            ) : productItem.dateBefore ? null : (
                                                'Megjelenít'
                                            )}
                                            {productItem.dateBefore &&
                                                productItem.type !== 'Alert' && (
                                                    <>
                                                        <br />
                                                        {`${moment(productItem.dateBefore).format(
                                                            'YYYY. MM. DD.'
                                                        )} - ${moment(productItem.date).format(
                                                            'YYYY. MM. DD.'
                                                        )}`}
                                                    </>
                                                )}
                                            {productItem.dateBefore &&
                                                productItem.type === 'Alert' && (
                                                    <div>
                                                        <div>
                                                            {moment(productItem.date).format(
                                                                'YYYY. MM. DD.'
                                                            )}
                                                        </div>
                                                        <div className={classes.beforeDate}>
                                                            Előtte:{' '}
                                                            {moment(productItem.dateBefore).format(
                                                                'YYYY. MM. DD.'
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                        </Button>
                                        {user.role !== ROLE.FARMER && (
                                            <form
                                                method="get"
                                                action={`/pdfs/${productItem.fileName}`}
                                                target="_blank"
                                            >
                                                <IconButton
                                                    variant="contained"
                                                    color="default"
                                                    size="small"
                                                    type="submit"
                                                    onClick={this.onProductDownload(
                                                        LOGS.TYPES.GET_PRODUCT_PDF,
                                                        productItem.fileName
                                                    )}
                                                >
                                                    <SaveAlt />
                                                </IconButton>
                                            </form>
                                        )}
                                    </div>
                                </ListItem>
                            ))}
                    </List>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.user;
    const { block, productType, product } = state.block;
    return {
        user,
        block,
        productType,
        product,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(BlockProductPanel)));
