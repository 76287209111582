import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuBar from '../menus/MenuBar';
import Div100vh from 'react-div-100vh';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { ROLE, VIEWS } from 'shared';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    align: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    progressBar: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1000,
        width: '100%',
        height: '100%',
        display: 'flex',
        marginTop: '30px',
        justifyContent: 'space-around',
    },
    progress: {
        marginTop: '100px',
    },
}));

export default function MainScreen(props) {
    const { children } = props;
    const classes = useStyles();
    const viewState = useSelector((state) => state.view.viewState);
    const user = useSelector((state) => state.user.user);

    return (
        <Div100vh style={{ height: '100rvh', width: '100%' }} className={classes.layout}>
            <MenuBar />
            {viewState &&
                viewState.view === VIEWS.PROGRESS &&
                user &&
                user.role === ROLE.CEMETERY_PUBLIC && (
                    <div className={classes.progressBar}>
                        <CircularProgress className={classes.progress} color="primary" />
                    </div>
                )}
            {children}
        </Div100vh>
    );
}
