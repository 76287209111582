import { policyConstants } from '../constants';

export const policyActions = {
    setPolicies,
    setPolicy,
    setPolicyPlot,
};

function setPolicies(policies) {
    return {
        type: policyConstants.SET_POLICIES,
        payload: {
            policies,
        },
    };
}

function setPolicy(policy, policyPlot = null) {
    return {
        type: policyConstants.SET_POLICY,
        payload: {
            policy,
            policyPlot,
        },
    };
}

function setPolicyPlot(policyPlot) {
    return {
        type: policyConstants.SET_POLICY_PLOT,
        payload: {
            policyPlot,
        },
    };
}
