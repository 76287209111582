import React, { useContext } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import TextureIcon from '@material-ui/icons/Texture';
import { GoogleMapContext } from '@googlemap-react/core';
import { meparActions } from '../../../state/actions/meparActions';
import { dialogActions } from '../../../state/actions/dialogActions';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import ViewQuiltOutlinedIcon from '@material-ui/icons/ViewQuiltOutlined';
import { farmerModule } from 'shared/src/modules/farmerModule';

const useStyles = makeStyles((theme) => ({
    layoutGroup: {
        '& > .MuiButton-root': {
            minWidth: '20px',
            padding: '5px',
        },
        '& > .MuiButton-containedSizeSmall': {
            padding: '5px',
        },
        '& > .MuiButtonGroup-grouped': {
            minWidth: '20px',
        },
    },
    layoutPlotGroup: {
        position: 'absolute',
        bottom: '180px',
        right: '10px',
    },
    layoutProductsGroup: {
        position: 'absolute',
        bottom: '100px',
        right: '10px',
    },
    layoutZoomGroup: {
        position: 'absolute',
        bottom: '20px',
        right: '10px',
    },
    selectedAlert: {
        '&.MuiButton-contained': {
            color: 'rgba(194, 27, 23, 1)',
        },
    },
    selectedNdvi: {
        '&.MuiButton-contained': {
            color: '#014FFF',
        },
    },
    button: {
        '&.MuiButton-contained': {
            backgroundColor: 'rgb(224, 224, 224)',
        },
    },
}));

export default function MapButtons(props) {
    const classes = useStyles();
    const {
        state: { map },
    } = useContext(GoogleMapContext);
    const dispatch = useDispatch();
    const showNdviLayer = useSelector((state) => state.mepar.showNdviLayer);
    const showAlertLayer = useSelector((state) => state.mepar.showAlertLayer);
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const productDate = useSelector((state) => state.mepar.productDate);
    const policyPlotToDraw = useSelector((state) => state.mepar.policyPlotToDraw);

    const enableAlertButton =
        selectedMepar &&
        productDate &&
        farmerModule.getMeparAlertsForDate(selectedMepar, productDate).length > 0;
    const enableNdviButton =
        selectedMepar &&
        productDate &&
        farmerModule.getMeparNdvisForDate(selectedMepar, productDate).length > 0 &&
        true; /* (farmerModule.meparDateHasAlert(selectedMepar, productDate) || farmerModule.meparHasSomeTableGeometries(selectedMepar)) */

    function handlePolicyPlotsClick() {
        dispatch(dialogActions.showPolicyPlotsDialog());
    }

    function handleAlertClick() {
        dispatch(meparActions.setShowAlertLayer(!showAlertLayer));
    }

    function handleNdviClick() {
        dispatch(meparActions.setShowNdviLayer(!showNdviLayer));
    }

    function handleZoomInClick() {
        map.setZoom(map.getZoom() + 1);
    }

    function handleZoomOutClick() {
        map.setZoom(map.getZoom() - 1);
    }

    return (
        <>
            <ButtonGroup
                orientation="vertical"
                color="default"
                aria-label="vertical contained primary button group"
                variant="contained"
                className={clsx(classes.layoutPlotGroup, classes.layoutGroup)}
            >
                <Tooltip title={'Táblahatárok kezelése'}>
                    <Button
                        onClick={handlePolicyPlotsClick}
                        disabled={policyPlotToDraw}
                        className={clsx(classes.button)}
                    >
                        <ViewQuiltOutlinedIcon />
                    </Button>
                </Tooltip>
            </ButtonGroup>

            <ButtonGroup
                orientation="vertical"
                color="default"
                aria-label="vertical contained primary button group"
                variant="contained"
                className={clsx(classes.layoutProductsGroup, classes.layoutGroup)}
            >
                <Tooltip title={'Riasztott terület'}>
                    <Button
                        onClick={handleAlertClick}
                        disabled={!enableAlertButton}
                        className={clsx(
                            classes.button,
                            enableAlertButton && showAlertLayer && classes.selectedAlert
                        )}
                    >
                        <NotificationsActiveIcon />
                    </Button>
                </Tooltip>
                <Tooltip title={'Ndvi térkép'}>
                    <Button
                        onClick={handleNdviClick}
                        disabled={!enableNdviButton}
                        className={clsx(
                            classes.button,
                            enableNdviButton && showNdviLayer && classes.selectedNdvi
                        )}
                        variant="contained"
                    >
                        <TextureIcon />
                    </Button>
                </Tooltip>
            </ButtonGroup>
            <ButtonGroup
                orientation="vertical"
                color="default"
                aria-label="vertical contained primary button group"
                variant="contained"
                className={clsx(classes.layoutZoomGroup, classes.layoutGroup)}
            >
                <Tooltip title={'Térkép nagyítása'}>
                    <Button onClick={handleZoomInClick}>
                        <AddIcon />
                    </Button>
                </Tooltip>
                <Tooltip title={'Térkép kicsinyítése'}>
                    <Button onClick={handleZoomOutClick}>
                        <RemoveIcon />
                    </Button>
                </Tooltip>
            </ButtonGroup>
        </>
    );
}
