import React from 'react';
import { makeStyles } from '@material-ui/core';
import { GoogleMapProvider, MapBox } from '@googlemap-react/core';
import MeparLayer from './layers/MeparLayer';
import DrawingManager from './drawing/DrawingManager';
import MissingPlotMessage from './messages/MissingPlotMessage';
import PolicyPlotLayer from './layers/PolicyPlotLayer';
import ProductLayer from './layers/ProductLayer';
import MapButtons from './buttons/MapButtons';
import AlertLayer from './layers/AlertLayer';
import MissingNdviMessage from './messages/MissingNdviMessage';
import LegendMessage from './messages/LegendMessage';
import DrawingMessage from './messages/DrawingMessage';
import LateNdviMessage from './messages/LateNdviMessage';

const useStyles = makeStyles((theme) => ({
    map: {
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'relative',
    },
}));

export default function MyMap() {
    const classes = useStyles();

    return (
        <GoogleMapProvider>
            <div className={classes.map}>
                <MapBox
                    apiKey={'AIzaSyCe-k20In57mAYelB2US7-gqKqZkARhHpA'}
                    style={{
                        flex: 1,
                        width: '100%',
                        height: '100%',
                    }}
                    opts={{
                        mapTypeId: 'hybrid',
                        mapTypeControl: false,
                        streetViewControl: false,
                        fullscreenControl: false,
                        zoomControl: false,
                    }}
                    useDrawing={true}
                />

                <DrawingManager />

                <MapButtons />

                <MissingPlotMessage />
                <MissingNdviMessage />
                <LateNdviMessage />
                <LegendMessage />
                <DrawingMessage />

                <MeparLayer />
                <PolicyPlotLayer />
                <ProductLayer />
                <AlertLayer />
            </div>
        </GoogleMapProvider>
    );
}
