import { LAYERS } from 'shared';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { Fill, Stroke, Style } from 'ol/style';
import Select from 'ol/interaction/Select';
import { pointerMove } from 'ol/events/condition';
import PopupFeature from 'ol-ext/overlay/PopupFeature';

export default class WoodwiserCrownLayer {
    map = null;
    layer = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new VectorLayer({
            title: 'Lombkoronák',
            type: LAYERS.TYPES.WOODWISER_CROWN,
            visible: false,
            displayInLayerSwitcher: true,
            source: new VectorSource(),
            style: (feature) => {
                const crown = feature.get('crown');
                const treeSpecies = feature.get('treeSpecies');
                const treeSpecie = treeSpecies.find(
                    (treeSpecie) => treeSpecie.treeTextCode === crown.species
                );

                if (!treeSpecie) {
                    console.log('Tree specie not found: ', crown.species);
                }

                return new Style({
                    stroke: new Stroke({
                        color: treeSpecie ? treeSpecie.color : '#0fff80',
                        width: 2,
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.1)',
                    }),
                });
            },
        });

        this.initInteractions();
        this.addInteractions();
    }

    initInteractions() {
        this.pointerSelect = new Select({
            condition: pointerMove,
            layers: [this.layer],
        });

        const popup = new PopupFeature({
            select: this.pointerSelect,
            canFix: true,
            template: {
                title: (feature) => {
                    const crown = feature.get('crown');
                    const treeSpecies = feature.get('treeSpecies');
                    const treeSpecie = treeSpecies.find(
                        (treeSpecie) => treeSpecie.treeTextCode === crown.species
                    );

                    return treeSpecie ? treeSpecie.treeHun : 'Ismeretlen fa';
                },
                attributes: {
                    height: {
                        title: 'Famagasság',
                        format: function (value, feature) {
                            const crown = feature.get('crown');
                            return Math.round(crown.height * 100) / 100 + ' m';
                        },
                    },
                    crown: {
                        title: 'Koronaterület',
                        format: function (value, feature) {
                            const crown = feature.get('crown');
                            return Math.round(crown.crownArea * 100) / 100 + ' m2';
                        },
                    },
                    dbh: {
                        title: 'Számított törzsátmérő (DBH)',
                        format: function (value, feature) {
                            const crown = feature.get('crown');
                            return Math.round(crown.dbh * 100) / 100 + ' cm';
                        },
                    },
                    volume: {
                        title: 'Számított fatérfogat',
                        format: function (value, feature) {
                            const crown = feature.get('crown');
                            return Math.round(crown.volume * 100) / 100 + ' m3';
                        },
                    },
                },
            },
        });

        this.map.map.addOverlay(popup);
    }

    addInteractions() {
        this.removeInteractions();
        this.map.map.addInteraction(this.pointerSelect);
    }

    removeInteractions() {
        this.map.map.removeInteraction(this.pointerSelect);
    }

    getLayer() {
        return this.layer;
    }

    update(layer) {
        if (layer.crowns !== this.layer.get('crowns') && layer.crowns) {
            let source = new VectorSource();

            const crowns = layer.crowns;

            crowns.forEach((crown) => {
                if (crown.polygon) {
                    const polygon = JSON.parse(crown.polygon);
                    if (polygon) {
                        const feature = new Feature({
                            geometry:
                                polygon.type === 'Polygon'
                                    ? new Polygon(polygon.coordinates)
                                    : new MultiPolygon(polygon.coordinates),
                            crown,
                            treeSpecies: layer.treeSpecies,
                        });
                        source.addFeature(feature);
                    }
                }
            });

            this.layer.setSource(source);
            this.layer.set('crowns', crowns);
        }
    }
}
