export const mapHelper = {
    getBBox,
    xyzToBounds,
    parseProductUrl,
    getTileUrl,
};

const EXTENT = [-Math.PI * 6378137, Math.PI * 6378137];

function xyzToBounds(x, y, z) {
    const tileSize = (EXTENT[1] * 2) / Math.pow(2, z);
    const minx = EXTENT[0] + x * tileSize;
    const maxx = EXTENT[0] + (x + 1) * tileSize;
    // remember y origin starts at top
    const miny = EXTENT[1] - (y + 1) * tileSize;
    const maxy = EXTENT[1] - y * tileSize;
    return [minx, miny, maxx, maxy];
}

function getBBox(coord, zoom) {
    const bounds = mapHelper.xyzToBounds(coord.x, coord.y, zoom);

    return `${bounds[0]},${bounds[1]},${bounds[2]},${bounds[3]}`;
}

function parseProductUrl(productUrl) {
    const match = productUrl.match('^([^:]*):(.*)$');
    if (!match) {
        throw new Error(`Match failure ${productUrl}`);
    }

    return { group: match[1], layer: productUrl };
}

function getTileUrl(coord, zoom, productUrl) {
    const bbox = mapHelper.getBBox(coord, zoom);

    const { group, layer } = mapHelper.parseProductUrl(productUrl);

    let url = `/geoserver/${group}/wms?`;
    url += 'service=WMS';
    url += '&SERVICE=WMS';
    url += '&REQUEST=GetMap';
    url += '&VERSION=1.3.0';
    url += `&LAYERS=${encodeURIComponent(layer)}`;
    url += `&FORMAT=${encodeURIComponent('image/png')}`;
    url += '&TRANSPARENT=true';
    url += `&SRS=${encodeURIComponent('EPSG:3857')}`;
    url += `&BBOX=${encodeURIComponent(bbox)}`;
    url += '&STYLES=';
    url += '&WIDTH=256';
    url += '&HEIGHT=256';
    return url;
}
