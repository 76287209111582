import { FARMER_VIEWS, ROUTES } from '../constants';
import moment from 'moment';
import { PRODUCTS } from '../constants/products';

class FarmerModule {
    getDefaultViewState(mepars) {
        const productDates = this.getNdviDates(mepars);
        const alertDates = this.getAlertDates(mepars);

        if (productDates.length > 0 && alertDates.length > 0 && productDates[0] === alertDates[0]) {
            return FARMER_VIEWS.ALERT;
        }

        return FARMER_VIEWS.NDVI;
    }

    getDefaultMeparViewState(mepar) {
        const productDates = this.getMeparNdviDates(mepar);
        const alertDates = this.getMeparAlertDates(mepar);

        if (productDates.length > 0 && alertDates.length > 0 && productDates[0] === alertDates[0]) {
            return FARMER_VIEWS.ALERT;
        }

        return FARMER_VIEWS.NDVI;
    }

    getDefaultMeparForViewState(viewState, mepars) {
        if (viewState === FARMER_VIEWS.NDVI) {
            return mepars && mepars[0];
        }

        const alertDates = this.getAlertDates(mepars);
        const alertMepars = this.getAlertMepars(mepars, alertDates[0]);
        return alertMepars[0];
    }

    getAlertMepars(mepars, alertDate) {
        return mepars.filter((mepar) => {
            return mepar.products.find(
                (product) => product.type === PRODUCTS.TYPES.ALERT && product.date === alertDate
            );
        });
    }

    getNdviDates(mepars) {
        return this.getProductDates(mepars, PRODUCTS.TYPES.CHRONOLOGY);
    }

    getAlertDates(mepars) {
        return this.getProductDates(mepars, PRODUCTS.TYPES.ALERT);
    }

    getProductDates(mepars, productType) {
        const datesMap = {};

        mepars.forEach((mepar) => {
            this.mapMeparProductDates(mepar, productType, datesMap);
        });

        const dates = Object.keys(datesMap);
        const sortedDates = this.sortDates(dates);
        return sortedDates;
    }

    mapMeparProductDates(mepar, productType, datesMap) {
        const products = mepar.products.filter((product) => product.type === productType);
        if (products) {
            products.forEach((product) => {
                if (!datesMap[product.date]) {
                    datesMap[product.date] = true;
                }
            });
        }
    }

    getMeparNdviDates(mepar) {
        return this.getMeparProductDates(mepar, PRODUCTS.TYPES.CHRONOLOGY);
    }

    getMeparAlertDates(mepar) {
        return this.getMeparProductDates(mepar, PRODUCTS.TYPES.ALERT);
    }

    getMeparAlertsForDate(mepar, productDate) {
        return mepar.products.filter(
            (product) => product.type === PRODUCTS.TYPES.ALERT && product.date === productDate
        );
    }

    getMeparNdvisForDate(mepar, productDate) {
        return mepar.products.filter(
            (product) => product.type === PRODUCTS.TYPES.CHRONOLOGY && product.date === productDate
        );
    }

    getMeparProductDates(mepar, productType) {
        const datesMap = {};
        this.mapMeparProductDates(mepar, productType, datesMap);

        const dates = Object.keys(datesMap);
        const sortedDates = this.sortDates(dates);
        return sortedDates;
    }

    sortDates(dates) {
        return dates.sort((a, b) => {
            if (moment(a).isBefore(moment(b))) {
                return 1;
            }
            if (moment(a).isAfter(moment(b))) {
                return -1;
            }
            return 0;
        });
    }

    getLatestAlertDate(alerts) {
        let latestAlertDate = null;
        alerts.forEach((alert) => {
            if (!latestAlertDate) {
                latestAlertDate = alert.date;
                return;
            }

            if (moment(latestAlertDate).isBefore(moment(alert.date))) {
                latestAlertDate = alert.date;
            }
        });
        return latestAlertDate;
    }

    parseViewState(view) {
        switch (view) {
            case 'ndvi':
                return FARMER_VIEWS.NDVI;
            case 'alert':
                return FARMER_VIEWS.ALERT;
            default:
                throw new Error(`Unknown view ${view}`);
        }
    }

    parseProductDate(date) {
        const dateIsValid = moment(date, 'YYYY-MM-DD', true).isValid();
        if (dateIsValid) {
            return date;
        }

        return null;
    }

    composeUrl(mepar, viewState, productDate, alertDate) {
        return `${ROUTES.BLOCK(mepar.meparCode)}?view=${
            viewState === FARMER_VIEWS.ALERT ? 'alert' : 'ndvi'
        }${viewState === FARMER_VIEWS.ALERT ? `&date=${alertDate}` : ''}`;
    }

    meparHasAllTableGeometries(mepar) {
        return mepar.policyPlots.every((policyPlot) => policyPlot.tableGeometry);
    }

    meparHasSomeTableGeometries(mepar) {
        return mepar.policyPlots.some((policyPlot) => policyPlot.tableGeometry);
    }

    meparDateHasAlert(mepar, productDate) {
        return farmerModule.getMeparAlertDates(mepar).includes(productDate);
    }

    isLatestNdviLate(mepar) {
        const ndviDates = this.getMeparNdviDates(mepar) || [];
        if (ndviDates.length === 0) {
            return false;
        }
        return moment(ndviDates[0]).add(21, 'day').isBefore(moment());
    }
}

export const farmerModule = new FarmerModule();
