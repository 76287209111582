import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import NavigationPreviousButton from './NavigationPreviousButton';
import NavigationNextButton from './NavigationNextButton';
import MeparChooser from './MeparChooser';
import { useSelector } from 'react-redux';
import NdviDateChooser from './NdviDateChooser';

const useStyles = makeStyles((theme) => ({
    layout: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 10px',
        alignItems: 'center',
        backgroundColor: '#DBD9DB',
        height: '42px',
        '& > *:not(:first-child)': {
            marginLeft: '10px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '46px',
        },
    },
    centerLayout: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > *:not(:first-child)': {
            marginLeft: '10px',
        },
    },
    text: {
        color: 'rgba(0, 0, 0, 0.87)',
    },
}));

export default function NavigationBar() {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(true);
    const policyPlotToDraw = useSelector((state) => state.mepar.policyPlotToDraw);
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);

    useEffect(() => {
        if (policyPlotToDraw) {
            setIsOpen(false);
            return;
        }

        setIsOpen(true);
    }, [policyPlotToDraw]);

    if (!isOpen) {
        return null;
    }

    return (
        <div className={classes.layout}>
            <NavigationPreviousButton />
            <div className={classes.centerLayout}>
                {selectedMepar && (
                    <>
                        <MeparChooser />
                        <NdviDateChooser />
                    </>
                )}
            </div>
            <NavigationNextButton />
        </div>
    );
}
