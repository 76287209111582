import React from 'react';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { ROUTES } from 'shared';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    layout: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 200,
    },
    dropdown: {
        maxHeight: '300px',
        overflow: 'auto',
    },
    button: {
        '&.MuiButton-root': {
            textTransform: 'none',
        },
    },
}));

export default function MeparChooser() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const mepars = useSelector((state) => state.mepar.mepars);
    const history = useHistory();

    const handleMenuItemClick = (event, mepar) => {
        history.push(ROUTES.BLOCK(mepar.meparCode));
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <div className={classes.layout}>
            <Button onClick={handleToggle} size="small" ref={anchorRef} className={classes.button}>
                {selectedMepar.meparCode}
                <ArrowDropDownIcon />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper className={classes.dropdown}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {mepars.map((mepar, index) => (
                                        <MenuItem
                                            key={mepar.id}
                                            selected={mepar.meparCode === selectedMepar.meparCode}
                                            onClick={(event) => handleMenuItemClick(event, mepar)}
                                        >
                                            {mepar.meparCode}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}
