import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { damageActions, mapActions, viewActions } from '../state/actions';
import { LAYERS } from 'shared/src/constants/layers';
import { VIEWS } from 'shared/src/constants/views';
import { damageService, layerService } from '../services';
import moment from 'moment';
import { radarActions } from '../state/actions/radarActions';

class RadarController extends React.Component {
    componentDidMount() {
        return this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.match.params.id !== prevProps.match.params.id ||
            this.props.location.search !== prevProps.location.search
        ) {
            return this.getData();
        }
    }

    async getData() {
        const { location } = this.props;

        this.props.dispatch(viewActions.setInfoPanelState({ open: true }));
        this.props.dispatch(viewActions.setViewState({ view: VIEWS.PROGRESS }));

        this.props.dispatch(mapActions.resetLayers());

        let searchParams = new URLSearchParams(location.search);
        const damagePlotId = parseInt(searchParams.get('damagePlotId'), 10);

        if (!damagePlotId) {
            this.props.dispatch(
                mapActions.updateLayer({
                    type: LAYERS.TYPES.COUNTRY,
                    visible: true,
                    fittingLayer: true,
                })
            );
        } else {
            this.props.dispatch(
                mapActions.updateLayer({
                    type: LAYERS.TYPES.COUNTRY,
                    visible: true,
                    fittingLayer: false,
                })
            );
        }

        if (damagePlotId) {
            const damagePlot = await damageService.getDamagePlot(damagePlotId);
            const damage = await damageService.getDamage(damagePlot.damage.damageNumber);

            this.props.dispatch(damageActions.setDamage(damage, damagePlot));

            if (JSON.parse(damagePlot.meparPolygon)) {
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.PLOT,
                        visible: true,
                        plots: [damagePlot],
                        damage,
                        damagePlot,
                        fittingLayer: true,
                    })
                );
            } else {
                this.props.dispatch(
                    mapActions.updateLayer({ type: LAYERS.TYPES.PLOT, visible: false })
                );
            }
        }

        const { radars, damagePlotRadars } = await layerService.getRadars(
            null,
            damagePlotId || null
        );
        if (radars && radars.length > 0) {
            const selectedRadarDate =
                damagePlotRadars && damagePlotRadars.length > 0
                    ? moment(damagePlotRadars[0].dateRadar)
                    : moment(radars[0].dateRadar);
            const { radars: radarsToDisplay, mepars } = await layerService.getRadars(
                selectedRadarDate
            );
            this.props.dispatch(
                radarActions.setRadars(
                    radars,
                    damagePlotRadars,
                    selectedRadarDate,
                    radarsToDisplay,
                    mepars
                )
            );
            this.props.dispatch(
                mapActions.updateLayer({
                    type: LAYERS.TYPES.RADAR,
                    visible: true,
                    radars: radarsToDisplay,
                    fittingLayer: false,
                })
            );
            if (mepars && mepars.length > 0) {
                this.props.dispatch(
                    mapActions.updateLayer({
                        type: LAYERS.TYPES.PLOT,
                        visible: true,
                        plots: mepars,
                        fittingLayer: false,
                    })
                );
            } else {
                this.props.dispatch(
                    mapActions.updateLayer({ type: LAYERS.TYPES.PLOT, visible: false })
                );
            }
        }

        this.props.dispatch(viewActions.setViewState({ view: VIEWS.RADAR }));
    }

    render() {
        return null;
    }
}

export default connect()(withRouter(RadarController));
