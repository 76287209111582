import { damageConstants } from '../constants';

const initialState = {
    damage: null,
    damagePlot: null,
    productType: null,
    product: null,
    highlightedDamagePlot: null,
    highlightedDamageMepar: null,
    manageDrawings: false,
    manageSamplings: false,
};

export function damage(state = initialState, action) {
    switch (action.type) {
        case damageConstants.SET_DAMAGE:
            return {
                damage: action.payload.damage,
                damagePlot: action.payload.damagePlot,
                productType: action.payload.productType,
                manageDrawings: action.payload.manageDrawings,
                manageSamplings: action.payload.manageSamplings,
                product: null,
                highlightedDamagePlot: null,
                highlightedDamageMepar: null,
            };
        case damageConstants.SET_DAMAGE_PLOT:
            return {
                ...state,
                damagePlot: action.payload.damagePlot,
                productType: null,
                product: null,
                highlightedDamagePlot: null,
                highlightedDamageMepar: null,
                manageDrawings: false,
                manageSamplings: false,
            };
        case damageConstants.UPDATE_DAMAGE_PLOT:
            return {
                ...state,
                damagePlot: action.payload.damagePlot,
            };
        case damageConstants.SET_PRODUCT_TYPE:
            return {
                ...state,
                productType: action.payload.productType,
                product: null,
                highlightedDamagePlot: null,
                highlightedDamageMepar: null,
                manageDrawings: false,
                manageSamplings: false,
            };
        case damageConstants.SET_PRODUCT:
            return {
                ...state,
                product: action.payload.product,
                highlightedDamagePlot: null,
                highlightedDamageMepar: null,
                manageDrawings: false,
                manageSamplings: false,
            };
        case damageConstants.SET_HIGHLIGHTED_DAMAGE_PLOT:
            return {
                ...state,
                highlightedDamagePlot: action.payload.highlightedDamagePlot,
                highlightedDamageMepar: null,
            };
        case damageConstants.SET_HIGHLIGHTED_DAMAGE_MEPAR:
            return {
                ...state,
                highlightedDamagePlot: null,
                highlightedDamageMepar: action.payload.highlightedDamageMepar,
            };
        default:
            return state;
    }
}
