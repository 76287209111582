import { LAYERS } from 'shared';
import { Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { geometryService } from '../../services';
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';

export default class CountryLayer {
    map = null;
    layer = null;
    countrySource = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new VectorLayer({
            title: 'Országhatár',
            type: LAYERS.TYPES.COUNTRY,
            visible: false,
            displayInLayerSwitcher: false,
            style: new Style({
                stroke: new Stroke({
                    color: 'white',
                    width: 2,
                }),
            }),
        });
    }

    getLayer() {
        return this.layer;
    }

    async update(layer) {
        if (!this.countrySource) {
            const countrySource = new VectorSource();
            const { country } = await geometryService.getCountry();
            const polygon = JSON.parse(country.polygon);
            const feature = new Feature({
                geometry: new MultiPolygon(polygon.coordinates),
            });
            countrySource.addFeature(feature);
            this.layer.setSource(countrySource);
            this.countrySource = countrySource;
            if (layer.fittingLayer) {
                this.map.map.getView().fit(this.countrySource.getExtent(), this.map.map.getSize());
            }
        }
        if (layer.fittingLayer && this.countrySource) {
            this.map.map.getView().fit(this.countrySource.getExtent(), this.map.map.getSize());
        }
        this.layer.setVisible(layer.visible);
    }
}
