import { frostDamageConstants } from '../constants';

export const frostDamageActions = {
    setFrostDamages,
    setFrostDanger,
    setFrostMepar,
    setFrostCity,
};

function setFrostDamages(damages) {
    return {
        type: frostDamageConstants.SET_FROST_DAMAGES,
        payload: {
            damages,
        },
    };
}

function setFrostDanger(danger) {
    return {
        type: frostDamageConstants.SET_FROST_DANGER,
        payload: {
            danger,
        },
    };
}

function setFrostMepar(mepar) {
    return {
        type: frostDamageConstants.SET_FROST_MEPAR,
        payload: {
            mepar,
        },
    };
}

function setFrostCity(city) {
    return {
        type: frostDamageConstants.SET_FROST_CITY,
        payload: {
            city,
        },
    };
}

