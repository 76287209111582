import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { mapActions, viewActions } from '../state/actions';
import { LAYERS } from 'shared';
import { VIEWS } from 'shared/src/constants/views';

class DamageController extends React.Component {
    componentDidMount() {
        return this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.match.params.id !== prevProps.match.params.id ||
            this.props.location.search !== prevProps.location.search
        ) {
            return this.getData();
        }
    }

    async getData() {
        this.props.dispatch(viewActions.setInfoPanelState({ open: false }));
        this.props.dispatch(viewActions.setViewState({ view: VIEWS.HOME }));
        this.props.dispatch(mapActions.resetLayers());
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.COUNTRY,
                visible: true,
                fittingLayer: true,
            })
        );
    }

    render() {
        return null;
    }
}

export default connect()(withRouter(DamageController));
