import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent/SnackbarContent';
import InfoTooltip from '../../common/InfoTooltip';
import { farmerModule } from 'shared/src/modules/farmerModule';
import { PRODUCTS } from 'shared';

const useStyles = makeStyles((theme) => ({
    layout: {
        position: 'absolute',
        top: '4px',
        left: '5px',
        right: '5px',
        [theme.breakpoints.up('sm')]: {
            top: '10px',
            left: '50%',
            right: 'auto',
            transform: 'translateX(-50%)',
        },
        '&.MuiSnackbarContent-root': {
            backgroundColor: '#4CAF50',
            paddingTop: '0px',
            paddingBottom: '0px',
        },
        '& .MuiSnackbarContent-message': {
            paddingTop: '0px',
            paddingBottom: '0px',
        },
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    tooltip: {
        backgroundColor: '#333333',
        color: 'rgba(255, 255, 255, 1)',
        fontSize: '18px',
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    legend: {
        margin: '3px 0px',
        border: '1px solid #A6A6A6',
    },
}));

export default function LegendMessage() {
    const classes = useStyles();
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const policyPlotToDraw = useSelector((state) => state.mepar.policyPlotToDraw);

    if (!selectedMepar) {
        return null;
    }
    // we remove drawing requirement for now
    // const selectedMeparHasTableGeometries = farmerModule.meparHasSomeTableGeometries(selectedMepar);
    const ndviDates = farmerModule.getMeparNdviDates(selectedMepar) || [];
    const isLatestNdviLate = farmerModule.isLatestNdviLate(selectedMepar);

    if (
        policyPlotToDraw /* || !selectedMeparHasTableGeometries */ ||
        ndviDates.length === 0 ||
        isLatestNdviLate
    ) {
        return null;
    }

    return (
        <SnackbarContent
            message={
                <div className={classes.message}>
                    <img
                        className={classes.legend}
                        src={PRODUCTS.DETAILS[PRODUCTS.TYPES.CHRONOLOGY].legend}
                        height={'40px'}
                        alt={''}
                    />
                </div>
            }
            action={
                <>
                    <InfoTooltip
                        title={`Az NDVI térképen a kék színtartomány alacsony, a piros színtartomány magas bimassza intenzitást mutat.`}
                    />
                </>
            }
            className={classes.layout}
        />
    );
}
