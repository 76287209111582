import React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiLink } from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    email: {
        textDecoration: 'underline',
    },
    videoWrapper: {
        width: '100%',
        margin: '20px 0px',
    },
    video: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '80%',
    },
}));

export default function HelpDialog(props) {
    const { open, handleClose } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Dialog fullScreen={!isTabletOrDesktop} open={open} onClose={handleClose}>
            <DialogTitle>Segítség</DialogTitle>
            <Divider />
            <DialogContent>
                <h2>Bemutató videó</h2>
                <div className={classes.videoWrapper}>
                    <video controls className={classes.video}>
                        <source src="/content/Riasztas_felulet_hasznalat.mp4" type="video/mp4" />
                    </video>
                </div>
                <h2>Elérhetőségek</h2>
                <div>
                    Probléma esetén kérjük, lépjen velünk kapcsolatba a{' '}
                    <MuiLink href="mailto:generali@karriasztas.hu" className={classes.email}>
                        generali@karriasztas.hu
                    </MuiLink>{' '}
                    email címen.
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="contained">
                    Bezár
                </Button>
            </DialogActions>
        </Dialog>
    );
}
