import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            marginTop: '50px',
        },
    },
    textBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export default function ErrorScreen() {
    const classes = useStyles();
    const error = useSelector((state) => state.error.error);
    const history = useHistory();

    if (!error) {
        return null;
    }

    function handleClick() {
        history.go(0);
    }

    return (
        <div className={classes.layout}>
            <Typography variant="h4">Hiba történt!</Typography>
            <div className={classes.textBlock}>
                <Typography variant="body1">A szolgáltatás jelenleg nem elérhető.</Typography>
                <Typography variant="body1">Kérjük, próbálja újra később!</Typography>
            </div>
            <Button variant="contained" color="default" onClick={handleClick}>
                Frissítés
            </Button>
        </div>
    );
}
