import { LAYERS } from 'shared';
import { Fill, Stroke, Style, Text } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';

export default class ProkatSoilSampleMeparLayer {
    map = null;
    layer = null;
    countrySource = null;

    constructor(map) {
        this.map = map;
        this.init();
    }

    init() {
        this.layer = new VectorLayer({
            title: 'Mepar',
            type: LAYERS.TYPES.PROKAT_SOIL_SAMPLE_MEPAR,
            visible: false,
            displayInLayerSwitcher: false,
            style: (feature) => {
                const mepar = feature.get('mepar');
                return new Style({
                    stroke: new Stroke({
                        color: 'yellow',
                        width: 2,
                    }),
                    fill: new Fill({
                        color: 'rgba(0,0,0,0.00000001)',
                    }),
                    text: new Text({
                        font: '14px Calibri,sans-serif',
                        fill: new Fill({ color: '#000' }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 2,
                        }),
                        text: mepar.meparCode,
                    }),
                });
            },
        });
    }

    getLayer() {
        return this.layer;
    }

    async update(layer) {
        if (layer.mepar && layer.mepar !== this.layer.get('mepar')) {
            const meparSource = new VectorSource();
            const polygon = layer.mepar.geom;
            const feature = new Feature({
                geometry: new Polygon(polygon.coordinates),
                mepar: layer.mepar,
            });
            meparSource.addFeature(feature);
            this.meparSource = meparSource;
            this.layer.setSource(meparSource);
        }
        if (layer.fittingLayer && this.meparSource) {
            this.map.map.getView().fit(this.meparSource.getExtent(), this.map.map.getSize());
        }
        this.layer.setVisible(layer.visible);
    }
}
