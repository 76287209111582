import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { woodwiserForestUnitService } from '../services/woodwiserForestUnitService';
import { woodwiserForestUnitActions } from '../state/actions/woodwiserForestUnitActions';
import { LAYERS } from 'shared/src/constants/layers';
import { VIEWS } from 'shared/src/constants/views';
import { viewActions } from '../state/actions/viewActions';
import { mapActions } from '../state/actions/mapActions';

class WoodwiserForestUnitController extends React.Component {
    componentDidMount() {
        return this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.match.params.id !== prevProps.match.params.id ||
            this.props.location.search !== prevProps.location.search
        ) {
            return this.getData();
        }
    }

    async getData() {
        const {
            match: { params },
        } = this.props;

        this.props.dispatch(viewActions.setInfoPanelState({ open: true }));
        this.props.dispatch(viewActions.setViewState({ view: VIEWS.PROGRESS }));

        this.props.dispatch(mapActions.resetLayers());

        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.COUNTRY,
                visible: true,
                fittingLayer: false,
            })
        );

        const woodwiserForestUnit = await woodwiserForestUnitService.getWoodwiserForestUnit(
            params.id
        );

        if (!woodwiserForestUnit) {
            return;
        }

        this.props.dispatch(woodwiserForestUnitActions.setWoodwiserForestUnit(woodwiserForestUnit));
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_FOREST_UNIT,
                forestUnits: [woodwiserForestUnit],
                fittingLayer: true,
            })
        );
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_DTM,
                products: woodwiserForestUnit.products,
                fittingLayer: false,
            })
        );
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_HEIGHT,
                products: woodwiserForestUnit.products,
                fittingLayer: false,
            })
        );
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_ASPECT,
                products: woodwiserForestUnit.products,
                fittingLayer: false,
            })
        );
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_SLOPE,
                products: woodwiserForestUnit.products,
                fittingLayer: false,
            })
        );
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_RGB,
                products: woodwiserForestUnit.products,
                fittingLayer: false,
            })
        );
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_STEM,
                stems: woodwiserForestUnit.stems,
                treeSpecies: woodwiserForestUnit.treeSpecies,
                fittingLayer: false,
            })
        );
        this.props.dispatch(
            mapActions.updateLayer({
                type: LAYERS.TYPES.WOODWISER_CROWN,
                crowns: woodwiserForestUnit.crowns,
                treeSpecies: woodwiserForestUnit.treeSpecies,
                fittingLayer: false,
            })
        );
        this.props.dispatch(viewActions.setViewState({ view: VIEWS.WOODWISER_FOREST_UNIT }));
    }

    render() {
        return null;
    }
}

export default connect()(withRouter(WoodwiserForestUnitController));
