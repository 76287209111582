export const screens = {
    mobile: 'xs',
    tablet: 'sm',
    desktop: 'md',
};

export const themeBase = {
    drawerWidth: '320px',
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1200,
        },
    },
    typography: {
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
            lineHeight: '28px',
        },
        body1: {
            fontSize: '18px',
            lineHeight: '24px',
        },
        body2: {
            color: 'rgba(74,74,74,1)',
            fontSize: '14px',
            lineHeight: '20px',
        },
        useNextVariants: true,
    },
    palette: {
        text: {
            primary: 'rgba(0,0,0,1)',
            primaryInverse: 'rgba(255,255,255,1)',
            secondary: 'rgba(155,155,155,1)',
            secondaryInverse: 'rgba(155,155,155,1)',
        },
        background: {
            default: 'rgba(249,249,249,1)',
        },
        primary: {
            main: 'rgba(194, 27, 23, 1)',
        },
        secondary: {
            main: 'rgba(233, 87, 61, 1)',
        },
    },
    screens,
    main: {
        width: '1200px',
        backgroundColor: 'rgba(239,239,239,1)',
        cardListPadding: '10px',
    },
    header: {
        backgroundColor: 'rgba(0,0,0,1)',
        hamburgerButtonColor: 'rgba(255,159,0,1)',
        hamburgerButtonColorHover: '#DD8A00',
        size: {
            [screens.mobile]: '50px',
            [screens.tablet]: '76px',
            [screens.desktop]: '70px',
        },
        padding: {
            [screens.mobile]: '8px',
            [screens.tablet]: '18px',
            [screens.desktop]: '12px',
        },
    },
    secondaryHeader: {
        backgroundColor: 'rgba(255,159,0,1)',
        size: {
            [screens.desktop]: '60px',
        },
        padding: {
            [screens.desktop]: '15px',
        },
    },
    checkoutDialog: {
        columnWidth: {
            [screens.desktop]: '380px',
            [screens.tablet]: '471px',
            [screens.mobile]: '100%',
        },
    },
    overrides: {
        MuiInputAdornment: {
            positionEnd: {
                paddingRight: '0px',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '12px 13px',
            },
        },
        MuiInputBase: {
            input: {
                fontSize: '13px',
            },
        },
    },
};

export const themeBaseWoodwiser = Object.assign({}, themeBase, {
    palette: {
        background: {
            default: 'rgba(249,249,249,1)',
        },
        primary: {
            main: 'rgb(3,84,124)',
        },
        secondary: {
            main: 'rgb(7,194,134)',
        },
    },
});

export const themeBaseProkat = Object.assign({}, themeBase, {
    palette: {
        background: {
            default: 'rgba(249,249,249,1)',
        },
        primary: {
            main: '#4C7AFF',
        },
        secondary: {
            main: '#C9CBD2', //#15151A
        },
    },
});

export const themeBaseAgrarmonitor = Object.assign({}, themeBase, {
    palette: {
        background: {
            default: 'rgba(249,249,249,1)',
        },
        primary: {
            main: '#2768AB',
        },
        secondary: {
            main: '#E56703', //#15151A
        },
    },
});

export const themeBaseAgro = Object.assign({}, themeBase, {
    palette: {
        background: {
            default: 'rgba(249,249,249,1)',
        },
        primary: {
            main: '#466E7B',
        },
        secondary: {
            main: '#C9CBD2', //#15151A
        },
    },
});

export const themeBaseCemetery = Object.assign({}, themeBase, {
    palette: {
        background: {
            default: 'rgba(249,249,249,1)',
        },
        primary: {
            main: '#466E7B',
        },
        secondary: {
            main: '#C9CBD2', //#15151A
        },
    },
});
