import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent/SnackbarContent';
import InfoTooltip from '../../common/InfoTooltip';
import { farmerModule } from 'shared/src/modules/farmerModule';

const useStyles = makeStyles((theme) => ({
    layout: {
        position: 'absolute',
        top: '10px',
        left: '5px',
        right: '5px',
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            right: 'auto',
            transform: 'translateX(-50%)',
        },
        '&.MuiSnackbarContent-root': {
            backgroundColor: '#f44336',
        },
    },
    tooltip: {
        backgroundColor: '#333333',
        color: 'rgba(255, 255, 255, 1)',
        fontSize: '18px',
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
}));

export default function LateNdviMessage() {
    const classes = useStyles();
    const selectedMepar = useSelector((state) => state.mepar.selectedMepar);
    const policyPlotToDraw = useSelector((state) => state.mepar.policyPlotToDraw);

    if (!selectedMepar) {
        return null;
    }

    // we remove drawing requirement for now
    // const selectedMeparHasTableGeometries = farmerModule.meparHasSomeTableGeometries(selectedMepar);
    const isLatestNdviLate = farmerModule.isLatestNdviLate(selectedMepar);

    if (policyPlotToDraw /* || !selectedMeparHasTableGeometries */ || !isLatestNdviLate) {
        return null;
    }

    return (
        <SnackbarContent
            message={'Felhőzet miatt nincs friss NDVI térkép!'}
            action={
                <>
                    <InfoTooltip
                        title={`A terület folyamatos felhőborítottsága miatt nincs aktuális NDVI térkép!`}
                    />
                </>
            }
            className={classes.layout}
        />
    );
}
